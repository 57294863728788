import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import InclusaoImg from "../../../images/noticias/inclusao.png";

function Covid19LongeControlada() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>‘A Covid-19 está longe de ser controlada no país’, alerta pneumologista</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        subtitulo="‘A Covid-19 está longe de ser controlada no país’, alerta pneumologista"
                        data="24/11/2020"
                        img={InclusaoImg}
                    />
                    <p>Pneumologista e pesquisadora da Fiocruz, Margareth Dalcolmo já enfrentou muitos desafios em quatro décadas de saúde pública. Entre eles a tuberculose, sua principal área de trabalho, que ainda acomete 75 mil novas pessoas por ano no Brasil. Participou também da formulação dos programas que reduziram drasticamente o tabagismo no país. Mas nenhum inimigo mobilizou suas forças como o SARS-CoV-2, vírus que provocou a pandemia da Covid-19.</p>
                    <p>Entre aparições na TV, textos para sua coluna no Globo, e a pesquisa, a médica sentiu no corpo os sintomas da doença em maio. Agora, vive a expectativa da imunização que tem o Brasil como um dos epicentros. Na entrevista abaixo, ela fala dos próximos meses de combate ao coronavírus e de outros males que atingem os pulmões dos brasileiros.</p>
                    <p><strong>O SARS-CoV-2 ainda vai viver entre nós por muito tempo?</strong></p>
                    <p>Historicamente, as viroses respiratórias tendem a desaparecer. Foi assim com a SARS, com a MERS, com a própria gripe espanhola. Mas isso não quer dizer que elas não possam voltar. Não é esperado que o SARS-CoV-2 desapareça das nossas vidas. Ele vai manter uma certa endemicidade, e é por isso que precisamos das vacinas. As viroses crônicas, como a hepatite C, a Aids, são tratadas com remédios. As agudas, não. A experiência é frustrante, como foram todos os tratamentos da Covid-19.</p>
                    <p><strong>A possibilidade de mutações do vírus ameaça a imunização global contra a Covid-19?</strong></p>
                    <p>É muito pouco provável, porque o SARS-CoV-2 é um vírus grande, tem RNA complexo, sofre poucas mutações. As que ocorreram até agora não tornaram a doença mais transmissível ou mais grave. Ele permite que todas as vacinas em teste sejam eficientes.</p>
                    <p><strong>Os problemas que estão ocorrendo na fase clínica dos testes soam algum alerta?</strong></p>
                    <p>Esses acidentes de percurso são perfeitamente esperados. Os comitês de segurança são independentes, têm autonomia para interromper os estudos. Esclarecem o efeito adverso, determinam se aquilo está ou não relacionado à vacina, qual a gravidade. Em relação à CoronaVac (que teve os testes em humanos interrompidos pela Anvisa), a suspensão foi algo totalmente excepcional. Quem poderia tê-lo feito seria o Butantan, o fabricante, ou os comitês que monitoram a vacina, antes da Anvisa. Não sei qual é o grau de interferência política nisso, mas temo que tenha havido.</p>
                    <p><strong>Podemos ser otimistas em relação a essas vacinas?</strong></p>
                    <p>Esperávamos que as vacinas mal passassem do ponto de corte mínimo para aprovação pelos órgãos regulatórios, e alcançassem uns 60% de proteção. Isso se somaria à taxa de imunidade comunitária já alcançada em algumas áreas, chegando a uns 80%. Os números mais altos que têm sido divulgados são preliminares, precisamos avaliar melhor. Mas a nossa perspectiva é que tenhamos, em 2021, várias vacinas aprovadas.</p>
                    <p><strong>O movimento antivacina fala em direitos individuais para defender seu ponto de vista. Tomar ou não a vacina deve ser uma escolha pessoal?</strong></p>
                    <p>Essa discussão é artificial. Estamos vivendo uma situação em que não se pode velar um morto com caixão aberto. Estamos ferindo o direito individual das famílias enlutadas? Com certeza. Mas uma epidemia é uma epidemia, tudo nela é diferente. Temos que olhar para o direito coletivo de proteção do máximo possível de pessoas. Diante de uma doença com uma capacidade de transmissão tão alta, qualquer medida que intercepte essa cadeia deve ser adotada para todos. Considero o movimento antivacina algo beirando a criminalidade. Por que negar a alguém acesso à única coisa capaz de prevenir uma doença?</p>
                    <p><strong>O SUS respondeu bem à crise sanitária dessa epidemia?</strong></p>
                    <p>Ele entrou nessa guerra combalido. A epidemia chegou para nós como uma tsunami e não prestamos atenção ao recuo do mar, que foram os países europeus que nos antecederam. Nós podíamos ter tomado medidas preventivas. Não podia nem ter havido carnaval, por exemplo. A doença chegou aqui nessa época. Mas acho que 2021 trará medidas de saúde pública em relação às vacinas muito positivas.</p>
                    <p><strong>Especialistas afirmam que estamos em uma segunda onda de Covid-19 no Brasil. Seria apropriado?</strong></p>
                    <p>Não podemos cravar ainda. Considera-se a segunda onda de uma epidemia quando já houve uma redução grande e depois os números de casos e mortes aumentam em mais de 50%, como está acontecendo na Europa. No Brasil, podemos pensar nesse movimento apenas no Norte, nem isso se caracterizou ainda. Passamos pelo pico epidêmico lá pelo final de junho e mantivemos um patamar de transmissão muito alto ainda. A doença está longe de ser controlada no país.</p>
                    <p><strong>Como a pandemia impactou outras doenças pulmonares?</strong></p>
                    <p>As viroses respiratórias que a Fiocruz monitora pelo sistema InfoGripe diminuíram muito. As pessoas ficaram em casa e usaram máscara, por isso a transmissão das gripes sazonais caiu. Mas outras doenças chamam atenção de maneira triste, como a dos cânceres mais frequentes no Brasil, incluindo o de pulmão. Segundo o INCA, 70% das cirurgias previstas deixaram de ser feitas. São pessoas que tinham seu diagnóstico e estavam em preparo para a cirurgia, mas ela foi adiada. Também não fizeram sua quimioterapia ou radioterapia adequadamente. Tudo isso tem um impacto muito grande na mortalidade. Vamos saber daqui a um ano, quando fizermos uma análise epidemiológica.</p>
                    <p><strong>E a tuberculose, que já era preocupante no Brasil?</strong></p>
                    <p>Sabemos, pelos dados do próprio Ministério da Saúde, que houve 40% menos diagnósticos no país. São pessoas que estão circulando e contaminando outras. Nos centros de referência, como a Fiocruz, não deixamos de atender nossos pacientes, mas muitos outros serviços não funcionaram. O problema é que a resistência aos medicamentos contra a tuberculose está ligada ao uso inadequado de tratamentos anteriores.</p>
                    <p><strong>Podemos dizer que o combate ao tabagismo foi um caso exemplar das nossas políticas públicas?</strong></p>
                    <p>É uma história de sucesso. A despeito de todas as dificuldades, estabelecemos uma política de prevenção. Tínhamos uma quantidade enorme de fumantes, reduzimos esse número e conseguimos manter essa taxa. Foram medidas muito exitosas, como a proibição da propaganda, a taxação, os programas de educação voltados a adolescentes.</p>
                    <p><strong>Que perigo os cigarros eletrônicos representam?</strong></p>
                    <p>Eles são um dos maiores desafios hoje, do ponto de vista respiratório. A Sociedade de Pneumologia, ao lado de outras sociedades médicas, conseguiu que a Anvisa não aprovasse os vapers no Brasil (a agência deve retomar a discussão no ano que vem). Mas eles entram aqui contrabandeados. São fonte de um grande número de mortes por inalação de substâncias tóxicas, que podem causar inflamação nas vias aéreas. Estão relacionados à doença pulmonar obstrutiva crônica (DPOC), que inclui o enfisema e a bronquite crônica. Criaram mecanismos de adição sutis, perversos.</p>
                    <p><strong>Que outros acertos políticos tivemos em relação às doenças pulmonares?</strong></p>
                    <p>Um excelente exemplo é a vacinação contra a influenza. A incorporação no programa anual fez a mortalidade cair, embora ainda seja alta. Além dos idosos, o SUS hoje também protege as crianças.</p>
                    <p>Gustavo Leitão</p>
                    <p>ter., 24 de novembro de 2020 4:30 AM BRT</p>
                    <span>Fonte: </span> <a href="https://br.noticias.yahoo.com/covid-19-est%C3%A1-longe-ser-073014499.html" target="blanck">https://br.noticias.yahoo.com/covid-19-est%C3%A1-longe-ser-073014499.html</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Covid19LongeControlada