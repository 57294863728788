import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import SubvarianteImg from '../../../images/noticias/omicron.jpg'

function Subvariante() {
    return (
        <SubvarianteStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Sociedade Brasileira de Infectologia pede medidas urgentes contra subvariante</h1>
                    <div className='paginas-noticias-content Subvariante'>
                        <Page
                            subtitulo="Sociedade Brasileira de Infectologia pede medidas urgentes contra subvariante"
                            data="16/11/2022"
                            img={SubvarianteImg}
                        />
                        {/* <img src={ SubvarianteImg } alt="noticia" /> */}
                        <p>A Sociedade Brasileira de Infectologia (SBI) divulgou nota, na tarde de sexta-feira (11/11), em que pede medidas urgentes para o controle da alta de casos da subvariante ômicron BQ.1. No Distrito Federal, a Secretaria de Saúde (SES) registrou 14 diagnósticos positivos. </p>
                        <p>Por meio de Comitê Científico da covid-19 e Infecções Respiratórias (CCCIR), a SBI alerta para o aumento significativo do número de casos no Brasil. Pelo menos em quatro estados a instituição se preocupa com uma tendência de alta na curva de novos casos.</p>
                        
                        <span>Veja matéria completa em: </span><a href="https://www.correiobraziliense.com.br/cidades-df/2022/11/5051212-sociedade-brasileira-de-infectologia-pede-medidas-contra-subvariante.html" target="_blank" rel="noreferrer">https://www.correiobraziliense.com.br/cidades-df/2022/11/5051212-sociedade-brasileira-de-infectologia-pede-medidas-contra-subvariante.html</a>
                    </div>
                </div>
                <Footer />
            </div>
        </SubvarianteStyle>
    )
}

const SubvarianteStyle = styled.div`
    .Subvariante-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default Subvariante