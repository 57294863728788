

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

function Paroquia() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Serratec instala túnel inflável de ozônio para descontaminação de pessoas</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        subtitulo="Serratec instala túnel inflável de ozônio para descontaminação de pessoas"
                        data="23/05/2020"
                    />
                    <a href="http://www.aconteceempetropolis.com.br/2020/05/23/serratec-instala-tunel-inflavel-de-ozonio-para-descontaminacao-de-pessoas/" target="_blank" rel="noreferrer">Leia a matéria completa no site do Acontece em Petróplis</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Paroquia