import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Header from '../../components/header'
import Footer from '../../components/footer'

import BannerOzonio from '../../components/bannerOzonio'

function Saude() {
    return (
        <div>
            <Header title="NOTAS TÉCNICAS SAÚDE" />
            <BannerOzonio />
            <NotasStyled className="body">
                <h2 className="title-d">Artigos e Certificados</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>

                <div class="desc-nt">
                    <p className="desc">
                        Neste espaço, você encontrará um vasto acervo de informações técnicas e científicas sobre os benefícios do ozônio como poderosa ferramenta de purificação e esterilização do ar. Abordamos em detalhes os processos de ozonização, suas aplicações em diferentes setores e os mecanismos pelos quais o ozônio elimina microrganismos, alérgenos e odores indesejados, promovendo ambientes livres de contaminantes nocivos.
                    </p>
                    <p className="desc">
                        Nossas notas técnicas são uma fonte confiável para profissionais da indústria, engenheiros ambientais, gestores de saúde, arquitetos e qualquer pessoa interessada em proporcionar um ambiente limpo e seguro para suas atividades diárias.
                    </p>
                </div>

                <div className="card-container">
                    <div className='card'>
                        <h3>Diploma Dra. Karyne Rangel Carvalho</h3>
                        <Link to="/pdf/DiplomaDoutoradofrente.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>NOTA TÉCNICA Nº 32-2020-SEI-COSAN-GHCOS-DIRE3-ANVISA</h3>
                        <Link to="/pdf/1-NOTA TÉCNICA Nº 32-2020-SEI-COSAN-GHCOS-DIRE3-ANVISA.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>RPI PATENTE MU 202021009339-6</h3>
                        <Link to="/pdf/2-RPI PATENTE MU 202021009339-6.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>PESQUISAS DRª kARYNE - EFEITO DO OZÔNIO EM BAIXA CONCENTRAÇÃO SOBRE BACTÉRIAS PATOGÊNICAS</h3>
                        <Link to="/pdf/3-PESQUISAS DRª kARYNE - EFEITO DO OZÔNIO EM BAIXA CONCENTRAÇÃO SOBRE BACTÉRIAS PATOGÊNICAS.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>ARTIGO PUBLICADO NA REVISTA MDPI - Detrimental Effect of Ozone on Pathogenic Bacteria</h3>
                        <Link to="/pdf/4-ARTIGO PUBLICADO NA REVISTA MDPI - Detrimental Effect of Ozone on Pathogenic Bacteria.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>ARTIGO PUBLICADO SOBRE - Reduction of severe acute respiratory syndrome coronavirus‐2 infectivity Profº. Takayuki Murata - 2021 - Microbiology and Immunology</h3>
                        <Link to="/pdf/5-ARTIGO PUBLICADO SOBRE - Reduction of severe acute respiratory syndrome coronavirus‐2 infectivity Profº. Takayuki Murata - 2021 - Microbiology and Immunology.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>OFÍCIO Nº 06-2019-GQUIP-GGTPS-ANVISA - 07-01-2019</h3>
                        <Link to="/pdf/6-OFÍCIO Nº 06-2019-GQUIP-GGTPS-ANVISA - 07-01-2019.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>NOTA TÉCNICA Nº 51-2020 - ANVISA</h3>
                        <Link to="/pdf/7-NOTA TÉCNICA Nº 51-2020 - ANVISA.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>NOTA TÉCNICA Nº 108-2020 - ANVISA - sei-anvisa-1168587-nota-tecnica-ozonio-ghcos</h3>
                        <Link to="/pdf/8-NOTA TÉCNICA Nº 108-2020 - ANVISA - sei-anvisa-1168587-nota-tecnica-ozonio-ghcos.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>OFÍCIO Nº 228-2022-SEI-DIRE3-ANVISA</h3>
                        <Link to="/pdf/9-OFÍCIO Nº 228-2022-SEI-DIRE3-ANVISA.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>RE_09_2003_ANVISA - QAI - QUALIDADE DO AR INTERNO</h3>
                        <Link to="/pdf/10-RE_09_2003_ANVISA - QAI - QUALIDADE DO AR INTERNO.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>PESQUISAS DRª KARYNE - EFEITO DA ALTA CONCENTRAÇÃO DE OZÔNIO SOBRE BACTÉRIAS PATOGÊNICAS</h3>
                        <Link to="/pdf/11-PESQUISAS DRª KARYNE - EFEITO DA ALTA CONCENTRAÇÃO DE OZÔNIO SOBRE BACTÉRIAS PATOGÊNICAS.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>ARTIGO PUBLICADO NA REVISTA MDPI - Potent Activity of a High Concentration of Chemical Ozone against Antibiotic-Resistant Bacteria</h3>
                        <Link to="/pdf/12-ARTIGO PUBLICADO NA REVISTA MDPI - Potent Activity of a High Concentration of Chemical Ozone against Antibiotic-Resistant Bacteria.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>PESQUISAS DRª KARYNE - EFEITO DA RADIAÇÃO ULTRAVIOLETA C DE UM EQUIPAMENTO DESCONTAMINADOR DE SOLA DE SAPATOS NA DESINFECÇÃO DE DIFERENTES PATÓGENOS</h3>
                        <Link to="/pdf/13-PESQUISAS DRª KARYNE - EFEITO DA RADIAÇÃO ULTRAVIOLETA C DE UM EQUIPAMENTO DESCONTAMINADOR DE SOLA DE SAPATOS NA DESINFECÇÃO DE DIFERENTES PATÓGENOS.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>ARTIGO PUBLICADO NA REVISTA MDPI - Effectiveness Evaluation of a UV-C-Photoinactivator against Selected ESKAPE-E Pathogens</h3>
                        <Link to="/pdf/14-ARTIGO PUBLICADO NA REVISTA MDPI - Effectiveness Evaluation of a UV-C-Photoinactivator against Selected ESKAPE-E Pathogens.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>NOTA TÉCNICA -  INSTALAÇÃO, MANUTENÇÃO E NÍVEIS DE TOLERÂNCIA</h3>
                        <Link to="/pdf/15-NOTA TÉCNICA -  INSTALAÇÃO, MANUTENÇÃO E NÍVEIS DE TOLERÂNCIA.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>Certificado de Aceitação do Manuscrito dos Microrganismo no Efeito Prejudicial do Ozônio em Bactérias Patogênicas.</h3>
                        <Link to="/pdf/certificado.png" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>NT-INFECÇÃO HOSPITALAR</h3>
                        <Link to="/pdf/NT-INFECÇÃOHOSPITALAR.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>NT-QAI-QUALIDADE DO ARINTERNO</h3>
                        <Link to="/pdf/NT-QAI-QUALIDADEDOARINTERNO.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>SANEANTES QUIMÍCOS OU OZÔNIO</h3>
                        <Link to="/pdf/SANEANTES QUIMÍCOS OU OZÔNIO.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>BACTÉRIAS EM COLCHÕES NA REDE HOSPITALAR</h3>
                        <Link to="/pdf/BACTÉRIAS EM COLCHÕES NA REDE HOSPITALAR.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>INFECÇÕES HOSPITALARES ELABORADAS POR EQUIPE DE ENFERMAGEM</h3>
                        <Link to="/pdf/INFECÇÕES HOSPITALARES ELABORADAS POR EQUIPE DE ENFERMAGEM.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>ESTUDO HOSPITAL SÍRIO LIBANÊS</h3>
                        <Link to="/pdf/ESTUDO HOSPITAL SÍRIO LIBANÊS.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>ESTUDO SOBRE SANITIZAÇÃO DAS MÃOS</h3>
                        <Link to="/pdf/ESTUDO SOBRE SANITIZAÇÃO DAS MÃOS.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>ESTUDO E CONTROLE AMBIENTAL BACTERIANO COM OZÔNIO</h3>
                        <Link to="/pdf/ESTUDO E CONTROLE AMBIENTAL BACTERIANO COM OZÔNIO.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>DIREITO DE SAÚDE - INFECÇÃO HOSPITALAR</h3>
                        <Link to="/pdf/DIREITO DE SAÚDE - INFECÇÃO HOSPITALAR.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>ESTUDO SOBRE BACTÉRIAS EM HOSPITAL</h3>
                        <Link to="/pdf/ESTUDO SOBRE BACTÉRIAS EM HOSPITAL.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>ANÁLISE DA CONTAMINAÇÃO DE FILTROS DE AR CONDICIONADO DE AUTOMÓVEIS</h3>
                        <Link to="/pdf/ANÁLISE DA CONTAMINAÇÃO DE FILTROS DE AR CONDICIONADO DE AUTOMÓVEIS.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>POLUIÇÃO DO AR DE INTERIORES - Carla-Alice-Theodoro-Batista</h3>
                        <Link to="/pdf/POLUIÇÃO DO AR DE INTERIORES - Carla-Alice-Theodoro-Batista.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>QUALIDADE DO AR INTERNO EM HOSPITAIS</h3>
                        <Link to="/pdf/QUALIDADE DO AR INTERNO EM HOSPITAIS.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>NOTA TÉCNICA - TRANSPORTES URBANOS 17-05-23</h3>
                        <Link to="/pdf/NOTA TÉCNICA - TRANSPORTES URBANOS 17-05-23.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>TRATAMENTO POR OZÔNIO BOVINO E EQUINO</h3>
                        <Link to="/pdf/TRATAMENTO POR OZÔNIO BOVINO E EQUINO.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>LIMPEZA E DESINFECÇÃO AVICULTURA - ENGORMIX</h3>
                        <Link to="/pdf/LIMPEZA E DESINFECÇÃO AVICULTURA - ENGORMIX.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>PRINCIPAIS MÉTODOS DE DESINFECÇÃO E DESINFECTANTES UTILIZADOS NA AVICULTURA</h3>
                        <Link to="/pdf/PRINCIPAIS MÉTODOS DE DESINFECÇÃO E DESINFECTANTES UTILIZADOS NA AVICULTURA.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>TRATAMENTO DE MASTITE POR OZÔNIO</h3>
                        <Link to="/pdf/TRATAMENTO DE MASTITE POR OZÔNIO.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>RESOLUÇÃO Nº 1275 - 25-06-19 - CONSELHO FEDERAL DE MEDICINA VETERINÁRIA</h3>
                        <Link to="/pdf/RESOLUÇÃO Nº 1275 - 25-06-19 - CONSELHO FEDERAL DE MEDICINA VETERINÁRIA.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>PORTARIA GM_MS Nº 888, DE 4 DE MAIO DE 2021 - PORTARIA GM_MS Nº 888, DE 4 DE MAIO DE 2021 - DOU - Imprensa Nacional</h3>
                        <Link to="/pdf/PORTARIA GM_MS Nº 888, DE 4 DE MAIO DE 2021 - PORTARIA GM_MS Nº 888, DE 4 DE MAIO DE 2021 - DOU - Imprensa Nacional.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>PORTARIA No- 2.914, DE 12 DE DEZEMBRO DE 2011 - COMPETE A ANVISA A VIGILÂNCIA DA QUALIDADE DA ÁGUA NAS ÁREAS DE PORTOS, AEROPORTOS E PASSAGENS DE FRONTEIRAS TERRESTRES</h3>
                        <Link to="/pdf/PORTARIA No- 2.914, DE 12 DE DEZEMBRO DE 2011 - COMPETE A ANVISA A VIGILÂNCIA DA QUALIDADE DA ÁGUA NAS ÁREAS DE PORTOS, AEROPORTOS E PASSAGENS DE FRONTEIRAS TERRESTRES.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>OZÔNIO NA ÁGUA - 18-10-2020</h3>
                        <Link to="/pdf/OZÔNIO NA ÁGUA - 18-10-2020.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>APLICAÇÃO DE OZÔNIO NO POLIMENTO FINAL DE EFLUENTES - 2015</h3>
                        <Link to="/pdf/APLICAÇÃO DE OZÔNIO NO POLIMENTO FINAL DE EFLUENTES - 2015.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>CLORO E OZÔNIO APLICADOS À DESINFECÇÃP DE EFLUENTE HOSPITALAR - 2004</h3>
                        <Link to="/pdf/CLORO E OZÔNIO APLICADOS À DESINFECÇÃP DE EFLUENTE HOSPITALAR - 2004.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>ANÁLISE DO PROCESSO DE OZONIZAÇÃO PARA TRATAMENTO DE ESGOTO SANITÁRIO SINTÉTICO - 2017</h3>
                        <Link to="/pdf/ANÁLISE DO PROCESSO DE OZONIZAÇÃO PARA TRATAMENTO DE ESGOTO SANITÁRIO SINTÉTICO - 2017.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>NOTA TÉCNICA SOCICAM - 21-07-23</h3>
                        <Link to="/pdf/NOTA TÉCNICA SOCICAM - 21-07-23.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>OZÔNIO NO ARMAZENAMENTO DO PESCADO</h3>
                        <Link to="/pdf/OZÔNIO NO ARMAZENAMENTO DO PESCADO.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>OZÔNIO UMA ESTRATÉGIA PARA REMOCÃO DE RESÍDUOS DE AGROTÓXICOS EM ALIMENTOS</h3>
                        <Link to="/pdf/OZÔNIO UMA ESTRATÉGIA PARA REMOCÃO DE RESÍDUOS DE AGROTÓXICOS EM ALIMENTOS.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>REGULAMENTOS DO OZÔNIO DO USDA E FDA - OzoneSolutions.com</h3>
                        <Link to="/pdf/REGULAMENTOS DO OZÔNIO DO USDA E FDA - OzoneSolutions.com.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>OZÔNIO NO CAMARÃO</h3>
                        <Link to="/pdf/OZÔNIO NO CAMARÃO.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>ESTUDO DE COBERTURA COMESTÍVEL TOMATE CEREJA UTILIZANDO O OZÔNIO NA ÁGUA - UFSC</h3>
                        <Link to="/pdf/ESTUDO DE COBERTURA COMESTÍVEL TOMATE CEREJA UTILIZANDO O OZÔNIO NA ÁGUA - UFSC.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>OZÔNIO NO PROCESSAMENTO DO PEIXE</h3>
                        <Link to="/pdf/OZÔNIO NO PROCESSAMENTO DO PEIXE.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>OZÔNIO NO USO DE CÂMARA INDUSTRIAL</h3>
                        <Link to="/pdf/OZÔNIO NO USO DE CÂMARA INDUSTRIAL.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>OZÔNIO COMO SANITIZANTES DE FRUTAS</h3>
                        <Link to="/pdf/OZÔNIO COMO SANITIZANTES DE FRUTAS.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>OZÔNIO COMO TECNOLOGIA PÓS-COLHEITA</h3>
                        <Link to="/pdf/OZÔNIO COMO TECNOLOGIA PÓS-COLHEITA.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>OZÔNIO EM CAMARAS FRIAS - Effect of ozone on qualities of fruits and vegetables in cold storage</h3>
                        <Link to="/pdf/OZÔNIO EM CAMARAS FRIAS - Effect of ozone on qualities of fruits and vegetables in cold storage.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>NOTA TÉCNICA - TRATAMENTO DE CHOQUE HOTELARIA - 17-05-23</h3>
                        <Link to="/pdf/NOTA TÉCNICA - TRATAMENTO DE CHOQUE HOTELARIA - 17-05-23.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>NOTA TÉCNICA - SUPERMERCADOS, RESTAURANTES E HOTÉIS 17-05-23</h3>
                        <Link to="/pdf/NOTA TÉCNICA - SUPERMERCADOS, RESTAURANTES E HOTÉIS 17-05-23.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>

                    <div className='card'>
                        <h3>EFEITOS DA APLICAÇÃO DO OZÔNIO EM PRODUTOS CÁRNEOS</h3>
                        <Link to="/pdf/EFEITOS DA APLICAÇÃO DO OZÔNIO EM PRODUTOS CÁRNEOS.pdf" target="_blank">
                            <button className="button-nt">saber mais</button>
                        </Link>
                    </div>
                </div>
            </NotasStyled>
            <Footer />
        </div>
    )
}

const NotasStyled = styled.div`
    // .card-container {
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: center;
    //     align-items: center;

    //     .card {
    //         position: relative;
    //         padding: 20px;
    //         text-align: center;
    //         width: 400px;
    //         height: 200px;
    //         margin: 50px 50px;
    //         box-shadow: 0rem 0 1rem rgba(0, 0, 0, 0.109);
    //         border-radius: 16px;

    //         h3 {
    //             font-size: 1rem;
    //             font-weight: 700;
    //         }

    //         button {
    //             position: absolute;
    //             right: 30px;
    //             bottom: 20px;
    //             font-weight: 400;
    //             color: var(--primary-color);
    //         }
    //     }
    // }

    .card-container {
        padding: 40px;
        .card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0rem 0 1rem rgba(0, 0, 0, 0.109);
            margin: 10px 0;
            padding: 10px;
            border-radius: 5px;

            h3 {
                font-size: .9rem;
                max-width: 60vw;
            }
        }
    }
    
    @media screen and (min-width: 1501px) {
        
    }

    @media screen and (min-width: 951px) and (max-width: 1500px) {
        
    }

    @media screen and (max-width: 950px) {
        .card-container {
            padding: 10px;
            
            .card {
                h3 {
                    font-size: .7rem;
                }
                button {
                    font-size: .7rem;
                }
            }
        }
    }
`

export default Saude