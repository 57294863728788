import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import Covid from '../../../images/noticias/covid.jpg'

function CoronavirusAmazonas() {
    return (
        <CoronavirusAmazonasStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Fiocruz identifica subvariante BA.5.3.1 do coronavírus no Amazonas</h1>
                    <div className='paginas-noticias-content CoronavirusAmazonas'>
                        <Page
                            subtitulo="Fiocruz identifica subvariante BA.5.3.1 do coronavírus no Amazonas"
                            data="25/10/2022"
                            img={Covid}
                        />
                        {/* <img src={ CoronavirusAmazonasImg } alt="noticia" /> */}
                        <p>Enquanto autoridades de saúde do Rio de Janeiro veem uma possível associação entre o aumento de casos de covid-19 entre cariocas e a subvariante BQ.1 do coronavírus, no Amazonas, a alta nas infecções, até o momento, está relacionada à subvariante BA.5.3.1, outra mutação genética do coronavírus SARS-CoV-2.</p>
                        <p>A conclusão é do virologista Felipe Naveca, coordenador do Núcleo de Vigilância de Vírus Emergentes, Reemergentes ou Negligenciados do Instituto Leônidas & Maria Deane (ILMD/Fiocruz Amazônia). Em investigação realizada em amostras colhidas até 21 de outubro, a subvariante BA.5.3.1 mostrou predomínio de 94%, enquanto só um caso da BQ.1 foi encontrado.</p>
                        <span>Veja matéria completa em: </span><a href="https://agenciabrasil.ebc.com.br/saude/noticia/2022-11/fiocruz-identifica-subvariante-ba531-do-coronavirus-no-amazonas#" target="_blank" rel="noreferrer">https://agenciabrasil.ebc.com.br/saude/noticia/2022-11/fiocruz-identifica-subvariante-ba531-do-coronavirus-no-amazonas#</a>
                    </div>
                </div>
                <Footer />
            </div>
        </CoronavirusAmazonasStyle>
    )
}

const CoronavirusAmazonasStyle = styled.div`
    .CoronavirusAmazonas-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default CoronavirusAmazonas