
import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

function PesquisadoresJaponeses() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Pesquisadores japoneses afirmam que ozônio neutraliza o coronavírus em ambientes</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        subtitulo="Pesquisadores japoneses afirmam que ozônio neutraliza o coronavírus em ambientes"
                        data="26/08/2020"
                        descricao="Em pequenas concentrações, diz estudo, gás pode ajudar a desinfectar espaços sem prejudicar o ser humano.
                        TÓQUIO — Pesquisadores japoneses disseram nesta quarta-feira que baixas concentrações de ozônio podem neutralizar partículas do coronavírus, fornecendo um meio para hospitais desinfectarem consultórios e salas de espera. Os cientistas da Universidade de Fujita garantiram, em entrevista coletiva, terem provado que o gás ozônio, em concentrações de 0,05 a 0,1 parte por milhão (ppm), um nível considerado inofensivo ao ser humanos, pode matar o vírus.
                        A experiência usou um gerador de ozônio numa câmara selada com uma amostra do coronavírus. A potência do vírus caiu mais de 90% quando ele foi submetido a baixos níveis de ozônio por dez horas.
                        — A transmissão do novo coronavírus pode ser reduzida por um tratamento contínuo de ozônio em baixa concentração, mesmo em um ambiente com a presença de pessoas, usando esse sistema — disse o pesquisador chefe Takayuki Murata. — Constatamos que ele é particularmente eficiente em condições de alta umidade.
                        O ozônio, um tipo de molécula de oxigênio, é conhecido por anular muitos patogêneos, e estudos anteriores demonstraram que altas concentrações, entre 1 e 6 ppm, eram eficientes contra o coronavírus, mas potencialmente tóxicas para humanos. Uma pesquisa recente do Instituto de Tecnologia da Geórgia mostrou que o ozônio pode ser eficiente para desinfectar aventais, óculos e outros equipamentos médicos de proteção.
                        O hospital universitário de Fujita instalou geradores de ozônio para reduzir as infecções em salas de espera e consultório. A universidade também está fazendo testes clínicos do remédio Avigan, produzido pela Fujifilm, em pacientes com Covid-19."
                    />
                    <span>Fontes: </span>
                    <a href="https://oglobo.globo.com/sociedade/pesquisadores-japoneses-afirmam-que-ozonio-neutraliza-coronavirus-em-ambientes-24606423?utm_source=globo.com&utm_medium=oglobo" target="_blank" rel="noreferrer">Jornal O Globo</a>
                    <a href="http://www.asahi.com/ajw/articles/13669827" target="_blank" rel="noreferrer"> / The AsahiShimbum</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PesquisadoresJaponeses