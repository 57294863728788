import Header from '../../components/header'
import Footer from '../../components/footer'
import Ficha from '../../components/ficha'

function Hotel() {
    return (
        <div>
            <Header title={"HOTÉIS E RESIDÊNCIAS"} />
            <div className="body">
                <h2 className="title-d">Aplicabilidade</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>

                <p className="desc">Na purificação e esterilização do ar ambiente dos cômodos, da recepção, sala da diretoria, quartos, sala de reuniões, auditório, sala de auditório, sala de ginástica, sala de jogos, sala de repouso após a sauna, elevadores, bar e restaurante, banheiros, vestiários, refeitórios, cozinha e outros, utilizando o nosso equipamento <strong>SANITECH (O₃) LINHA PURI</strong> de baixa concentração para <strong>ambientes habitáveis</strong>, evitando assim a contaminação cruzada por bactérias, vírus, germes, fungos, odores indesejáveis, a proliferação de insetos em ambientes fechados, como a picada dos mosquitos da dengue, chikungunya e zika e minimizando as doenças respiratórias e alérgicas como rinite, sinusite, resfriados por aerossóis.</p>

                <p className="desc">Na geladeira, no closet, na sapateira, no automóvel, no transfer, no receptivo, no city tour, utilizando o nosso equipamento <strong>SANITECH (O₃) MULTIUSO</strong> de baixa concentração para <strong>ambientes habitáveis</strong> eliminando micro-organismos, fungos, odores indesejáveis.</p>

                <p className="desc">Na descontaminação dos sapatos utilizando o nosso equipamento <strong>SANITECH UVC</strong> eliminando os micro-organismos das solas dos sapatos em 99%.</p>

                <p className="desc">No tratamento da água potável e reuso utilizando o nosso equipamento <strong>SANITECH MVO₃, eliminando as bactérias, odores, melhorando a turbidez, restruturando molecularmente, melhorando o seu PH oxigenando-o.</strong></p>

                <p className="desc">No tratamento de esgoto em fossa ou ETE de hotel, utilizando o nosso equipamento <strong>SANITECH MVO₃ DESINFECÇÃO 80</strong>, eliminando as bactérias, odores, melhorando a turbidez e oxigenando-o.</p>

                <p className="desc">Na sanitização das compras de supermercado e outros, utilizando o nosso equipamento <strong>SANITECH (O₃) OXI-SANITIZAÇÃO 40</strong>, com uma cápsula inflável ou minicontainer e gás de ozonio em alta concentração, eliminando os micro-organismos.</p>

                <p className="desc">Na descontaminação dos alimentos com água ozonizada, no enxague com água ozonizada nas áreas contaminadas por fezes de animais e outros, utilizando o nosso equipamento <strong>SANITECH (O₃) ACQUA</strong>, eliminando as bactérias e odores indesejáveis.</p>

                <p className="desc">Na pulverização com água ozonizada na horta e árvores frutíferas, utilizando o nosso equipamento <strong>SANITECH (O₃) NEBULIZADOR COSTAL</strong>, eliminando micro-organismos e fungos sem precisar utilizar produtos químicos.</p>

                <p className="desc">Na pulverização com água ozonizada potencializando na mistura de saneamentos químicos em ambientes de grande circulação e áreas contaminadas do hotel após a limpeza terminal, utilizando o nosso equipamento <strong>SANITECH (O₃) NEBULIZADOR COSTAL</strong>, eliminando micro-organismos, fungos e odores indesejáveis.</p>
            </div>  
            <Ficha />
            <Footer />
        </div>
    )
}

export default Hotel