import styled from 'styled-components'

import Nav from '../components/nav'

import background from '../images/banner2.png'

function Header() {
    return (
        <HeaderStyled>
            <Nav />
        </HeaderStyled>
    )
}

const HeaderStyled = styled.div`
    width: 100%;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 50%;
    position: relative;
    
    @media screen and (min-width: 1001px) and (max-width: 3500px) {
        height: 18vh;
    }

    @media screen and (min-width: 801px) and (max-width: 1000px) {
        height: 28vh;
    }

    @media screen and (max-width: 800px) {
        height: 28vh;
    }
`

export default Header