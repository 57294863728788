import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import PageVideo from '../../../components/pageVideo'

function Paroquia() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Jambo Supermercados instala túnel de descontaminação</h1>
                <div className='paginas-noticias-content'>
                    <PageVideo
                        subtitulo="Jambo Supermercados instala túnel de descontaminação"
                        data="23/05/2020"
                        descricao="O Jambo Supermercados loja do bairro Jequiezinho em Jequié, saiu na frente e instalou um túnel de desinfecção a base de ozônio para garantir maior segurança para os clientes e funcionários contra o coronavírus. O Jequié News, conversou com Gilvan Rocha, um dos representantes do empreendimento que disse que o investimento vem acrescentar a tudo aquilo que a loja vinha fazendo, como por exemplo uso de alcool 70º, uso de mascaras, higienização dos carrinhos e solução de hipoclorito na limpeza.
                        A técnica usada é uma inovação no mercado levando em consideração que a mesma  não oferece nenhum risco, pois o ozônio é produzido pelo próprio equipamento sem uso de produtos que agrida a natureza, nem as pessoas."
                        video="https://www.youtube.com/embed/AWlzuyTZs8U"
                    />
                    <span>Fonte: </span><a href="http://www.jequienews.com/2020/05/supermercado-jambo-de-jequie-instala.html" target="_blank" rel="noreferrer">http://www.jequienews.com/2020/05/supermercado-jambo-de-jequie-instala.html</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Paroquia