import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import PageVideo from '../../../components/pageVideo'


function OxiSanitizacao() {
    return (
        <OxiSanitizacaoStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Produtos para Oxi-sanitização</h1>
                    <div className='paginas-noticias-content oxisanitizacao'>
                        <PageVideo
                            data="31/05/2021"
                            descricao="Cohen & Cohen, representante Astech exclusivo do Norte do país."
                            video="https://www.youtube.com/embed/1XMXb4R0z_w"
                        />
                    </div>
                </div>
                <Footer />
            </div>
        </OxiSanitizacaoStyle>
    )
}

const OxiSanitizacaoStyle = styled.div`
    p {
        text-align: center;
        margin: 5vh 0;
    }   

` 

export default OxiSanitizacao