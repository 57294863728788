import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'
import QualidadeArImg from "../../../images/noticias/qualidade-ar.webp";

function QualidadeAr() {
    return (
        <QualidadeArStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Segmento corporativo investe na qualidade do ar</h1>
                    <div className='paginas-noticias-content QualidadeAr'>
                        <Page
                            subtitulo="Segmento corporativo investe na qualidade do ar"
                            data="25/10/2022"
                            img={QualidadeArImg}
                        />
                        {/* <img src={ QualidadeArImg } alt="noticia" /> */}
                        <p><strong>Ameaça do ‘sick building’ leva o mercado a apostar em tecnologia avançada para eliminar vírus e bactérias, criando ambientes internos mais saudáveis nos edifícios</strong></p>
                        <p>O retorno ao trabalho presencial no pós-pandemia elevou o nível de atenção do mercado imobiliário corporativo quanto à qualidade do ar nos escritórios. O objetivo é evitar a síndrome do sick building, que pode causar redução da produtividade e absenteísmo dos colaboradores. O termo resume sintomas como irritações de pele e olhos, dores de cabeça, t</p>
                        <p>As empresas têm procurado contornar o problema investindo em novas tecnologias de refrigeração e purificação do ar. Em São Paulo, os edifícios Brazilian Financial Center (BFC), Eldorado Business Tower e 17007 Nações — Sigma Tower, que fazem parte do portfólio da Brookfield Properties, contam com a certificação Well Healt</p>
                        <span>Veja matéria completa em: </span><a href="https://valor.globo.com/patrocinado/imoveis-de-valor/noticia/2022/10/07/segmento-corporativo-investe-na-qualidade-do-ar.ghtml" target="_blank" rel="noreferrer">https://valor.globo.com/patrocinado/imoveis-de-valor/noticia/2022/10/07/segmento-corporativo-investe-na-qualidade-do-ar.ghtml</a>
                    </div>
                </div>
                <Footer />
            </div>
        </QualidadeArStyle>
    )
}

const QualidadeArStyle = styled.div`
    .QualidadeAr-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default QualidadeAr