import { Link } from "react-router-dom"
// import { Fade } from 'react-reveal'
import styled from "styled-components"

function News(props) {
    return (
        <NewsStyle>
            <div className="noticia-content"> 
                {/* <Fade right> */}
                    <img alt="noticia" src={ props.img } />
                    <h3>{ props.titulo }</h3>
                    <span>{ props.data }</span>
                    <p>{ props.descricao }</p>
                    <Link to={ `/noticias/${props.pagina}` }><button className="button-global">ler mais</button></Link>
                {/* </Fade> */}
            </div>
        </NewsStyle>
    )
} 

const NewsStyle = styled.div`
    .noticia-content {
        cursor: default;

        p {
            text-align: justify;
            color: var(--text-color);
            font-size: .9rem;
        }

        button {
            background-color: var(--primary-color);
            border: none;
            color: var(--white-color);
            cursor: pointer;
        }

        img {
            border-radius: 8px;
        }

        span {
            font-size: 0.8rem;
        }
    }

    @media screen and (min-width: 1501px) {
        height: 40vh;

        .noticia-content {
            margin: 10vh 4vw;
            
            img {
                width: 20vw;
                height: 22vh;
            }

            h3 {
                padding: 2vh 1vw 0 0;
                font-size: 1rem;
                height: 12vh;
            }

            p {
                padding: 2vh 1vw;
                height: 15vh;
            }

            button {
                padding: 8px 18px;
            }
        }
    }

    @media screen and (min-width: 951px) and (max-width: 1500px) {
        height: 40vh;

        .noticia-content {
            margin: 10vh 4vw;
            
            img {
                width: 20vw;
                height: 20vh;
            }

            h3 {
                padding: 2vh 1vw 0 0;
                font-size: 1rem;
                height: 12vh;
            }

            p {
                padding: 2vh 1vw;
                height: 15vh;
            }

            button {
                padding: 8px 18px;
            }
        }
    }

    @media screen and (max-width: 950px) {
        padding: 1vh 8vw;
        height: 70vh;

        .noticia-content {
            img {
                width: 180px;
                height: auto;
            }

            h3 {
                padding: 2vh 8vw;
                font-size: 1rem;
            }

            p {
                padding: 2vh 6vw;
                font-size: 0.9rem;
                text-align: center;
            }

            button {
                padding: 8px 18px;
            }
        }
    }
`

export default News