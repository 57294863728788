import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import PageVideo from '../../../components/pageVideo'

function ImportanciaVentilacao() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Por que ventilação é mais importante que limpar superfícies, segundo cientistas</h1>
                <div className='paginas-noticias-content'>
                    <PageVideo
                        subtitulo="Por que ventilação é mais importante que limpar superfícies, segundo cientistas"
                        data="26/04/2021"
                        descricao="A chance de o contato de uma pessoa com uma superfície contaminada pelo coronavírus resultar em uma infecção é menor que 1 em 10 mil, segundo o Centro de Controle e Prevenção de Doenças (CDC, na sigla em inglês), agência de saúde pública dos Estados Unidos."
                        video="https://www.youtube.com/embed/x_MsCJvDqe8"
                    />
                    <span>Fonte: </span><a href="https://www.bbc.com/portuguese/geral-56723635" target="_blank" rel="noreferrer">https://www.bbc.com/portuguese/geral-56723635</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ImportanciaVentilacao