import styled from 'styled-components'

import background from '../images/banners/bannerBack.png'
import infografico from '../images/identidade/infografico.png'

function BannerOzonio() {
    return (
        <BannerOzonioStyled>
            <div className="banner-left">
                <h1>Sobre o Ozônio (O₃)</h1>
                <p>A molécula de ozônio (O₃) é uma composição de 3 átomos de oxigênio.</p>
                <p>É um bactericida e germinicida natural e, por apresentar diversas propriedades, sendo uma delas a oxidação na purificação e esterilização do ar ambiente, evita a contaminação cruzada por bactérias, vírus, germes, fungos, microorganismos e odores indesejáveis.</p>
            </div>
            <div className="banner-right">
                <img className="infografico" src={infografico} alt="infografico" />
            </div>
        </BannerOzonioStyled>
    )
}

const BannerOzonioStyled = styled.div`
    width: 100%;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 100%;

    h1 {
        color: white;
    }

    p {
        color: white;
    }
    
    @media screen and (min-width: 1001px) and (max-width: 3500px) {
        height: auto;
        display: flex;
        padding: 50px 140px;
        
        .banner-left {
            h1 {
                font-size: 1.6rem;
            }

            p {
                font-size: 12px;
                padding: 20px 120px 0 0;
                line-height: 30px;
                text-align: justify;
            }
        }

        .banner-right {
            .infografico {
                width: 700px;
                height: auto;
            }
        }
    }

    @media screen and (min-width: 1001px) and (max-width: 1500px) {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .banner-left {
            width: 100vw;
            h1 {
                font-size: 1.6rem;
            }

            p {
                font-size: 12px;
                line-height: 30px;
                text-align: justify;
            }
        }

        .banner-right {
            .infografico {
                width: 100%;
                height: auto;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        height: 100vh;
        padding: 50px 40px;
        text-align: center;
        
        .banner-left {
            h1 {
                font-size: 1.8rem;
            }

            p {
                font-size: 15px;
                padding: 20px 20px 0 0;
                line-height: 30px;
                text-align: justify;
            }
        }

        .banner-right {
            
            .infografico {
                margin: 80px auto;
                width: 100%;
                height: auto;
            }
        }
    }
`

export default BannerOzonio