import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import ImgBergCampos from '../../../images/noticias/berg-campos.jpg'

function DeclaracaoBergCampos() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Declaração de Berg Campos, Prefeito de Porto de Moz</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        subtitulo="Declaração de Berg Campos, Prefeito de Porto de Moz"
                        data="01/06/2020"
                    />
                    <img src={ ImgBergCampos } alt="imagem thumb" />
                    <p>Nessa guerra contra um inimigo invisível, estamos apostando na técnica da ozônio terapia. A Prefeitura Municipal de Porto de Moz, através da Secretaria de Saúde adquiriu 30 geradores de ozônio, para purificação de ambientes fechados, para eliminar vírus e bactérias. Instalamos hoje no hospital Ana Nery nas salas de cirurgia, de parto, cozinha e várias outras.</p>
                    <p>Podemos perder algumas batalhas, mas em nome de Jesus venceremos essa guerra contra o covid-19.</p>
                    <p>🙏🙏🙏🙏</p>
                    <p>Governo União, força e trabalho.</p>
                    <p>Secretaria municipal de Saúde</p>
                    <p>Gente cuidando de Gente 🙏👍🤝🧡</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default DeclaracaoBergCampos