import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'
import NovaOnda from "../../../images/noticias/nova-onda.webp";

function NovaOndaEuropa() {
    return (
        <NovaOndaEuropaStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Covid: o que nova onda de casos na Europa significa para o Brasil</h1>
                    <div className='paginas-noticias-content NovaOndaEuropa'>
                        <Page
                            subtitulo="Covid: o que nova onda de casos na Europa significa para o Brasil"
                            data="25/10/2022"
                            img={NovaOnda}
                        />
                        {/* <img src={ NovaOndaEuropaImg } alt="noticia" /> */}
                        <p><strong>"Ainda que não estejamos na mesma situação que passamos há um ano, está claro que a pandemia de covid-19 não acabou. Infelizmente, vemos os indicadores subirem de novo na Europa, o que sugere o início de uma nova onda de infecções."</strong></p>
                        <p>Esse é o primeiro parágrafo de uma declaração conjunta publicada em 12 de outubro pelos líderes do Centro de Controle e Prevenção de Doenças da Europa (ECDC) e por representantes da Organização Mundial da Saúde (OMS) neste continente.</p>
                        <span>Veja matéria completa em: </span><a href="https://www.bbc.com/portuguese/internacional-63330741" target="_blank" rel="noreferrer">https://www.bbc.com/portuguese/internacional-63330741</a>
                    </div>
                </div>
                <Footer />
            </div>
        </NovaOndaEuropaStyle>
    )
}

const NovaOndaEuropaStyle = styled.div`
    .NovaOndaEuropa-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default NovaOndaEuropa