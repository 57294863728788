import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import AltaCovid from '../../../images/noticias/alta-covid.png'

function NovaOnda() {
    return (
        <NovaOndaStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Alta de casos positivos pode indicar nova onda de Covid-19, diz infectologista</h1>
                    <div className='paginas-noticias-content NovaOnda'>
                        <Page
                            subtitulo="Alta de casos positivos pode indicar nova onda de Covid-19, diz infectologista"
                            data="25/10/2022"
                            img={AltaCovid}
                        />
                        {/* <img src={ NovaOndaImg } alt="noticia" /> */}
                        <p>Em entrevista à CNN, a infectologista do Instituto Emílio Ribas, Rosana Richtmann, avaliou que a recente crescente de casos positivos do coronavírus ao redor do mundo pode indicar uma nova onda da doença, originada por uma série de fatores</p>
                        <p>Em entrevista à CNN neste sábado (5), a infectologista do Instituto Emílio Ribas, Rosana Richtmann, disse que é possível falar em uma nova onda de Covid-19 diante do aumento no número de casos, no Brasil e no mundo.</p>
                        <p>“Vemos uma maior procura no pronto-socorro, nos consultórios, nas farmácias… Tudo leva a crer que estamos tendo uma repercussão do que está acontecendo no resto do mundo. É preciso observar o que ainda vai acontecer, mas a tendência é que estamos diante, sim, de uma nova de casos”, disse a especialista ao CNN Sábado.</p>
                        <span>Veja matéria completa em: </span><a href="https://www.cnnbrasil.com.br/saude/alta-de-casos-positivos-pode-indicar-nova-onda-de-covid-19-diz-infectologista/" target="_blank" rel="noreferrer">https://www.cnnbrasil.com.br/saude/alta-de-casos-positivos-pode-indicar-nova-onda-de-covid-19-diz-infectologista/</a>
                    </div>
                </div>
                <Footer />
            </div>
        </NovaOndaStyle>
    )
}

const NovaOndaStyle = styled.div`
    .NovaOnda-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default NovaOnda