import styled from 'styled-components'

import Apresentacao from '../videos/Video-Sanitech.mp4'
import Instrucao from '../videos/instrucao-linha-puri.mp4'
import Manutencao from '../videos/manutencao-linha-puri.mp4'

function ProductVideo() {
    return (
        <ProductVideoStyle>
            <h2>Confira Nossos Videos</h2>

            <ul>
                <li>
                    <video controls="controls" type="video/mp4" preload="auto">
                        <source src={Apresentacao} autostart="false" />
                    </video>
                    <p>Apresentação da <strong>Linha PURI MU</strong></p>
                </li>

                <li>
                    <video controls="controls" type="video/mp4" preload="auto">
                        <source src={Instrucao} autostart="false" />
                    </video>
                    <p>Instalação da Linha <strong>Sanitech O3 PURI</strong></p>
                </li>

                <li>
                    <video controls="controls" type="video/mp4" preload="auto">
                        <source src={Manutencao} autostart="false" />
                    </video>
                    <p>Manutenção da linha <strong>Sanitech O3 PURI</strong></p>
                </li>
            </ul>
        </ProductVideoStyle>
    )
}

const ProductVideoStyle = styled.div`
    text-align: center;

    p {
        text-align: center;
    }

    ul {
        li {
            p {
                color: var(--text-color);
            }
        }

        video {
            border-radius: 12px;
        }
    }

    @media screen and (min-width: 1501px) {
        h2 {
            margin-top: 15vh;
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10vh;
            margin-bottom: 10vh;
            position: relative;

            video {
                width: 20vw;
                height: auto;
                margin: 2vh 5vw;
            }
        }
    }

    @media screen and  (max-width: 1500px) and (min-width: 801px) {
        h2 {
            margin-top: 15vh;
        }

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10vh;
            margin-bottom: 10vh;

            video {
                width: 20vw;
                border-radius: 24px;
                margin: 4vh 5vw;
            }

            p {
                margin: 0 auto;
                max-width: 20ch;
            }
        }
    }

    @media screen and (max-width: 950px) {
        
        h2 {
            margin: 10vh 0;
            font-size: 1.5rem;
        }

        ul {
            display: block;
            margin-top: 10vh;
            margin-bottom: 10vh;

            li {
                margin-top: 5vh;

                video {
                    width: 50%;
                    height: auto;
                }               
            }
        }
    }
`

export default ProductVideo