

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

function PrefeituraPoluicao() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Prefeitura é notificada sobre poluição nas lagoas da Zona Oeste</h1>
                <div className='paginas-noticias-content ozonio-prevencao'>
                    <Page
                        subtitulo="Prefeitura é notificada sobre poluição nas lagoas da Zona Oeste"
                        data="16/04/2019"
                        descricao="MPF/RJ e MPRJ intimam Prefeitura a apresentar solução para sanamento nas comunidades da região."
                    />
                    <a href="https://globoplay.globo.com/v/7505325/?utm_source=whatsapp&utm_medium=share-bar" target="_blank" rel="noreferrer"><strong>Assista o video</strong></a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrefeituraPoluicao