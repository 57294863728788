import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'
import SaoPaulo from '../../../images/noticias/Bandeira_do_estado_de_São_Paulo.svg.png'

function SaoPauloMorteVariante() {
    return (
        <SaoPauloMorteVarianteStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Covid-19: São Paulo tem primeira morte pela nova subvariante da Ômicron</h1>
                    <div className='paginas-noticias-content SaoPauloMorteVariante'>
                        <Page
                            subtitulo="Covid-19: São Paulo tem primeira morte pela nova subvariante da Ômicron"
                            data="25/10/2022"
                            img={SaoPaulo}
                        />
                        {/* <img src={ SaoPauloMorteVarianteImg } alt="noticia" /> */}
                        <p>O secretário de Estado da Saúde de São Paulo, Jean Gorinchteyn, confirmou nesta terça-feira, 8, a primeira morte por covid-19 no Estado causada pela nova subvariante BQ.1, associada à alta de casos da doença em outros países. A secretaria já havia confirmado dois casos na capital dessa nova versão da Ômicron na manhã desta terça, mas não tinha divulgado o óbito.</p>
                        <span>Veja matéria completa em: </span><a href="https://www.estadao.com.br/saude/covid-19-sao-paulo-tem-primeira-morte-pela-nova-subvariante-da-omicron/?utm_source=estadao:whatsapp&utm_medium=link" target="_blank" rel="noreferrer">https://www.estadao.com.br/saude/covid-19-sao-paulo-tem-primeira-morte-pela-nova-subvariante-da-omicron/?utm_source=estadao:whatsapp&utm_medium=link</a>
                    </div>
                </div>
                <Footer />
            </div>
        </SaoPauloMorteVarianteStyle>
    )
}

const SaoPauloMorteVarianteStyle = styled.div`
    .SaoPauloMorteVariante-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default SaoPauloMorteVariante