import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import TesteCovid from '../../../images/noticias/teste_covid_19_rapido.webp'

function NovaCepa() {
    return (
        <NovaCepaStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Nova cepa tem poder de transmissão maior do que a Ômicron, diz infectologista</h1>
                    <div className='paginas-noticias-content NovaCepa'>
                        <Page
                            subtitulo="Nova cepa tem poder de transmissão maior do que a Ômicron, diz infectologista"
                            data="25/10/2022"
                            img={TesteCovid}
                        />
                        {/* <img src={ NovaCepaImg } alt="noticia" /> */}
                        <p>O aumento de casos de Covid-19 na Europa, Estados Unidos e Canadá serve de alerta para o Brasil, que já apresenta um aumento de testes positivos, de acordo com a Abrafarma.</p>
                        <p>Esta é a avaliação do infectologista e consultor da Sociedade Brasileira de Infectologia Márcio Nehab.

À CNN Rádio, há uma “conjunção de fatores” que explica a escalada de casos, que pode se transformar em uma nova onda.

“Existe um tempo que tivemos nossa última onda, há diminuição da imunidade, e também das vacinas, além da entrada de novas cepas.”</p>
                        <span>Veja matéria completa em: </span><a href="https://www.cnnbrasil.com.br/saude/nova-cepa-tem-poder-de-transmissao-maior-do-que-a-omicron-diz-infectologista/" target="_blank" rel="noreferrer">https://www.cnnbrasil.com.br/saude/nova-cepa-tem-poder-de-transmissao-maior-do-que-a-omicron-diz-infectologista/</a>
                    </div>
                </div>
                <Footer />
            </div>
        </NovaCepaStyle>
    )
}

const NovaCepaStyle = styled.div`
    .NovaCepa-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default NovaCepa