import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import ImgEncathoExprotel from '../../../images/noticias/encatho-exprotel.jpeg'

function Encatho() {
    return (
        <EncathoStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Encatho & Exprotel 2018</h1>
                    <div className='paginas-noticias-content encantho'>
                        <Page
                            subtitulo="Encatho & Exprotel 2018"
                            data="18/07/2018"
                            descricao="Promovido pela Associação Brasileira da Indústria de Hotéis de Santa Catarina- ABIH-SC, o Encontro Catarinense de Hoteleiros- Encatho & Exprotel chega à 31ª edição. O objetivo é apresentar as inovações, tendências e perspectivas para a hotelaria e o turismo, sem esquecer que a principal ferramenta de hospitalidade continua sendo o ser humano. O evento irá oportunizar negócios, qualificação, novidades em produtos e serviços, atualização na operação, gerar networking e divulgar as potencialidades do turismo nacional.

                            Informações
                            Categoria: Evento
                            Data de início: 24/07/2018
                            Data de término: 26/07/2018
                            Cidade: Florianópolis
                            Local: CentroSul
                            Endereço:
                            Av. Gov. Gustavo Richard, 850 – Centro, Florianópolis – SC , Florianópolis / SC"
                        />
                        <img src={ ImgEncathoExprotel } alt="imagem expotel" />
                        <a href="https://encatho.com.br/programacao/" target="_blank" rel="noreferrer"><strong>Confira o programa aqui</strong><br/></a>
                        <a href="https://eventos.sigevent.com/visitantes/formularios.php?id_edicao=41" target="_blank" rel="noreferrer"><strong>Cadastre-se aqui</strong></a>
                    </div>
                </div>
                <Footer />
            </div>
        </EncathoStyle>
    )
}

const EncathoStyle = styled.div`
    .encantho {
        text-align: center;
        img {
            width: 40vw;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    }
`

export default Encatho