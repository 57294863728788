import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import ImgParoquia01 from '../../../images/noticias/2021.06.21-paroquiasj_01.jpeg'
import ImgParoquia03 from '../../../images/noticias/2021.06.21-paroquiasj_03.jpeg'
import ImgParoquia04 from '../../../images/noticias/2021.06.21-paroquiasj_04.jpeg'
import ImgParoquia05 from '../../../images/noticias/2021.06.21-paroquiasj_05.jpeg'
import ImgParoquia06 from '../../../images/noticias/2021.06.21-paroquiasj_06.jpeg'
import ImgParoquia07 from '../../../images/noticias/2021.06.21-paroquiasj_07.jpeg'
import ImgSanitechO340 from '../../../images/produtos/Sanitech-O3-40.jpg'
import InclusaoImg from "../../../images/noticias/inclusao.png";

function Paroquia() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"}/>
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Paróquia de São José – Itaipava – Petrópolis – RJ</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        subtitulo="Paróquia de São José – Itaipava – Petrópolis – RJ"
                        data="10/09/2021"
                        img={InclusaoImg}
                    />
                    <p>Instalação do <a href="https://sanitech.eco.br/produto/puri-mu-turbo-duplo/" target="_blank" rel="noreferrer"><strong>Sanitech O3 Puri MU TURBO DUPLO</strong></a> na <strong>Paróquia de São José</strong>, Itaipava, Petrópolis – RJ para a prevenção no ambiente.
                    Satisfação do cliente é objetivo principal para a <strong>Astech</strong>.</p>
                    <div className="pn-fotos">
                        <div className="pn-thumbnail">
                            <ul>
                                <li><img alt="imagem paroquia" src={ ImgParoquia01 } /></li>
                                <li><img alt="imagem paroquia" src={ ImgParoquia03 } /></li>
                            
                                <li><img alt="imagem paroquia" src={ ImgParoquia04 } /></li>
                                <li><img alt="imagem paroquia" src={ ImgParoquia05 } /></li>
                                <li><img alt="imagem paroquia" src={ ImgParoquia06 } /></li>
                            
                                <li><img alt="imagem paroquia" src={ ImgParoquia07 } /></li>
                                <li><img alt="imagem paroquia" src={ ImgSanitechO340 } /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Paroquia