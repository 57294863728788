import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import CriancaRespiradorImage from '../../../images/noticias/crianca-respirador.webp'

function CriancaRespirador() {
    return (
        <CriancaRespiradorStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Fiocruz aponta aumento de casos de vírus sincicial respiratório em crianças</h1>
                    <div className='paginas-noticias-content CriancaRespirador'>
                        <Page
                            subtitulo="Fiocruz aponta aumento de casos de vírus sincicial respiratório em crianças"
                            data="11/10/2022"
                            img={CriancaRespiradorImage}
                        />
                        {/* <img src={ CriancaRespiradorImg } alt="noticia" /> */}
                        <p>A Fundação Oswaldo Cruz (Fiocruz) aponta para o aumento de casos semanais associados ao vírus sincicial respiratório (VSR) em crianças de 0 a 4 anos no país. Os dados foram divulgados nesta sexta-feira (4) em nova edição do boletim InfoGripe.</p>
                        <p>O levantamento, referente ao período de 23 a 29 de outubro, tem como base os dados inseridos no Sistema de Informação de Vigilância Epidemiológica da Gripe (Sivep-Gripe) até o dia 31 de outubro.</p>
                        <p>O vírus sincicial respiratório (VSR) é um dos principais causadores das infecções que afetam o trato respiratório inferior de crianças menores de 2 anos de idade. O vírus poded ser responsável por até 75% das bronquiolites e 40% das pneumonias durante os períodos da sazonalidade, de acordo com a Sociedade Brasileira de Pediatria (SBP).</p>
                        <span>Veja matéria completa em: </span><a href="https://www.cnnbrasil.com.br/saude/fiocruz-aponta-aumento-de-casos-de-virus-sincicial-respiratorio-em-criancas/" target="_blank" rel="noreferrer">https://www.cnnbrasil.com.br/saude/fiocruz-aponta-aumento-de-casos-de-virus-sincicial-respiratorio-em-criancas/</a>
                    </div>
                </div>
                <Footer />
            </div>
        </CriancaRespiradorStyle>
    )
}

const CriancaRespiradorStyle = styled.div`
    .CriancaRespirador-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default CriancaRespirador