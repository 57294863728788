import styled from 'styled-components'

import Header from '../components/header'
import Footer from '../components/footer'

import image1 from '../images/identidade/photo.jpg'
import image2 from '../images/identidade/photo-2.jpg'

function NossaHistoria() {
    return (
        <div>
            <Header />
            <NossaHistoriaStyled className="body">
                <h1 className="title">Nossa História</h1>
                <div className="section">
                    <h2 className="title-d">2015</h2>
                    <div className="style">
                        <div className="box b1"></div>
                        <div className="box b2"></div>
                    </div>
                    <div className="container">
                        <div className="container-left">
                            <p className="desc p-left">A ASTECH criada em 2015 é uma empresa de base tecnológica, localizada no Distrito de Itaipava, na Cidade de Petrópolis/RJ, <strong>especializada</strong> no desenvolvimento de produtos sustentáveis e soluções inovadoras.</p>
                            <p className="desc p-left">O que motivou a criação da ASTECH, foi o desenvolvimento de uma cápsula inflável de barreira hermética <strong>(INFLATECH)</strong> de proteção, preservação e segurança.</p>
                        </div>
                        <div className="container-right">
                            <img src={image1} alt="imagem" />
                        </div>
                    </div>
                </div>

                <div className="section">
                    <h2 className="title-d">2017</h2>
                    <div className="style">
                        <div className="box b1"></div>
                        <div className="box b2"></div>
                    </div>
                    <p className="desc">Em 2017, a ASTECH incorpora a tecnologia de geração do ozônio (O₃) no desenvolvimento de equipamentos geradores de ozônio (O₃) <strong>(SANITECH)</strong>, com o objetivo na purificação, esterilização do <strong>ar ambiente habitável</strong>, na oxi-sanitização e desinfecção do <strong>ar ambiente não habitável</strong> e superfícies, no combate a infecção hospitalar, na descontaminação de sapatos, no tratamento de água potável e reuso e outras aplicabilidades.</p>
                </div>

                <div className="section">
                    <h2 className="title-d">2020 - 2021</h2>
                    <div className="style">
                        <div className="box b1"></div>
                        <div className="box b2"></div>
                    </div>
                    <div className="container">
                        <div className="container-left">
                            <img src={image2} alt="imagem" style={{ marginTop: '16px' }} />
                        </div>
                        <div className="container-right">
                            <p className="desc">Nos anos de 2020 e 2021, as grandes empresas preocupadas em mitigar o risco da contaminação cruzada através de aerossóis no ambiente, consequência da pandemia de COVID-19, tiveram a necessidade de procurar alternativas eficazes no mercado, para ambientes habitados, que não fossem saneantes químicos.</p>
                            <p className="desc">O ozônio (O₃) em baixa concentração foi a melhor solução, já comprovada, sendo um virucida, bactericida e germicida natural e por ter diversas propriedades, sendo uma delas a eficácia da oxidação da membrana celular das bactérias que estão presentes nos aerossóis.</p>
                            <p className="desc">Diante da oportunidade não medimos esforços e investimos em novas instalações, laboratório de engenharia mecatrônico, laboratório de 3D, área de produção, estoque semi-acabado, estoque acabado, expedição, showroom, reforma da cozinha e outros, para o aprimoramento e qualidade dos nossos equipamentos.</p>
                            <p className="desc">Em 2020 fomos procurados pela Dra. Karyne Rangel, Doutora em Ciências pelo Programa de Pós-Graduação em Vigilância Sanitária do Instituto Nacional de Controle de Qualidade em Saúde (PPGVS/INCQS) e pesquisadora do Laboratório de Bioquímica de Proteínas e Peptídeos do Centro de Desenvolvimento Tecnológico em Saúde da <strong>Fundação Oswaldo Cruz (LBPP/CDTS/FIOCRUZ),</strong> realizando seu segundo Pós-Doutorado na área de microbiologia, nos propôs um estudo colaborativo sobre a eficácia do ozônio (O₃), assunto de grande interesse atual, em virtude dos acontecimentos.</p>
                            <p className="desc">Diante da proposta de estudar o efeito do ozônio (O₃) sobre bactérias patogênicas, a ASTECH não mediu esforços em colaborar com o projeto, disponibilizando inicialmente o equipamento purificador e esterilizador de ar ambiente <strong>SANITECH Linha PURI</strong> juntamente com o detector de gás portátil para monitoramento da concentração ambiental de ozônio (O₃) emitido.</p>
                            <p className="desc">Como era de se esperar, os testes obtiveram resultados preliminares surpreendentes, mostrando que uma baixa concentração de ozônio (O₃) <strong>(média de 0,014 ppm)</strong> foi capaz de interferir significativamente na viabilidade celular da maioria das bactérias estudadas através da inibição do crescimento.</p>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <h2 className="title-d">2022</h2>
                    <div className="style">
                        <div className="box b1"></div>
                        <div className="box b2"></div>
                    </div>
                    <h3 className="desc">O ano de 2022 foi desafiador para a Astech no desenvolvimento de novas tecnologias:</h3>

                    <ul>
                        <li><strong className="dot">.</strong> Desenvolvimento de novos equipamentos <strong>SANITECH O₃ LINHA PURI</strong> para ambientes habitados em baixa concentração de ozônio (O₃) com tecnologia da ASTECH;</li>
                        <li><strong className="dot">.</strong> Autorizado a concessão do Pedido Nacional de Invenção, Modelo de Utilidade, Certificado de Adição de Invenção dos nossos equipamentos<strong>SANITECH O₃ LINHA PURI. </strong>Patente depositado nº BR 20 2021009339 6;</li>
                        <li><strong className="dot">.</strong> Melhorias na tecnologia dos equipamentos, utilizando o conceito Indústria 4.0, também chamada de Quarta Revolução Industrial. Este fenômeno trará um ganho de escala na automação e troca de dados nos equipamentos instalados nos clientes, medindo a concentração de ozônio (O₃), funcionamento e manutenção preventiva e corretiva em tempo real;</li>
                        <li><strong className="dot">.</strong> Desenvolvimento dos equipamentos de medição de ozônio (O₃), <strong>Sensores de Ozônio (O₃)</strong> de baixa e alta concentrações com tecnologia da ASTECH;</li>
                        <li><strong className="dot">.</strong> Novas pesquisas e testes, com equipamentos geradores de ozônio (O₃) de alta concentração para desinfecção, junto a Fiocruz com superbactérias, mitigando a infecção hospitalar para ambientes não habitados;</li>
                        <li><strong className="dot">.</strong> Desenvolvimento do equipamento <strong>SANITECH UVC</strong> de descontaminação de solas de sapatos com tecnologia da ASTECH;</li>
                        <li><strong className="dot">.</strong> Novas pesquisas e testes, com o equipamento <strong>SANITECH UVC</strong> de descontaminação de solas de sapatos, junto a Fiocruz com superbactérias, mitigando a infecção hospitalar para ambientes não habitados;</li>
                        <li><strong className="dot">.</strong> Publicações na revista Americana MDPI, dos artigos enviados pela Fiocruz, do trabalho realizado pela equipe da pesquisadora Dra. Karyne Rangel.
                            <p>1 - Article - Detrimental Effect of Ozone on Pathogenic Bacteria;</p>
                            <a className="articleLink" href="https://www.mdpi.com/1423032" target='blank'>Link: https://www.mdpi.com/1423032</a>
                            <p>2 - Article - Potent Activity of a High Concentration of Chemical Ozone against Antibiotic-Resistant Bacteria;</p>
                            <a className="articleLink" href="https://www.mdpi.com/1420-3049/27/13/3998" target='blank'>Link: https://www.mdpi.com/1420-3049/27/13/3998</a>
                            <p>3 - Article - Effectiveness Evaluation of a UV-C-Photoinactivator against. Selected ESKAPE-E Pathogens.</p>
                            <a className="articleLink" href="https://www.mdpi.com/1660-4601/19/24/16559" target='blank'>Link: https://www.mdpi.com/1660-4601/19/24/16559</a>
                        </li>
                        <li><strong className="dot">.</strong> Conseguimos encontrar o artigo publicado em 2020 pelo professor Takayuki Murata da Universidade de Medicina de Toyoake do Japão, que atesta a eficiência do uso de purificadores de ar a base de ozônio (O₃) em baixa concentração, contra a redução da síndrome respiratória aguda de grave infectividade do Coronavírus-2: 
                        <a className="articleLink" href='https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe' target="blank">https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe</a>
                        </li>
                        <li><strong className="dot">.</strong> Participação do Pregão Eletrônico da Prefeitura de Manaus – AM, para locação, instalação e manutenção nas salas de aulas das Escolas Municipais e outras Secretarias e fomos o vencedor;</li>
                        <li><strong className="dot">.</strong> Desenvolvimento dos novos equipamentos <strong>SANITECH O₃ 40 e 80 PPM</strong> de oxi-sanitização e desinfecção para hospitais e outros segmentos com tecnologia da ASTECH;</li>
                        <li><strong className="dot">.</strong> Desenvolvimento dos novos equipamentos <strong>SANITECH O3 80 e 120 PPM</strong> de tratamento do esgoto doméstico no terciário (DBO líquido) e outros segmentos com tecnologia da ASTECH;</li>
                        <li><strong className="dot">.</strong> Desenvolvimento e aprimoramento dos novos equipamentos <strong>SANITECH O₃ MVO3 (Magnético, vórtex e ozônio (O₃))</strong> de tratamento de água potável, poço, igarapés e de reuso para caixas d'águas e cisternas de até 50 mil litros com tecnologia da ASTECH;</li>
                        <li><strong className="dot">.</strong> Desenvolvimento de uma <strong>Cabine de Descontaminação de Uniformes,</strong> utilizando nossos equipamentos<strong> SANITECH O₃ 40 e 80 PPM</strong> de oxi-sanitização e desinfecção para hospitais, indústrias de processamentos de alimentos e outros segmentos com tecnologia da ASTECH;</li>
                        <li><strong className="dot">.</strong> Desenvolvimento dos novos equipamentos <strong>SANITECH O₃ MED PRO MOBILE, VET PRO MOBILE</strong> para ozonioterapia, em atendimento aos médicos e veterinários com tecnologia da ASTECH;</li>
                        <li><strong className="dot">.</strong> Desenvolvimento do novo equipamento <strong>SANITECH O₃ ACQUA,</strong> para ozonioterapia no tratamento e lavagem de feridas do pé diabético e outros segmentos com tecnologia da ASTECH;</li>
                        <li><strong className="dot">.</strong> Desenvolvimento do novo equipamento <strong>SANITECH O₃ ACQUA,</strong> para diluição de saneantes químicos, lavagem e enxague de superfícies, fabricação de gelo para conservação do pescado, no uso em hospitais, clínicas, veterinárias, escolas, creches, banheiros públicos, cozinhas, restaurantes, supermercados, açougue, peixaria, salas de processamentos de alimentos e outros segmentos com tecnologia da ASTECH;</li>
                        <li><strong className="dot">.</strong> Desenvolvimento do novo equipamento <strong>SANITECH O₃ NEBULIZADOR COSTAL,</strong> para os pequenos agricultores que desenvolvem a agricultura familiar, utilizando água ozonizada nos cultivos das frutas, legumes e verduras (FLV) e outros segmentos com tecnologia da ASTECH;</li>
                        <li><strong className="dot">.</strong> Continuamos a insistir junto a ANVISA a regulamentação do ozônio (O₃) em ambientes habitáveis através de uma <strong>RDC - Regulamentação de Diretoria Colegiada</strong> e obtivemos uma resposta de <strong>MANIFESTAÇÃO FAVORÁVEL</strong> quanto à prestação da empresa ASTECH em obter uma oportunidade para apresentar e testar seus equipamentos no âmbito da Assistência à Saúde com o objetivo da comprovação efetiva de sua eficácia através da Nota Técnica <strong>n°34/2022/SEI/COSAN/GHCOS/DIRE3/ANVISA. Processo n° 25000.038041/2020-51;</strong></li>
                        <li><strong className="dot">.</strong> Desenvolvimentos de novas parcerias com fabricantes e fornecedores de componentes nacionais;</li>
                        <li><strong className="dot">.</strong> Reestruturação da área comercial, para melhor atender o mercado de locação de equipamento;</li>
                        <li><strong className="dot">.</strong> Planejamento de abertura das filiais Manaus e Brasília;</li>
                    </ul>
                </div>

                <div className="section">
                    <h2 className="title-d">2023</h2>
                    <div className="style">
                        <div className="box b1"></div>
                        <div className="box b2"></div>
                    </div>

                    <ul>
                        <li><strong className="dot">.</strong> Está sendo um ano muito difícil, diante de uma retração na economia do nosso país e o mercado em geral se estagnou-se;</li>
                        <li><strong className="dot">.</strong> Paramos com os novos desenvolvimentos e estamos avaliando a retomada para 2024;</li>
                        <li><strong className="dot">.</strong> Procurando investidores para investimentos em novos projetos;</li>
                        <li><strong className="dot">.</strong> A área comercial está trabalhando outros estados da federação, apresentando os novos produtos e insistindo na cultura da prevenção e precaução da contaminação cruzada por bactérias, vírus, germes e fungos, inclusive do Candida auris (C. auris) que é um fungo emergente que representa uma grave ameaça à saúde global e foi identificado pela primeira vez como causador de doença em humanos em 2009, no Japão;</li>
                    </ul>
                </div>
            </NossaHistoriaStyled>
            <Footer />
        </div>
    )
}

const NossaHistoriaStyled = styled.div`
    a {
        display: block;
    }

    .articleLink {
        font-weight: 500;
        margin: 5px 0;
        color: var(--primary-color);
    }

    ul {
        display: block;

        .dot {
            font-size: 32px;
        }

        li {
            text-align: justify;
            color: var(--text-color) !important;
            padding: 1vh 0 0 4vw;
            line-height: 32px;
        }
    }

    @media screen and (min-width: 1001px) and (max-width: 3500px) {
        .section {
            .container {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: justify;

                img {
                    width: 400px;
                    height: auto;
                    border-radius: 64px 0 64px 0;
                }
            }
        }
    }

    @media screen and (min-width: 801px) and (max-width: 1000px) {
        .section {
            .container {
                img {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 800px) {
        .section {
            .container {
                img {
                    display: none;
                }
            }
        }
    }
`

export default NossaHistoria