import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import App from './App';

import GlobalStyle from './GlobalStyle'

import Whatsapp from './components/whatsapp'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <GlobalStyle />
      <App />
      {/* <Whatsapp /> */}
    </React.StrictMode>
  </BrowserRouter>
);