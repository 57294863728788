import styled from 'styled-components'
import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'
import Page from '../../../components/page'

import eletronica from '../../../images/noticias/eletronica.png'
import circuito from '../../../images/noticias/circuito.png'
import ozonio from '../../../images/noticias/ozonio.png'
import premio from '../../../images/svg/premio.svg'
import InclusaoImg from "../../../images/noticias/inclusao.png";

function Inclusao() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <InclusaoStyled>
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Astech - Inclusão Social</h1>
                    <div className='paginas-noticias-content Inclusao'>
                        {/* <img src={ ImgNotaAboz } /> */}
                        <Page
                            subtitulo="Inclusao - Nota de Esclarecimento Jurídico"
                            data="08/07/2022"
                            img={InclusaoImg}
                        />
                        <p>
                            A Astech preocupada com a inclusão social de jovens carentes da Região Serrana do Rio de Janeiro, tem como objetivo desenvolver um projeto social,  formando profissionais juniors nas disciplinas de circuito eletrônicos, montagem de equipamentos eletrônicos e conhecimento básico de ozônio (O3).  Estes receberão ajuda de custo de passagem, refeições servidas dentro da empresa durante o período de 3 meses.
                        </p>
                        <div className="container">
                            <div className="itens">
                                <div className="item">
                                    <img alt="icon" src={circuito} />
                                    <h3>Circuitos Eletrônicos</h3>
                                </div>

                                <div className="item">
                                    <img alt="icon" src={eletronica} />
                                    <h3>Montagem de Equipamentos Eletrônicos</h3>
                                </div>

                                <div className="item">
                                    <img alt="icon" src={ozonio} />
                                    <h3>Conhecimento Básico de Ozônio</h3>
                                </div>
                            </div>
                        </div>

                        <div className="premio">
                            <img alt="icon" src={premio} />
                            <p>Após o término do curso, os alunos com melhor rendimento serão convidados para trabalhar na Astech, conforme vagas disponíveis e o perfil dos alunos.</p>
                        </div>
                    </div>
                </div>
            </InclusaoStyled>
            <Footer />
        </div>
    )
}

const InclusaoStyled = styled.div`

.container {
            .itens {
                display: flex;
                align-items: center;
                justify-content: center;

                .item {
                    height: 30vh;
                    margin: 3vh 3vw;
                    width: 14vw;
                    text-align: center;
                    img {
                        width: 8vw;
                        height: auto;
                    }

                    h3 {
                        margin-top: 2vh;
                    }
                }
            }
        }

        .premio {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 8vh auto;

                img {
                    width: 4vw;
                    height: auto;
                
            }
        }
`

export default Inclusao