import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import PageVideo from '../../../components/pageVideo'

import VideoOzonioterapia from '../../../videos/ozonioterapia-prevencao.mp4'

function OzonioterapiaPrevencao() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Ozonioterapia na prevenção de doenças e para antienvelhecimento</h1>
                <div className='paginas-noticias-content ozonio-prevencao'>
                    <PageVideo
                        subtitulo="Ozonioterapia na prevenção de doenças e para antienvelhecimento"
                        data="16/04/2019"
                        descricao="Ozonioterapia na prevenção de doenças e para antienvelhecimento…
                        Realizamos tratamentos com ozônio no Coi Lajeado!!! #ozonioterapia"
                        video={ VideoOzonioterapia }
                    />
                    <span>Fonte: </span><a href=" https://www.instagram.com/p/BveJJqggT2u/?utm_source=ig_share_sheet&igshid=mpzm25il3ih3" target="_blank" rel="noreferrer"> https://www.instagram.com/p/BveJJqggT2u/?utm_source=ig_share_sheet&igshid=mpzm25il3ih3</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OzonioterapiaPrevencao