import styled from 'styled-components'

import background from '../images/banner2.png'

import whatsapp from '../images/svg/whatsappBranco.svg'
import youtube from '../images/svg/youtubeBranco.svg'
import facebook from '../images/svg/facebookBranco.svg'
import instagram from '../images/svg/instagramBranco.svg'
import linkedin from '../images/svg/linkedinBranco.svg'

function Footer() {
    return (
        <FooterStyled>
            <div className="container-footer">
                <div className="item-footer">
                    <h3>ENDEREÇO FISCAL</h3>
                    <div className="description">
                        <p>Rua Flávio Muniz, n° 15, Condomínio Industrial Oswaldo Cruz, Distrito de Itaipava, Petrópolis - RJ <br />CEP 25.750-040 <br /> CNPJ: 22.356.912/0001-33 </p>
                    </div>
                </div>

                <div className="item-footer">
                    <h3>ENDEREÇO DE ENTREGA</h3>
                    <div className="description">
                        <p>Estrada União Indústria, n° 15.500, Galpão n° 15, Condomínio Industrial Oswaldo Cruz, Distrito de Pedro do Rio, Petrópolis - RJ - CEP 25.750-226</p>
                    </div>
                </div>

                <div className="item-footer">
                    <h3>CONTATO</h3>
                    <div className="description">
                        <p>+55 (24)2246-6681</p>
                        
                        <p>contato@sanitech.eco.br</p>
                    </div>
                </div>
{/*
                <div className="item-footer">
                    <h3>REDES SOCIAIS</h3>

                    <div className="description">
                        <a href="https://www.youtube.com/@astechempresasocialbrasil8988" target="_blank" rel="noreferrer">
                            <img className="icon" src={youtube} alt="youtube" />
                        </a>
                        <img className="icon" src={facebook} alt="facebook" />
                        <a href="https://www.instagram.com/astech.eco/" target="_blank" rel="noreferrer">
                            <img className="icon" src={instagram} alt="instagram" />
                        </a>
                        <img className="icon" src={linkedin} alt="linkedin" />
                    </div>
  
                </div>
    */}
            </div>
        </FooterStyled>
    )
}

const FooterStyled = styled.div`
     h3 {
    color: #fff !important;
   }

   margin-top: 10vh;

   p {
    font-weight: 500;
   }

    @media screen and (min-width: 1500px) {
        width: 100%;
        height: 35vh;
        background-image: url(${background});
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 10%;
        position: absolute;

        .container-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5vh 6vw 1vh 6vw;

            .item-footer {
                width: 22vw;
                height: 10vh;
                margin: 0 2vw;
                color: #fff;
                text-align: center;

                .description {
                    margin-top: 2vh;
                    line-height: 24px;

                    p {
                        font-size: 0.8rem;
                    }

                    .icon {
                        width: 2.5vw;
                        height: 2.5vh;
                        margin: 0 0.5vw;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 1001px) and (max-width: 1500px) {
        width: 100%;
        height: 35vh;
        background-image: url(${background});
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 10%;
        position: absolute;

        .container-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5vh 6vw 1vh 6vw;

            .item-footer {
                width: 22vw;
                height: 10vh;
                margin: 0 2vw;
                color: #fff;
                text-align: center;

                .description {
                    margin-top: 2vh;
                    line-height: 24px;

                    p {
                        font-size: 0.8rem;
                    }

                    .icon {
                        width: 2.5vw;
                        height: 2.5vh;
                        margin: 0 0.5vw;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1000px) {
        width: 100%;
        height: 100vh;
        background-image: url(${background});
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 10%;
        position: absolute;

        
        .container-footer {
            padding: 24px;
            display: block;

            .item-footer {
                width: 90%;
                height: auto;
                margin: 4vh 2vw;
                color: #fff;
                text-align: center;

                .description {
                    margin-top: 2vh;
                    line-height: 24px;

                    p {
                        text-align: center;
                        font-size: 0.8rem;
                    }

                    .icon {
                        width: 4vw;
                        height: 4vh;
                        margin: 0 2vw;
                    }
                }
            }
        }
    }

`

export default Footer