import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import ImgMaserati from '../../../images/noticias/automotiva-MASERATI-1.jpg'
import ImgClassicShow00 from '../../../images/noticias/br-classic-show_001.jpg'
import ImgClassicShow01 from '../../../images/noticias/br-classic-show_004.jpg'


function Exposicao() {
    return (
        <ExposicaoStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Exposição de Carros antigos em Araxa</h1>
                    <div className='paginas-noticias-content exposicao'>
                    <img src={ ImgMaserati } alt="noticia thumb" />
                        <Page
                            subtitulo="Exposição de Carros antigos em Araxa"
                            data="22/05/2018"
                        />
                        <div className="exposicao">
                            <p>Dois anos custaram a passar, mas o <strong>Brazil Classics Show</strong> chegou!</p>
                            <p>Os mais charmosos veículos antigos retornam para o deleite dos colecionadores e admiradores do tradicional e principal evento do calendário do antigomobilismo que este ano comemora a sua 23ª edição, novamente na acolhedora cidade de Araxá-MG.</p>
                            <p>Ao longo dos anos este evento é reconhecido pela sua sofisticação, além do comprometimento em trazer os melhores automóveis para a exposição.</p>
                            <p>O <strong>Instituto Cultural Veteran Car – MG</strong> está preparando um espetáculo inesquecível, proporcionando 4 dias de entretenimento aos amantes do antigomobilismo e visitantes. Raridades serão apresentadas de 30 de maio a 3 de junho de 2018, no suntuoso Tauá Grande Hotel Termas & Convention Araxá.</p>
                            <p><strong>Brazil Classics Show 2018</strong> é aberto ao público e será uma festa grandiosa onde sua presença é fundamental e os automóveis, assumem o papel de personagem principal!</p>
                            <a href="http://www.classicshow.com.br/site/brazil-classics-show-2018-em-araxa-mg/" target="_blank" rel="noreferrer">http://www.classicshow.com.br/site/brazil-classics-show-2018-em-araxa-mg/</a>
                            <img src={ ImgClassicShow00 } alt="imagem exposição" />
                            <img src={ ImgClassicShow01 } alt="imagem exposição" />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </ExposicaoStyle>
    )
}

const ExposicaoStyle = styled.div`
    .exposicao {
        img {
            margin: 8vh auto;
            width: 30vw;
            height: auto;
            display: block;
        }
    }
`

export default Exposicao