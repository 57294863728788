import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import ImgTratamentoOzonio01 from '../../../images/noticias/tratamento-com-ozonio01.jpg'
import ImgTratamentoOzonio02 from '../../../images/noticias/tratamento-com-ozonio02.webp'
import ImgTratamentoOzonio03 from '../../../images/noticias/tratamento-com-ozonio03.webp'
import ImgTratamentoOzonio04 from '../../../images/noticias/tratamento-com-ozonio04.webp'
import ImgTratamentoOzonio05 from '../../../images/noticias/tratamento-com-ozonio05.webp'
import ImgTratamentoOzonio06 from '../../../images/noticias/tratamento-com-ozonio06.webp'
import ImgTratamentoOzonio07 from '../../../images/noticias/tratamento-com-ozonio07.webp'

function Ozonioterapia() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Ozonioterapia - O que é</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        titulo= "Ozonioterapia: o que é, para que serve e como é feita"
                        subtitulo="Ozonioterapia: o que é, para que serve e como é feita"
                        data="25/05/2020"
                        descricao="A ozonioterapia consiste num processo em que é administrado gás de ozônio no corpo para tratar alguns problemas de saúde. O ozônio é um gás composto por 3 átomos de oxigênio que tem importantes propriedades analgésicas, anti-inflamatórias e anti-sépticas, além de ter um efeito de melhora da oxigenação dos tecidos, assim como fortalecimento do sistema imune.
                        Devido às suas propriedades, esta é uma terapia que pode ser sugerida no tratamento de problemas crônicos, como artrite, dor crônica, feridas infectadas e atraso da cicatrização, por exemplo.
                        O tratamento deve ser realizado por um profissional de saúde, aplicando o ozônio localmente ou injetando via intravenosa, via intramuscular ou por insuflação retal."
                    />

                    <h2>Para que e como funciona</h2>
                    <p>A terapia com ozônio funciona interrompendo processos não saudáveis no corpo, como o crescimento de bactérias patogênicas se houver uma infecção, ou impedindo alguns processos oxidativos, podendo por isso ser usado para melhorar diversos problemas de saúde:</p>
                    <h3>1. Problemas respiratórios</h3>
                    <img alt="tratamento ozonio" src={ ImgTratamentoOzonio01 } />
                    <p>Como promove entrada de maior quantidade de oxigênio no sangue, a ozonioterapia é uma boa opção para aliviar os sintomas de pessoas com problemas respiratórios, como asma, bronquite e DPOC. Saiba <a href="https://www.tuasaude.com/sintomas-de-asma/">como identificar e tratar a asma.</a>
                    Isso acontece porque a entrada de maior quantidade de oxigênio no sangue, provoca um aumento na taxa de glicólise dos glóbulos vermelhos, aumento também a quantidade de oxigênio liberado para os tecidos.
                    Além disso, aumenta significativamente a resistência das vias aéreas e a frequência respiratória.</p>

                    <h3>2. Distúrbios no sistema imune</h3>
                    <img alt="tratamento ozonio" src={ ImgTratamentoOzonio02 } />
                    <p>A terapia com ozônio pode trazer benefícios para pessoas com o sistema imunológico debilitado e ajudar a tratar doenças como esclerose múltipla, artrite reumatoide ou <i>miastenia gravis</i>, por exemplo, já que estimula e reforça o sistema imune, aumentando o número de moléculas envolvidas na emissão de sinais entre as células durante o desencadeamento das respostas imunes.
                    Veja <a href="https://www.tuasaude.com/como-aumentar-o-sistema-imunologico/" target="_blank" rel="noreferrer">outras formas de aumentar a imunidade.</a></p>

                    <h3>3. Tratamento da AIDS</h3>
                    <img alt="tratamento ozonio" src={ ImgTratamentoOzonio03 } />
                    <p>Vários estudos comprovam que a ozonioterapia pode ser utilizado para complementar o tratamento do HIV, o vírus da AIDS, por facilitar a inativação de uma proteína nuclear do vírus, além de ter função como antioxidante e antimicrobiano. Saiba <a href="https://www.tuasaude.com/hiv-aids/">mais sobre os sintomas, contágio e de como é feito o tratamento da AIDS.</a></p>
                
                    <h3>4. Tratamento do câncer</h3>
                    <img alt="tratamento ozonio" src={ ImgTratamentoOzonio04 } />
                    <p>Alguns estudos comprovam também que o ozônio administrado em uma concentração entre 30 e 55 μg / cc causa aumento na produção de interferon, que é uma proteína produzida para, entre outros mecanismos, interferir na replicação de células tumorais e estimular a atividade de defesa de outras células.
                    Além disso, leva também ao aumento do fator de necrose tumoral e interleucina-2, que por sua vez estimula uma cascata de reações imunológicas subsequentes.
                    A terapia com ozônio pode ainda ser usada juntamente com a radioterapia e a quimioterapia para reduzir o risco de complicações e aumentar sua eficácia.</p>
                
                    <h3>5. Tratamento de infecções</h3>
                    <img alt="tratamento ozonio" src={ ImgTratamentoOzonio05 } />
                    <p>A ozonioterapia leva também à inativação de bactérias, vírus, fungos e parasitas. Nas bactérias atua através de um mecanismo que interrompe a integridade do envelope celular bacteriano, levando à oxidação dos fosfolipídios e lipoproteínas.
                    Nos fungos, o ozônio inibe o crescimento celular em certos estágios e em vírus danifica o capsídeo viral e perturba o ciclo reprodutivo ao interromper o contato entre o vírus e a célula com a peroxidação.
                    Alguns estudos já demonstraram sua eficácia em infecções como a doença de Lyme, infecções vaginais e até candidíase vaginal ou intestinal.</p>
                
                    <h3>6. Complicações na diabetes</h3>
                    <img alt="tratamento ozonio" src={ ImgTratamentoOzonio06 } />
                    <p>Algumas complicações na diabetes podem ser atribuídas ao estresse oxidativo no corpo e estudos demonstram que o ozônio ativa o sistema antioxidante que afeta o nível de glicemia. Conheça <a href="https://www.tuasaude.com/tratamento-para-diabetes/">outras formas de tratar os vários tipos de diabetes.</a>
                    Além disso, como esta terapia ajuda com a circulação sanguínea, pode permitir a melhora da vascularização de tecidos afetados pela falta de oxigênio produzida pela diabetes. Assim, e embora ainda não existam estudos com resultados bem comprovados, este tipo de terapia pode também ser experimentada para melhorar a cicatrização de úlceras em pessoas com diabetes.</p>

                    <h3>7. Tratamento de feridas</h3>
                    <img alt="tratamento ozonio" src={ ImgTratamentoOzonio07 } />
                    <p>O ozônio pode ainda ser usado para o tratamento de feridas, aplicando o gás diretamente na região afetada. Em um estudo in vitro, observou-se que o ozônio é muito eficaz na redução das concentrações de <i>Acinetobacter baumannii, Clostridium difficile e Staphylococcus aureus</i>.O ozônio pode também ser usado para tratar doenças inflamatórias, como artrite, reumatismo, degeneração macular, hernia de disco, problemas circulatórios, síndrome respiratória aguda grave, em sintomas hipóxicos e isquêmicos e para diminuir o colesterol no sangue.
                    Além disso tem também sido usado em odontologia, no tratamento de cáries dentárias. Saiba <a href="https://www.tuasaude.com/carie-dentaria/" target="_blank" rel="noreferrer">como identificar e tratar uma cárie dentária</a>.</p>

                    <h2>Como é feito o tratamento</h2>
                    <p>O tratamento com ozônio deve ser realizado por um profissional de saúde e nunca por via inalatória.
                    Existem várias formas de realizar a ozonioterapia, aplicando o gás diretamente na pele, caso se pretenda tratar uma ferida, via intravenosa ou intramuscular. Para administrar o ozônio pela veia, para tratar outros problemas de saúde, é retirada uma determinada quantidade de sangue que é misturada com o ozônio e depois é administrado novamente na pessoa via intravenosa. Também pode ser administrada via intramuscular, em que o ozônio pode estar misturado com o sangue da própria pessoa ou com água estéril.
                    Além disso, também são usadas outras técnicas, como a injeção intradiscal, paravertebral ou insuflação retal, em que é introduzida uma mistura de ozônio e oxigênio através de um cateter no cólon.</p>
                
                    <h2>Possíveis efeitos colaterais</h2>
                    <p>O fato de o ozônio ser ligeiramente instável torna-o um pouco imprevisível, podendo danificar os glóbulos vermelhos do sangue e por isso a quantidade usada no tratamento deve ser precisa.</p>

                    <h2>Quando não deve ser usado</h2>
                    <p>O ozônio médico está contraindicado em casos de gravidez, assim como em pacientes com infarto agudo do miocárdio, hipertireoidismo não controlado, intoxicação alcoólica ou problemas de coagulação, especialmente casos de favismo.</p>

                    <span>Criado por: </span><a href="https://www.tuasaude.com/equipe-editorial/" target="_blank" rel="noreferrer">Equipe Editorial do Tua Saúde<br/></a>
                    <span>Fonte: </span><a href="https://www.tuasaude.com/tratamento-com-ozonio/" target="_blank" rel="noreferrer">https://www.tuasaude.com/tratamento-com-ozonio/</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Ozonioterapia