import styled from 'styled-components'

import Header from '../components/header'
import Footer from '../components/footer'

import image1 from '../images/fachada-1.jpg'
import image2 from '../images/fachada-2.jpg'
import image3 from '../images/fachada-4.jpg'

function Contato() {
    return (
        <div>
            <Header />
            <ContatoStyled className="body">
                <h1 className="title">Contato</h1>

                <h2 className="title-d">Fale Conosco</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>
                <p>Manteremos um canal permanente de contato.</p>
                <p>Nos envie uma mensagem e a responderemos o mais breve possível.</p>
                {/* </Fade> */}
                {/* <Fade left> */}
                <form>
                    <input type="text" placeholder="Nome:" />
                    <input type="text" placeholder="Empresa:" />
                    <input type="email" placeholder="Email:" />
                    <input type="phone" placeholder="Telefone:" />

                    <select>
                        <option>Assunto</option>
                        <option>Atestados das Clínicas São Vicente e Unimed</option>
                        <option>Atestados das Clínicas Veterinárias</option>
                        <option>Atestados de Organizações</option>
                        <option>Atestados de Clientes</option>
                        <option>Declarações de Médicos, Certificadores e etc...</option>
                        <option>Reportagens, Notícias e Videos</option>
                        <option>Leis, Resoluções, Regulamentos, Anvisa e etc</option>
                        <option>Outro</option>
                    </select>
                    <textarea></textarea>
                    <button className="button-global">enviar</button>
                </form>
                {/* </Fade> */}

                <div className="contact-images">
                    {/* <Fade left> */}
                    <img alt="empresa" src={image1} />
                    {/* </Fade> */}
                    {/* <Fade bottom> */}
                    <img alt="empresa" src={image2} />
                    {/* </Fade> */}
                    {/* <Fade right> */}
                    <img alt="empresa" src={image3} />
                    {/* </Fade> */}

                </div>
            </ContatoStyled>
            <Footer />
        </div>
    )
}

const ContatoStyled = styled.div`
    text-align: center;

    h2 {
        text-align: left;
    }

    form {
        input, textarea, select {
            margin: 0 auto;
            display: block;
            border-radius: 4px;
            border-left: none;
            border-top: none;
            border-color: var(--primary-color);
            border-width: thin;
            color: var(--text-color);
            padding-left: 1vw;
            font-size: 1rem;
        }

        input::placeholder,
        select {
            color: gray;
        }

        textarea {
            padding: 12px;
        }
    }

	@media screen and (min-width: 1501px) and (max-width: 3500px) {
        p {
            text-align: center;
            font-size: 0.9rem;
            line-height: 1.5rem;
        }

        form {
            padding: 5vh 0;

            input, select {
                height: 6vh;
                width: 50vw;
                font-size: 0.8rem;
                margin: 3vh auto;
            }

            textarea {
                font-size: 0.8rem;
                width: 50vw;
                height: 18vh;
            }

            button {
                margin: 8vh auto;
                width: 20vw;
            }
        }

        .contact-images {
            display: grid;
            grid-template-columns: 25vw 25vw 25vw;
            align-items: center;
            justify-content: space-between;

            img {
                display: block;
                width: 240px;
                height: auto;
                margin: 5vh auto;
                border-radius: 12vh 0 12vh 0;
                cursor: pointer;
            }
        }
    }

	@media screen and (min-width: 1201px) and (max-width: 1500px) {
        p {
            text-align: center;
            font-size: 0.9rem;
            line-height: 1.5rem;
        }

        form {
            padding: 5vh 0;

            input, select {
                height: 6vh;
                width: 55vw;
                font-size: 0.8rem;
                margin: 3vh auto;
            }

            textarea {
                font-size: 0.8rem;
                width: 55vw;
                height: 18vh;
            }

            button {
                margin: 8vh auto;
                width: 20vw;
            }
        }

        .contact-images {
            display: grid;
            grid-template-columns: 25vw 25vw 25vw;
            align-items: center;
            justify-content: space-between;

            img {
                display: block;
                width: 240px;
                height: auto;
                margin: 5vh auto;
                border-radius: 12vh 0 12vh 0;
                cursor: pointer;
            }
        }
    }

	@media screen and (min-width: 1001px) and (max-width: 1200px) {
        p {
            text-align: center;
            font-size: 0.9rem;
            line-height: 1.5rem;
        }

        form {
            padding: 5vh 0;

            input, select {
                height: 6vh;
                width: 60vw;
                font-size: 0.8rem;
                margin: 3vh auto;
            }

            textarea {
                font-size: 0.8rem;
                width: 60vw;
                height: 18vh;
            }

            button {
                margin: 8vh auto;
                width: 20vw;
            }
        }

        .contact-images {
            display: grid;
            grid-template-columns: 25vw 25vw 25vw;
            align-items: center;
            justify-content: space-between;

            img {
                display: block;
                width: 200px;
                height: auto;
                margin: 5vh auto;
                border-radius: 12vh 0 12vh 0;
                cursor: pointer;
            }
        }
    }

	@media screen and (min-width: 801px) and (max-width: 1000px) {
        p {
            text-align: center;
            font-size: 0.9rem;
            line-height: 1.5rem;
        }

        form {
            padding: 5vh 0;

            input, select {
                height: 6vh;
                width: 80%;
                font-size: 0.8rem;
                margin: 3vh auto;
            }

            textarea {
                font-size: 0.8rem;
                width: 80%;
                height: 12vh;
            }

            button {
                margin: 8vh auto;
                width: 20vw;
            }
        }

        .contact-images {
            display: grid;
            grid-template-columns: 25vw 25vw 25vw;
            align-items: center;
            justify-content: space-between;

            img {
                display: block;
                width: 180px;
                height: auto;
                margin: 5vh auto;
                border-radius: 12vh 0 12vh 0;
                cursor: pointer;
            }
        }
    }

	@media screen and (max-width: 800px) {
        p {
            text-align: center;
            font-size: 0.9rem;
            line-height: 1.5rem;
        }

        form {
            padding: 5vh 0;

            input, select {
                height: 6vh;
                width: 80%;
                font-size: 0.8rem;
                margin: 3vh auto;
            }

            textarea {
                font-size: 0.8rem;
                width: 80%;
                height: 12vh;
            }

            button {
                margin: 8vh auto;
                width: 20vw;
            }
        }

        .contact-images {
            padding: 5vh 0 12vh 0;

            img {
                display: block;
                width: 220px;
                height: auto;
                margin: 5vh auto;
                border-radius: 12vh 0 12vh 0;
                cursor: pointer;
            }
        }
    }
`

export default Contato