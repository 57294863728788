import styled from 'styled-components'

import Header from '../components/header'
import Footer from '../components/footer'
import ProductVideo from '../components/productVideo'
import ProductComponent from '../components/produtoComponent'

import garantia from '../images/identidade/garantia-astech-1.png'

function Produtos() {
    return (
        <div>
	        <Header />	
            <ProdutosStyled className="body">
		        <h1 className="title">Produtos</h1>

                <h2 className="title-d">Equipamentos SANITECH (O₃) e INFLATECH (O₃)</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>

                <p className="desc"><strong>SANITECH (O₃) - Patente depositado n° BR 20 2021009339 6.</strong> Pedido Nacional de Invenção, Modelo de Utilidade, Certificado de Adição de Invenção dos nossos equipamentos <strong>SANITECH (O₃).</strong></p>

                {/* <p className="desc desc-center">Pedido Nacional de Invenção, Modelo de Utilidade, Certificado de Adição de Invenção dos nossos equipamentos <strong>SANITECH (O₃) e INFLATECH (O₃).</strong></p> */}

                <p className="desc"><strong>Os equipamentos SANITECH (O₃)</strong> seguem normas e processo de montagens, respeitando os mais altos padrões de qualidade.</p>

                <div className="garantia">
                    <p className="desc"><strong>Todos os equipamentos são exaustivamente testados e quando este processo é concluído, recebem o selo de garantia.</strong></p>
                    <img alt="selo garantia" src={garantia} />
                </div>

                <ProductVideo />
            </ProdutosStyled>
            <ProductComponent />
	        <Footer />
        </div>
    )
}

const ProdutosStyled = styled.div`
    .desc-center {
        text-align: center;
    }

	@media screen and (min-width: 1501px) and (max-width: 3500px) {
        .garantia {
            width: 60vw;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            margin: 5vh auto;

            border-width: 1px;
            border-style: solid;
            border-radius: 60px 0 60px 0;
            overflow: hidden;
            border-top-width: 1px;
            border-bottom-width: 1px;
            border-color: #d6d6d6;
            border-left: none;
            border-right: none;

            p {
                font-size: 1rem;
            }

            img {
                width: 150px;
                height: auto;
            }
        }
    }

	@media screen and (min-width: 1201px) and (max-width: 1500px) {
        .garantia {
            width: 70vw;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            margin: 5vh auto;

            border-width: 1px;
            border-style: solid;
            border-radius: 60px 0 60px 0;
            overflow: hidden;
            border-top-width: 1px;
            border-bottom-width: 1px;
            border-color: #d6d6d6;
            border-left: none;
            border-right: none;

            p {
                font-size: 0.9rem;
            }

            img {
                width: 140px;
                height: auto;
            }
        }
    }

	@media screen and (min-width: 1001px) and (max-width: 1200px) {
        .garantia {
            width: 70vw;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            margin: 5vh auto;

            border-width: 1px;
            border-style: solid;
            border-radius: 60px 0 60px 0;
            overflow: hidden;
            border-top-width: 1px;
            border-bottom-width: 1px;
            border-color: #d6d6d6;
            border-left: none;
            border-right: none;

            p {
                font-size: 0.9rem;
            }

            img {
                width: 140px;
                height: auto;
            }
        }
    }

	@media screen and (min-width: 801px) and (max-width: 1000px) {
        .garantia {
            text-align: center;

            p {
                text-align: left;
            }

            img {
                margin-top: 4vh;
                width: 30%;
            }
        }
    }

	@media screen and (max-width: 800px) {
        .garantia {
            text-align: center;

            img {
                margin-top: 4vh;
                width: 30%;
            }
        }
    }
`

export default Produtos