

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

function GrupoSulfidrila() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Grupos sulfidrila são vulneráveis ​​à oxidação</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        subtitulo="Grupos sulfidrila são vulneráveis ​​à oxidação"
                        data="25/05/2020"
                        descricao="Materia traduzida do CLINMEDJOURNALS.org
                        Muitos vírus requerem grupos sulfidrila reduzidos para fusão e entrada de células. Os vírus corona, incluindo SARS-CoV-2 (a causa da condição agora denominada doença de coronavírus 2019 ou COVID-19), são ricos em cisteína, cujos resíduos devem estar intactos para a atividade viral. Grupos sulfidrila são vulneráveis ​​à oxidação. A ozonioterapia, uma modalidade muito barata e segura, pode explorar com segurança essa vulnerabilidade crítica em muitos vírus, inclusive o SARS-CoV-2."
                    />
                    <span>Fonte: </span><a href="https://clinmedjournals.org/articles/jide/journal-of-infectious-diseases-and-epidemiology-jide-6-113.php?jid=jide" target="_blank" rel="noreferrer">https://clinmedjournals.org/articles/jide/journal-of-infectious-diseases-and-epidemiology-jide-6-113.php?jid=jide</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default GrupoSulfidrila