import Header from '../../components/header'
import Footer from '../../components/footer'
import Ficha from '../../components/ficha'

function Restaurantes() {
    return (
        <div>
            <Header title={"RESTAURANTES E BARES"} />
            <div className="body">
                <h2 className="title-d">Aplicabilidade</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>

                <p className="desc"> 
                    Na purificação e esterilização do ar ambiente da recepção,
                    salão de refeição, sala da gerência, sala de repouso, sala de
                    reunião e treinamento, estoque, banheiros, vestiários,
                    refeitório, cozinha e outros, utilizando o nosso equipamento
                    <strong>SANITECH (O₃) LINHA PURI</strong> de baixa concentração para
                    <strong>ambientes habitáveis</strong>, evitando assim a contaminação cruzada
                    por bactérias, vírus, germes, fungos, odores indesejáveis, a
                    proliferação de insetos em ambientes fechados, como a picada
                    dos mosquitos da dengue, chikungunya e zika e minimizando as
                    doenças respiratórias e alérgicas como renite, sinusite,
                    resfriado por aerossóis.
                </p>

                <p className="desc"> 
                    Na descontaminação dos alimentos armazenados em câmara
                    fria, utilizando o nosso equipamento <strong>SANITECH (O₃) CÂMARA
                    FRIA</strong>, eliminando as bactérias, odores indesejáveis.
                </p>

                <p className="desc"> 
                    No transporte de clientes, permitindo o uso do ar-
                    condicionado e proporcionando segurança com os vidros
                    fechados, utilizando o nosso equipamento <strong>SANITECH (O₃)
                    MULTIUSO</strong> de baixa concentração para <strong>ambientes habitáveis</strong>
                    eliminando micro-organismos, fungos, odores indesejáveis.
                </p>

                <p className="desc"> 
                    Na descontaminação dos sapatos utilizando o nosso
                    equipamento <strong>SANITECH UVC</strong> eliminando os micro-organismos
                    das solas dos sapatos em 99%.
                </p>
                <p className="desc"> 
                    No tratamento da água potável e reuso utilizando o nosso
                    equipamento <strong>SANITECH MVO₃</strong>, eliminando as bactérias,
                    odores, melhorando a turbidez, reestruturando
                    molecularmente, melhorando o seu PH oxigenando-o.
                </p>

                <p className="desc"> 
                    Na sanitização das mercadorias nas docas, embalagens,
                    ferramental e outros, utilizando o nosso equipamento
                    <strong>SANITECH (O₃) OXI-SANITIZAÇÃO 40</strong>, com uma cápsula inflável
                    ou minicontainer e gás de ozônio em alta concentração,
                    eliminando os micro-organismos.
                </p>

                <p className="desc"> 
                    Na descontaminação dos alimentos com água ozonizada para
                    posterior embalagem, no enxague no salão de refeições, na
                    cozinha, banheiros e outros, após a limpeza terminal, utilizando
                    o nosso equipamento <strong>SANITECH (O₃) ACQUA</strong>, eliminando as
                    bactérias, odores indesejáveis.
                </p>

                <p className="desc"> 
                    Na pulverização com água ozonizada potencializando na
                    mistura de saneantes químicos, em ambientes de grande
                    circulação e áreas contaminadas após a limpeza terminal,
                    utilizando o nosso equipamento <strong>SANITECH (O₃) NEBULIZADOR
                    COSTAL</strong>, eliminando micro-organismos, fungos e odores
                    indesejáveis.
                </p>
            </div>
            <Ficha />
            <Footer />
        </div>
    )
}

export default Restaurantes