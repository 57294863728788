import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

function BacteriaSepse() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Staphylococcus aureus: entenda o que é a bactéria que pode causar sepse, a infecção generalizada</h1>
                <div className='paginas-noticias-content bacteria'>
                    <Page
                        subtitulo="Staphylococcus aureus: entenda o que é a bactéria que pode causar sepse, a infecção generalizada"
                        data="16/04/2019"
                    />
                    <p><strong>Os estafilococos são comuns, mas quando afetam pessoas com baixa imunidade podem ser letais. Febre, mal-estar, dores no corpo, cansaço excessivo e vômitos são sinais de alerta.</strong></p>
                    <p>O contato com a bactéria Staphylococcus aureus é mais comum do que muitas pessoas imaginam. Embora em casos extremos possa levar a uma infecção generalizada, ou “sepse” – como ocorreu com o menino Arthur, neto do ex-presidente Luiz Inácio Lula da Silva – o micro-organismo é um dos mais presentes na pele humana e na maioria das vezes causa problemas simples, tratáveis com antibióticos.
                    Abaixo, entenda o que são os estafilococos, que tipo de doença podem causar, os sintomas e tratamentos:</p>
                    <p><strong>O que são os estafilococos?</strong></p>
                    <p>Os estafilococos são um tipo de bactéria. A espécie mais frequente é justamente o Staphylococcus aureus, mas existem dezenas de outras. Os estafilococos estão presentes na superfície de pele de cerca de 20% das pessoas, e no nariz de 30% dos adultos, o que é considerado normal.
                    Infecção de Staphylococcus aureus após piercing deixa jovem paraplégica
                    De acordo com o Dr. Juvêncio Furtado, médico infectologista e professor na Faculdade de Medicina do ABC, existem os estafilococos chamados “domiciliares” ou “comunitários”, normalmente sensíveis a antibióticos e que causam infecções menos graves. Mas há também os “hospitalares”, que são bem mais resistentes.
                    “O que está na nossa pele geralmente não causa doenças, exceto em pessoas que tenham a imunidade muito comprometida”, explica. Já pessoas que passam mais de 72 horas em ambientes hospitalares podem ser colonizadas por estafilococos mais resistentes.
                    Alguns resistem, inclusive, ao antibiótico meticilina e são conhecidos pela sigla SARM. “A mesma bactéria, em ambientes diferentes, pode adquirir características de resistência maior”, diz o Dr. Furtado.
                    Essas bactérias podem ser perigosas quando caem na corrente sanguínea, porém são raros os casos em que os estafilococos comuns causem infecções graves. De acordo com a Dra. Ana Escobar, médica pediatra e colunista do G1, isso pode ocorrer principalmente por meio de lesões na pele.
                    “Quando a pele está íntegra, a bactéria não faz nada. Mas se houver um corte, ela pode penetrar”, explica a Dra. Ana. Além de infecções na própria pele, os estafilococos podem atacar também outros órgãos distantes.
                    “Depende um pouco do sistema imunológico de cada pessoa. O nosso sistema geralmente é muito eficaz”, diz a médica. Em alguns casos, menos comuns, o micro-organismo também pode ser ingerido pela boca, em alimentos contaminados, e se espalhar pelo corpo no trato gastrointestinal.
                    Também nos chamados “traumas fechados”, possivelmente provocados por quedas, pancadas ou outros acidentes comuns entre crianças, a bactéria pode se alastrar internamente, sem dar sinais visíveis.</p>
                    <p><strong>Quais são os sintomas de infecção?</strong></p>
                    <p>Os estafilococos têm um grande “poder de invasão”, diz a Dra. Ana, e por isso a infecção pode se desenvolver rapidamente. Para impedir uma contaminação mais agressiva, é preciso estar atento aos sintomas de infecção.
                    São sinais de que um processo infeccioso pode ter começado:</p>
                        <li>febre</li>
                        <li>mal-estar</li>
                        <li>dores no corpo</li>
                        <li>cansaço excessivo</li>
                        <li>vômitos</li>
                    <p>Quando houver esses sintomas, um médico deve ser procurado. O diagnóstico ainda nas primeiras 24 horas é decisivo, inclusive para uma eventual confirmação da presença do Staphylococcus aureus. Isso porque ela age rápido no corpo. “Normalmente, demora um dia ou dois para a coisa evoluir”, explica a médica.
                    As infecções estafilocócicas estão entre as que evoluem mais rapidamente – assim como aquelas provocadas por outro tipo de bactéria, os meningococos, o que levou os médicos do menino Arthur a diagnosticarem uma meningite.
                    Segundo o Dr. Furtado, o uso exagerado e, às vezes, desnecessário de antibióticos leva à seleção de bactérias mais resistentes. Ainda são raros os casos de contaminação grave com os estafilococos “comunitários”, presentes normalmente na pele, mas isso pode acontecer.
                    “Já tive um paciente que se cortou fazendo a barba. São poucos casos, mas o uso inadequado de antibióticos pelas pessoas acaba promovendo essas bactérias mais resistentes do que as habituais.”
                    Para que isso aconteça, continua, é necessária a soma de dois elementos raros: uma baixa imunidade do paciente e a entrada na corrente sanguínea de um grande número de bactérias mais resistentes.</p>
                    <p><strong>Como evitar?</strong></p>
                    <p>Segundo a Dra. Ana Escobar, a melhor forma de evitar infecções bacterianas por estafilococos é lavar com água e sabão as mãos e o local lesionado por um corte, pequeno acidente, espinhas, etc. “Se saiu sangue, é preciso lavar com água e sabão, sempre o melhor desinfetante, e ficar de olho se começar a aparecer algum sinal de infecção”, ensina.
                    Se o machucado demorar para cicatrizar ou se houver sinais de inflamação, como vermelhidão, pus ou dor excessiva, é melhor procurar um médico rapidamente. O mesmo vale para casos de “trauma fechado”. É preciso ir ao médico quando houver febre e mal-estar após o incidente.
                    Para prevenir a contaminação de alimentos por bactérias também é preciso lavá-los bem – assim como as mãos e os utensílios –, evitar que fiquem muito tempo em temperatura ambiente e cozinhá-los cuidadosamente.</p>
                    <p><strong>O que é a sepse?</strong></p>
                    <p>Se não for logo identificada e tratada, a infecção pode atingir vários órgãos ao mesmo tempo. “Quando começa a ter uma progressão, a coisa não melhora, pode ser uma bactéria mais resistente”, diz o Dr. Furtado, acrescentando que esse quadro clínico é chamado de “sepse” ou “septicemia”.
                    As bactérias podem provocar os mais diversos tipos de infecção: nos pulmões (pneumonia), nos ossos (osteomielite), no coração (endocardite), nas membranas que envolvem o cérebro (meningite), entre outras.
                    Quando a infecção atinge vários órgãos de forma descontrolada, ocorre um “choque séptico”, um quadro ainda mais grave que o da sepse. Quando isso ocorre, a chance de mortalidade é de aproximadamente 80%.
                    O choque, entretanto, é algo mais comum em pacientes já hospitalizados. Isso pode ocorrer após um agravamento da infecção generalizada, atingindo todo o organismo de forma sistêmica.
                    A queda da pressão arterial é o que leva à falência múltipla dos órgãos e, consequentemente, à morte. “O nosso corpo fica como se fosse um campo minado. No fim, ele não consegue proteger mais nada e essas minas começam a estourar”, acrescenta Dra. Ana. “Isso afeta a capacidade de manter a pressão arterial, um fluxo sanguíneo adequado, oxigenação do sangue, etc.”</p>
                    <p><strong>Como tratar?</strong></p>
                    <p>O tratamento contra os estafilococos, assim como para todas as outras bactérias, se faz com antibióticos. No entanto, para cada bactéria utiliza-se um antibiótico específico. Somente o médico pode avaliar qual receitar. “Dependendo do quadro clínico, dos exames, o médico pode supor qual bactéria está afetando o paciente”, diz a Dra. Ana.
                    “Se a infecção for muito grave, não dá tempo de esperar os resultados dos exames. O médico pode administrar dois ou três antibióticos ao mesmo tempo e, depois, deixar somente o mais adequado”, completa.
                    No caso da infecção generalizada, os médicos avaliam o quadro da pessoa constantemente e realizam exames clínicos para identificar o tipo de bactéria. Os hospitais já têm protocolos estabelecidos para os casos de sepse.
                    O Dr. Furtado afirma que, às vezes, é preciso hierarquizar os antibióticos, conforme a gravidade do caso. “A maioria das infecções não evolui para a sepse, que é quando a infecção já chegou na corrente sanguínea. O médico presume qual antibiótico indicar conforme a evolução do quadro”, diz.
                    Além dos antibióticos, ele lembra que, nos casos de internação e infecção hospitalar, também é essencial controlar o volume de sangue</p>
                    <span>Fonte: </span><a href="Fonte: https://g1.globo.com/ciencia-e-saude/noticia/2019/04/04/staphylococcus-aureus-entenda-o-que-e-a-bacteria-que-pode-causar-sepse-a-infeccao-generalizada.ghtml" target="_blank" rel="noreferrer">Fonte: https://g1.globo.com/ciencia-e-saude/noticia/2019/04/04/staphylococcus-aureus-entenda-o-que-e-a-bacteria-que-pode-causar-sepse-a-infeccao-generalizada.ghtml</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BacteriaSepse