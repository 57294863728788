import styled from "styled-components"
import whats from '../images/svg/whatsapp.svg'

function Whatsapp() {
    return (
        <WhatsappStyle>
            <a
                href="https://wa.me/5521994659228?text=Comercial Astech"
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img className="whatsapp-icon" src={whats} alt="whatsapp" />
            </a>
        </WhatsappStyle>
    )
}

const WhatsappStyle = styled.a`
    .whatsapp_float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 40px;
        right: 40px;
        background-color: #25d366;
        color: var(--white);
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
    }
    .whatsapp-icon {
        margin-top: 16px;
    }
    @media screen and (max-width: 1347px) {
    
    }
    @media screen and (max-width: 1186px) {
        
    }
    @media screen and (max-width: 990px) {
        
    }
    @media screen and (max-width: 790px) {
        
    }    
`

export default Whatsapp