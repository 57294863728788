import Header from '../../components/header'
import Footer from '../../components/footer'
import Ficha from '../../components/ficha'

function Ensino() {
    return (
        <div>
            <Header title={"INSTITUIÇÕES DE ENSINO"} />
            <div className="body">
                <h2 className="title-d">Aplicabilidade</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>

                <p className="desc">Na purificação e esterilização do ar ambiente da recepção, sala da diretoria, sala dos professores, sala de reuniões, auditório, creche, sala de repouso, sala de aula, biblioteca, banheiros, vestiários, refeitórios, cozinha e outros, utilizando o nosso equipamento <strong>SANITECH (O₃) LINHA PURI</strong> de baixa concentração para <strong>ambientes habitáveis</strong>, evitando assim a contaminação cruzada por bactérias, vírus, germes, fungos, odores indesejáveis, a proliferação de insetos em ambientes fechados, como a picada dos mosquitos da dengue, chikungunya e zika e minimizando as doenças respiratórias e alérgicas como rinite, sinusite, resfriados por aerossóis.</p>

                <p className="desc">No transporte de alunos utilizando o nosso equipamento <strong>SANITECH (O₃) MULTIUSO</strong> de baixa concentração para <strong>ambientes habitáveis</strong> eliminando micro-organismos, fungos, odores indesejáveis.</p>

                <p className="desc">Na descontaminação dos sapatos utilizando o nosso equipamento <strong>SANITECH UVC</strong> eliminando os micro-organismos das solas dos sapatos em 99%</p>

                <p className="desc">No tratamento da água potável e reuso utilizando o nosso equipamento <strong>SANITECH MVO₃</strong>, eliminando as bactérias, odores, melhorando, a turbidez, restruturando molecularmente, melhorando o seu PH oxigenando-o.</p>

                <p className="desc">Na sanitização da merenda escolar outros, utilizando o nosso equipamento <strong>SANITECH (O₃) OXI-SANITIZAÇÃO 40</strong>, com uma cápsula inflável ou minicontainer e gás de ozônio em alta concentração, eliminando os micro-organismos.</p>

                <p className="desc">Na descontaminação dos alimentos com água ozonizada, utilizando o nosso equipamento<strong>SANITECH (O₃) ACQUA</strong>, eliminando os micro-organismos e odores indesejáveis.</p>

                <p className="desc">Na pulverização com água ozonizada potencializando na mistura de saneantes químicos em ambientes de grande circulação e áreas contaminadas nas escolas após a limpeza terminal, utilizando o nosso equipamento <strong>SANITECH (O₃) NEBULIZADOR COSTAL</strong>, eliminando micro-organismos, fungos e odores indesejáveis.</p>

            </div>
            <Ficha />
            <Footer />
        </div>
    )
}

export default Ensino