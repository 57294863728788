import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import Rio from '../../../images/noticias/rio-de-janeiro.jpg'

function RioConfirmaSubvariante() {
    return (
        <RioConfirmaSubvarianteStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Prefeitura do Rio confirma transmissão local de subvariante da ômicron da Covid-19</h1>
                    <div className='paginas-noticias-content RioConfirmaSubvariante'>
                        <Page
                            subtitulo="Prefeitura do Rio confirma transmissão local de subvariante da ômicron da Covid-19"
                            data="25/10/2022"
                            img={Rio}
                        />
                        {/* <img src={ RioConfirmaSubvarianteImg } alt="noticia" /> */}
                        <p>A Secretaria Municipal de Saúde do Rio (SMS) confirmou a transmissão local da BQ.1, subvariante da ômicron. De acordo com a SMS, a BQ.1 está causando o aumento do número de casos de Covid-19 na cidade.</p>
                        <p>No sábado (5), a Fiocruz anunciou a primeira confirmação pela subvariante no Rio. É o de uma moradora da Zona Norte do Rio de 35 anos, que tomou as vacinas e passa bem.</p>
                        <p>A paciente não tinha histórico de viagem, o que comprova a transmissão local. Nas últimas três semanas, a positividade dos exames de Covid passou de 6% para 21%.</p>
                        <span>Veja matéria completa em: </span><a href="https://g1.globo.com/rj/rio-de-janeiro/noticia/2022/11/07/pefeitura-do-rio-confirma-transmissao-local-de-subvariante-da-omicron-da-covid-19.ghtml" target="_blank" rel="noreferrer">https://g1.globo.com/rj/rio-de-janeiro/noticia/2022/11/07/pefeitura-do-rio-confirma-transmissao-local-de-subvariante-da-omicron-da-covid-19.ghtml</a>
                    </div>
                </div>
                <Footer />
            </div>
        </RioConfirmaSubvarianteStyle>
    )
}

const RioConfirmaSubvarianteStyle = styled.div`
    .RioConfirmaSubvariante-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default RioConfirmaSubvariante