import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Fade } from 'react-reveal'

import menu from '../images/identidade/menu3branco.png'
import logo from '../images/logo/logo.svg'

function Nav() {
    const dropDownRef = useRef(null)
    const [isActive, setIsActive] = useState(false)
    const onClick = () => setIsActive(!isActive)

    return (
        <NavStyled>
            <Fade top>
            <div className="menu-container">
                <div className="logo">
                    <Link to="/">
                        <img alt="logo" src={ logo } />
                    </Link>
                </div>
                <button onClick={onClick} className="menu-button">
                    <img width="30px" height="30px" src={ menu } alt="menu" />
                </button>
                <nav ref={dropDownRef} className={`menu ${isActive ? "ativo" : "inativo"}`}>
                    <ul>
                        <li>
                            <Link to="/">
                                home
                            </Link>
                        </li>

                        <li>
                            <Link to="/nossa-historia">
                                nossa história
                            </Link>
                        </li>

                        <li>
                            <Link to="/solucoes">
                                soluções
                            </Link>
                        </li>

                        <li>
                            <Link to="/beneficios">
                                benefícios
                            </Link>
                        </li>

                        <li>
                            <Link to="/produtos">
                                produtos
                            </Link>
                        </li>

                        <li>
                            <Link to="/clientes">
                                clientes
                            </Link>
                        </li>

                        <li>
                            <Link to="/notas-tecnicas">
                                notas técnicas
                            </Link>
                        </li>
                        
                        <li>
                            <Link to="/noticias">
                                notícias
                            </Link>
                        </li>

                        <li>
                            <Link to="/contato">
                                contato
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            </Fade>
        </NavStyled>
    )
}

const NavStyled = styled.div`
    li { color: var(--white-color)}

    @media screen and (min-width: 1501px) and (max-width: 3500px) {
        .menu-container {
            width: 100vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            
            .menu-button {
                opacity: 0;
            }

            .logo {
                margin-top: -10px;
                img {
                    width: 125px;
                    height: auto;
                }
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: center;

                li {
                    margin: 0 0.8vw;
                    font-size: 1rem;
                    font-weight: 300;
                }
            }
        }
    }

    @media screen and (min-width: 1001px) and (max-width: 1500px) {
        .menu-container {
            width: 100vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            

            .menu-button {
                opacity: 0;
            }

            .logo {
                margin-top: -10px;
                img {
                    width: 125px;
                    height: auto;
                }
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: center;

                li {
                    margin: 0 0.8vw;
                    font-size: 0.9rem;
                    font-weight: 300;
                }
            }
        }
    }

    @media screen and (max-width: 1000px) {
        .menu-container {
            width: 100vw;
            position: absolute;
            text-align: center;

            .logo {
                img {
                    width: 150px;
                    height: auto;
                }
            }

            .menu-button {
                margin: 4vh auto;
                background-color: #08546500;
                border: none;
                margin-left: auto;
                cursor: pointer;
                border-radius: 190px;

                button:hover {
                    background-color: #085465;
                }
            }

            .menu.ativo {
                padding-top: 4vh;
                opacity: 1;
                transition: .5s;
                background-color: rgba(248, 248, 248, 0.936);
                height: 72vh;
                width: 100vw;
            }

            ul {
               li {
                    width: 60vw;
                    color: var(--primary-color);
                    margin: 2.5vh auto;
                    padding-bottom: 10px;
               }
            }

            .menu {
                opacity: 0;
                transition: .5s;
            }
        }
    }   
`

export default Nav