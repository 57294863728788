

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import PageVideo from '../../../components/pageVideo'

function PrefeituraBacabeira() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Prefeitura de Bacabeira disponibiliza Túnel de Descontaminação como medida de prevenção ao Coronavírus</h1>
                <div className='paginas-noticias-content'>
                    <PageVideo
                        subtitulo="Prefeitura de Bacabeira disponibiliza Túnel de Descontaminação como medida de prevenção ao Coronavírus"
                        data="05/06/2020"
                        video="https://www.youtube.com/embed/qb8xokpgyg0"
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrefeituraBacabeira