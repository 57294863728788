

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

function RecemNascidos() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Mais de 900 mil recém-nascidos morrem todo ano por causa de sujeira nos hospitais, diz OMS</h1>
                <div className='paginas-noticias-content ozonio-prevencao'>
                    <Page
                        subtitulo="Mais de 900 mil recém-nascidos morrem todo ano por causa de sujeira nos hospitais, diz OMS"
                        data="16/04/2019"
                        descricao="Uma em cada quatro instalações médicas tem acesso limitado ou então nem tem água corrente. Além disso, uma em cada cinco não tem banheiros."
                    />
                    <a href="https://globoplay.globo.com/v/7508621/?utm_source=whatsapp&utm_medium=share-bar" target="_blank" rel="noreferrer"><strong>ASSISTA O VIDEO</strong></a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default RecemNascidos