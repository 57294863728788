
import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import ImgPalestra from '../../../images/noticias/palestra-ozonio-gui.jpeg'

function Palestra() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Palestra ozônio na conservação e sanitização de alimentos</h1>
                <div className='paginas-noticias-content ozonio-prevencao'>
                    <Page
                        subtitulo="Palestra ozônio na conservação e sanitização de alimentos"
                        data="05/04/2019"
                        descricao="Convite aos amigos e clientes e a inscrição são 2 kg de alimentos não perecível.
                        Muito obrigado!"
                    />
                    <img src={ ImgPalestra } alt="imagem palestra" />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Palestra