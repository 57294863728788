import styled from "styled-components";
import ModalProduct from "./modais/produto/produto";

import puri30 from "../images/produtos/puri30.png";
import puri60 from "../images/produtos/puri60.png";
import puri90 from "../images/produtos/puri90.png";
import puri120 from "../images/produtos/puri120.png";
import Puri15 from "../images/produtos/puri15.png";
import SanitechO3AcquaPet from "../images/produtos/pet.png";
import SanitechO3Camara from "../images/produtos/sn.jpg";
import SanitechO340 from "../images/produtos/sn0003a.jpg";
import SanitechO380Sanitizacao from "../images/produtos/sn0004a.jpg";
import SistemaMVO3 from "../images/produtos/mvo3.png";
import SistemaMVO350 from "../images/produtos/mvo350.png";
import DescontaminadorSapatos from "../images/produtos/descontaminador.png";
import Sensor from "../images/produtos/sensor.png";
import Sensor10 from "../images/produtos/sensor10.png";
import MedPro from "../images/produtos/medpro.png";
import VetMobile from "../images/produtos/vetmobile.png";
import KitBag from "../images/produtos/bag.png";
import AcquaSanitizacao from "../images/produtos/hospital.png";
import DescUniforme from "../images/produtos/descUniforme.jpeg";
import AtomizadorCostalImage from '../images/produtos/atomizador costal.png'

function ProductComponent() {
  return (
    <ProductComponentStyle className="body">
      <h2 className="title-d">Nossos Produtos</h2>
      <div className="style">
        <div className="box b1"></div>
        <div className="box b2"></div>
      </div>

      <div className="card-container">
        <div className="card" id="1">
          <div className="card-image">
            <img style={{ width: '140px' }} alt="imagem produto" src={Puri15} />
          </div>
          <h3>SANITECH O₃ PURI 15</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ PURI 15 E PURI CAR"
            descricao={
              <>
                <p className="modal-desc">O<Strong> EQUIPAMENTO SANITECH O₃ PURI 15 E PURI CAR</Strong> produz ozônio O₃, um gás bactericida e germicida natural, capaz de purificar e esterilizar do ar ambiente, não é prejudicial à saúde quando produzido em baixa concentração e utilizando da forma correta de instalação e manutenção do equipamento. O objetivo de evitar e mitigar a contaminação cruzada por bactérias, vírus, germes, fungos e quaisquer microrganismos por aerossóis de espirros, tosse, gripes, alergias, sinusite, nariz entupido, odores de animais de estimação e espanta insetos em ambiente fechado, evitando a picada dos mosquitos transmissores da dengue, chikungunya e zika, tornando o ar mais puro e leve para as atividades diárias e ao dormir.</p>
              </>
            }
            func="APLICABILIDADE DO SANITECH O₃ PURI 15 E PURI CAR"
            funcionamento={
              <>
                <p className="modal-desc">Sua demanda foi planejada para atender uma área de <Strong>15 m³ (larg x comp x alt), produz 0,01 ppm</Strong> e exigiu um desenvolvimento tecnológico de inovação e sustentabilidade ecologicamente correto.</p>
                <p className="modal-desc">Sua aplicabilidade está voltada para os seguintes segmentos: Residências, hospitais, clínicas, Instituições de Longa Permanência para Idosos, consultórios dentários, escolas, escritórios, repartições públicas, call center, hotéis, veterinárias, academias, salão de beleza, indústrias de processamentos de alimentos, supermercados, banheiros públicos, restaurantes, bares, teatros, cinemas, shoppings, automóveis, caminhões, rodoviárias, aeroportos, portos e outros para dar suporte às atividades diárias de intenso fluxo de pessoas expostas a contaminação cruzada, trazendo mais proteção e segurança.</p>
                <p className="modal-desc">Nosso equipamento é <Strong>patenteado e depositado sob o n° BR 20 2021 009339 6,</Strong> testado pela pesquisadora <Strong>Drª Karyne Rangel da FIOCRUZ</Strong> e reconhecido pela <Strong>Revista Microorganisms Americana (MDPI),</Strong> estão de acordo com o Ministério do Trabalho e Emprego através da norma regulamentadora <Strong>(NR 15, anexo 11 do MTE), (BRASIL, 2016), que indica exposição máxima de 0,08 ppm (0,16 mg/m³)</Strong> para jornadas de trabalho de até 48 horas por semana e não requer autorização da ANVISA para registro:</p>
                <p className="modal-desc">Link da publicação da revista: <a className="ref" href="https://www.mdpi.com/1423032" target="blank">https://www.mdpi.com/1423032</a></p>
                <p className="modal-desc">Um artigo publicado em 2020 pelo professor Takayuki Murata da Universidade de Medicina de Toyoake do Japão, atesta a eficiência do uso de purificadores de ar a base de ozônio (O₃) em baixa concentração, contra a redução da síndrome respiratória aguda de grave infectividade do Coronavírus-2: <a className="ref" href="https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe" target="blank">https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe</a></p>
              </>
            }
          />
        </div>

        <div className="card" id="2">
          <div className="card-image">
            <img alt="imagem produto" src={puri30} />
          </div>
          <h3>SANITECH O₃ PURI 30</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ PURI 30"
            descricao={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ PURI 30</Strong> produz ozônio O₃, um gás bactericida e germicida natural, capaz de purificar e esterilizar do ar ambiente, não é prejudicial à saúde quando produzido em baixa concentração e utilizando da forma correta de instalação e manutenção do equipamento. O objetivo de evitar e mitigar a contaminação cruzada por bactérias, vírus, germes, fungos e quaisquer microrganismos por aerossóis de espirros, tosse, gripes, alergias, sinusite, nariz entupido, odores de animais de estimação e espanta insetos em ambiente fechado, evitando a picada dos mosquitos transmissores da dengue, chikungunya e zika, tornando o ar mais puro e leve para as atividades diárias e ao dormir.</p>
              </>
            }
            func="APLICABILIDADE DO SANITECH O₃ PURI 30"
            funcionamento={
              <>
                <p className="modal-desc">Sua demanda foi planejada para atender uma área de <Strong>30 m³ (larg x comp x alt), produz 0,02 ppm</Strong> e exigiu um desenvolvimento tecnológico de inovação e sustentabilidade ecologicamente correto. </p>
                <p className="modal-desc">Sua aplicabilidade está voltada para os seguintes segmentos: Residências, hospitais, clínicas, Instituições de Longa Permanência para Idosos, consultórios dentários, escolas, escritórios, repartições públicas, call center, hotéis, veterinárias, academias, salão de beleza, indústrias de processamentos de alimentos, supermercados, banheiros públicos, restaurantes, bares, teatros, cinemas, shoppings, automóveis, caminhões, rodoviárias, aeroportos, portos e outros para dar suporte às atividades diárias de intenso fluxo de pessoas expostas a contaminação cruzada, trazendo mais proteção e segurança.</p>
                <p className="modal-desc">Nosso equipamento é <Strong>patenteado e depositado sob o nº BR 20 2021 009339 6,</Strong> e reconhecido pela <Strong>Revista Microorganisms Americana (MDPI), </Strong>estão de acordo com o Ministério do Trabalho e Emprego através da norma regulamentadora <Strong>(NR 15, anexo 11 do MTE), (BRASIL, 2016), que indica exposição máxima de 0,08 ppm (0,16 mg/m³)</Strong> para jornadas de trabalho de até 48 horas por semana e não requer autorização da ANVISA para registro:</p>
                <p className="modal-desc">Link da publicação da revista: <a className="ref" href="https://www.mdpi.com/1423032" target="blank">https://www.mdpi.com/1423032</a></p>
                <p className="modal-desc">Um artigo publicado em 2020 pelo professor Takayuki Murata da Universidade de Medicina de Toyoake do Japão, atesta a eficiência do uso de purificadores de ar a base de ozônio (O₃) em baixa concentração, contra a redução da síndrome respiratória aguda de grave infectividade do Coronavírus-2: <a className="ref" href="https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe" target="blank">https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe</a></p>
              </>
            }
          />
        </div>

        <div className="card" id="3">
          <div className="card-image">
            <img alt="imagem produto" src={puri60} />
          </div>
          <h3>SANITECH O₃ PURI 60</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ PURI 60"
            descricao={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ PURI 60</Strong> produz ozônio O₃, um gás bactericida e germicida natural, capaz de purificar e esterilizar do ar ambiente, não é prejudicial à saúde quando produzido em baixa concentração e utilizando da forma correta de instalação e manutenção do equipamento. O objetivo de evitar e mitigar a contaminação cruzada por bactérias, vírus, germes, fungos e quaisquer microrganismos por aerossóis de espirros, tosse, gripes, alergias, sinusite, nariz entupido, odores de animais de estimação e espanta insetos em ambiente fechado, evitando a picada dos mosquitos transmissores da dengue, chikungunya e zika, tornando o ar mais puro e leve para as atividades diárias e ao dormir.</p>
              </>
            }
            func="APLICABILIDADE DO SANITECH O₃ PURI 60"
            funcionamento={
              <>
                <p className="modal-desc">Sua demanda foi planejada para atender uma área de <Strong>60 m³ (larg x comp x alt), produz 0,04 ppm</Strong> e exigiu um desenvolvimento tecnológico de inovação e sustentabilidade ecologicamente correto. </p>
                <p className="modal-desc">Sua aplicabilidade está voltada para os seguintes segmentos: Residências, hospitais, clínicas, Instituições de Longa Permanência para Idosos, consultórios dentários, escolas, escritórios, repartições públicas, call center, hotéis, veterinárias, academias, salão de beleza, indústrias de processamentos de alimentos, supermercados, banheiros públicos, restaurantes, bares, teatros, cinemas, shoppings, automóveis, caminhões, rodoviárias, aeroportos, portos e outros para dar suporte às atividades diárias de intenso fluxo de pessoas expostas a contaminação cruzada, trazendo mais proteção e segurança.</p>
                <p className="modal-desc">Nosso equipamento é <Strong>patenteado e depositado sob o nº BR 20 2021 009339 6,</Strong> e reconhecido pela <Strong>Revista Microorganisms Americana (MDPI), </Strong>estão de acordo com o Ministério do Trabalho e Emprego através da norma regulamentadora <Strong>(NR 15, anexo 11 do MTE), (BRASIL, 2016), que indica exposição máxima de 0,08 ppm (0,16 mg/m³)</Strong> para jornadas de trabalho de até 48 horas por semana e não requer autorização da ANVISA para registro:</p>
                <p className="modal-desc">Link da publicação da revista: <a className="ref" href="https://www.mdpi.com/1423032" target="blank">https://www.mdpi.com/1423032</a></p>
                <p className="modal-desc">Um artigo publicado em 2020 pelo professor Takayuki Murata da Universidade de Medicina de Toyoake do Japão, atesta a eficiência do uso de purificadores de ar a base de ozônio (O₃) em baixa concentração, contra a redução da síndrome respiratória aguda de grave infectividade do Coronavírus-2: <a className="ref" href="https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe" target="blank">https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe</a></p>
              </>
            }
          />
        </div>

        <div className="card" id="4">
          <div className="card-image">
            <img alt="imagem produto" src={puri90} />
          </div>
          <h3>SANITECH O₃ PURI 90</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ PURI 90"
            descricao={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ PURI 90</Strong> produz ozônio O₃, um gás bactericida e germicida natural, capaz de purificar e esterilizar do ar ambiente, não é prejudicial à saúde quando produzido em baixa concentração e utilizando da forma correta de instalação e manutenção do equipamento. O objetivo de evitar e mitigar a contaminação cruzada por bactérias, vírus, germes, fungos e quaisquer microrganismos por aerossóis de espirros, tosse, gripes, alergias, sinusite, nariz entupido, odores de animais de estimação e espanta insetos em ambiente fechado, evitando a picada dos mosquitos transmissores da dengue, chikungunya e zika, tornando o ar mais puro e leve para as atividades diárias e ao dormir.</p>
              </>
            }
            func="APLICABILIDADE DO SANITECH O₃ PURI 90"
            funcionamento={
              <>
                <p className="modal-desc">Sua demanda foi planejada para atender uma área de <Strong>90 m³ (larg x comp x alt), produz 0,06 ppm</Strong> e exigiu um desenvolvimento tecnológico de inovação e sustentabilidade ecologicamente correto. </p>
                <p className="modal-desc">Sua aplicabilidade está voltada para os seguintes segmentos: Residências, hospitais, clínicas, Instituições de Longa Permanência para Idosos, consultórios dentários, escolas, escritórios, repartições públicas, call center, hotéis, veterinárias, academias, salão de beleza, indústrias de processamentos de alimentos, supermercados, banheiros públicos, restaurantes, bares, teatros, cinemas, shoppings, automóveis, caminhões, rodoviárias, aeroportos, portos e outros para dar suporte às atividades diárias de intenso fluxo de pessoas expostas a contaminação cruzada, trazendo mais proteção e segurança.</p>
                <p className="modal-desc">Nosso equipamento é <Strong>patenteado e depositado sob o nº BR 20 2021 009339 6,</Strong> e reconhecido pela <Strong>Revista Microorganisms Americana (MDPI), </Strong>estão de acordo com o Ministério do Trabalho e Emprego através da norma regulamentadora <Strong>(NR 15, anexo 11 do MTE), (BRASIL, 2016), que indica exposição máxima de 0,08 ppm (0,16 mg/m³)</Strong> para jornadas de trabalho de até 48 horas por semana e não requer autorização da ANVISA para registro:</p>
                <p className="modal-desc">Link da publicação da revista: <a className="ref" href="https://www.mdpi.com/1423032" target="blank">https://www.mdpi.com/1423032</a></p>
                <p className="modal-desc">Um artigo publicado em 2020 pelo professor Takayuki Murata da Universidade de Medicina de Toyoake do Japão, atesta a eficiência do uso de purificadores de ar a base de ozônio (O₃) em baixa concentração, contra a redução da síndrome respiratória aguda de grave infectividade do Coronavírus-2: <a className="ref" href="https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe" target="blank">https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe</a></p>
              </>
            }
          />
        </div>

        <div className="card" id="5">
          <div className="card-image">
            <img alt="imagem produto" src={puri120} />
          </div>
          <h3>SANITECH O₃ PURI 120</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ PURI 120"
            descricao={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ PURI 120</Strong> produz ozônio O₃, um gás bactericida e germicida natural, capaz de purificar e esterilizar do ar ambiente, não é prejudicial à saúde quando produzido em baixa concentração e utilizando da forma correta de instalação e manutenção do equipamento. O objetivo de evitar e mitigar a contaminação cruzada por bactérias, vírus, germes, fungos e quaisquer microrganismos por aerossóis de espirros, tosse, gripes, alergias, sinusite, nariz entupido, odores de animais de estimação e espanta insetos em ambiente fechado, evitando a picada dos mosquitos transmissores da dengue, chikungunya e zika, tornando o ar mais puro e leve para as atividades diárias e ao dormir.</p>
              </>
            }
            func="APLICABILIDADE DO SANITECH O₃ PURI 120"
            funcionamento={
              <>
                <p className="modal-desc">Sua demanda foi planejada para atender uma área de <Strong>120 m³ (larg x comp x alt), produz 0,08 ppm</Strong> e exigiu um desenvolvimento tecnológico de inovação e sustentabilidade ecologicamente correto. </p>
                <p className="modal-desc">Sua aplicabilidade está voltada para os seguintes segmentos: Residências, hospitais, clínicas, Instituições de Longa Permanência para Idosos, consultórios dentários, escolas, escritórios, repartições públicas, call center, hotéis, veterinárias, academias, salão de beleza, indústrias de processamentos de alimentos, supermercados, banheiros públicos, restaurantes, bares, teatros, cinemas, shoppings, automóveis, caminhões, rodoviárias, aeroportos, portos e outros para dar suporte às atividades diárias de intenso fluxo de pessoas expostas a contaminação cruzada, trazendo mais proteção e segurança.</p>
                <p className="modal-desc">Nosso equipamento é <Strong>patenteado e depositado sob o nº BR 20 2021 009339 6,</Strong> e reconhecido pela <Strong>Revista Microorganisms Americana (MDPI), </Strong>estão de acordo com o Ministério do Trabalho e Emprego através da norma regulamentadora <Strong>(NR 15, anexo 11 do MTE), (BRASIL, 2016), que indica exposição máxima de 0,08 ppm (0,16 mg/m³)</Strong> para jornadas de trabalho de até 48 horas por semana e não requer autorização da ANVISA para registro:</p>
                <p className="modal-desc">Link da publicação da revista: <a className="ref" href="https://www.mdpi.com/1423032" target="blank">https://www.mdpi.com/1423032</a></p>
                <p className="modal-desc">Um artigo publicado em 2020 pelo professor Takayuki Murata da Universidade de Medicina de Toyoake do Japão, atesta a eficiência do uso de purificadores de ar a base de ozônio (O₃) em baixa concentração, contra a redução da síndrome respiratória aguda de grave infectividade do Coronavírus-2: <a className="ref" href="https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe" target="blank">https://pure.fujita-hu.ac.jp/en/publications/reduction-of-severe-acute-respiratory-syndrome-coronavirus-2-infe</a></p>
              </>
            }
          />
        </div>

        <div className="card" id="6">
          <div className="card-image">
            <img alt="imagem produto" src={Sensor10} />
          </div>
          <h3>SANITECH SENSOR DE OZÔNIO O₃ 0-10ppm</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH SENSOR DE OZÔNIO O₃ 0 A 10 PPM"
            descricao={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH SENSOR DE OZÔNIO O₃ 0 A 10 PPM</Strong> para
                  uso em ambientes que utilizam equipamentos com a tecnologia do gás
                  de ozônio O₃, na purificação, esterilização, desinfecção e sanitização do
                  ar ambiente, identificando a concentração do ozônio O₃, através da
                  leitura do ozônio O₃ na concentração 0 a 10 PPM.</p>
                <p>O sistema coleta os dados emitidos pelo módulo eletroquímico “Winsen
                  ZE-25” de forma digital, interpreta e os mostra em uma tela OLED.
                  O mesmo ocorre com os sensores de temperatura e umidade.</p>
                <p>A Astech se qualifica como integradora do equipamento na tecnologia
                  de leitura de ozônio, não sendo responsável pela calibração do módulo
                  sensor “Winsen ZE-25 Eletroquímico”.</p>
                <p>Esse módulo vem calibrado de fábrica e a validade é de 2 anos.</p>
              </>
            }
            func=""
            funcionamento=""
          />
        </div>

        <div className="card" id="7">
          <div className="card-image">
            <img alt="imagem produto" src={Sensor} />
          </div>
          <h3>SANITECH SENSOR DE OZÔNIO O₃ 0-100ppm</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH SENSOR DE OZÔNIO O₃ 0 A 100 PPM"
            descricao={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH SENSOR DE OZÔNIO O₃ 0 A 100 PPM</Strong> para uso em ambientes que utilizam equipamentos com a tecnologia do
                  gás de ozônio O₃, na purificação, esterilização, desinfecção e sanitização
                  do ar ambiente, identificando a concentração do ozônio O₃, através da
                  leitura do ozônio O₃ na concentração 0 a 100 PPM.</p>
                <p>O sistema coleta os dados emitidos pelo módulo eletroquímico “Winsen
                  ZE-25” de forma digital, interpreta e os mostra em uma tela OLED.
                  O mesmo ocorre com os sensores de temperatura e umidade.</p>
                <p>A Astech se qualifica como integradora do equipamento na tecnologia
                  de leitura de ozônio, não sendo responsável pela calibração do módulo
                  sensor “Winsen ZE-25 Eletroquímico”.</p>
                <p>Esse módulo vem calibrado de fábrica e a validade é de 2 anos.</p>
              </>
            }
            func=""
            funcionamento=""
          />
        </div>

        <div className="card" id="8">
          <div className="card-image">
            <img style={{ width: '140px' }} alt="imagem produto" src={SanitechO3AcquaPet} />
          </div>
          <h3>SANITECH O₃ ACQUA PET</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ ACQUA PET"
            descricao={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH O₃ ACQUA PET</Strong> para uso veterinário, com
                  a tecnologia do gás de ozônio (O₃) e acessórios, tem como objetivo a
                  utilização da água ozonizada no uso terapêutico, poder desempenhar
                  funções importantes auxiliando na descontaminação e cicatrização,
                  como agente anti-microbiano na, lavagem de diversas feridas, lavagem
                  dos pets e outros.</p>
              </>
            }
            func="APLICABILIDADE DO EQUIPAMENTO SANITECH O₃ ACQUA PET"
            funcionamento={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH O₃ ACQUA PET</Strong>, teve sua demanda
                  desenvolvida e planejada para atender as, veterinárias, haras de cavalos,
                  canil, gatis, Centro de Zoonoses, currais, chiqueiros e outros, para dar
                  suporte às atividades diárias.</p>
                <p>O desenvolvimento e a inovação de um equipamento <Strong>ecologicamente
                  correto,</Strong> para uma solução alternativa de terapia veterinária.</p>
                <p>Na veterinária, o uso da água ozonizada no enxágue após banho do
                  animal, eliminando pulgas, carrapatos e outros, no tratamento de
                  feridas, no enxágue dos canis e instalações dos animais, na
                  potencialização e diluição de produtos químicos e outros.</p>
              </>
            }
          />
        </div>

        <div className="card" id="9">
          <div className="card-image">
            <img style={{ width: '140px' }} alt="imagem produto" src={SanitechO3Camara} />
          </div>
          <h3>SANITECH O₃ CÂMARA FRIA</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ CÂMARA FRIA"
            descricao={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH O₃ CÂMARA FRIA</Strong> utiliza a tecnologia do
                  gás de ozônio (O₃) e acessórios, tem como objetivo a descontaminação
                  do ar ambiente das câmaras frias, eliminando a contaminação cruzada
                  por bactérias, vírus, germes, fungos, microrganismos e odores
                  indesejáveis, podendo desempenhar funções importantes, minimizando
                  as perdas do FLV (Frutas, Legumes e Verduras), como agente oxidante e
                  outros.</p>
              </>
            }
            func="APLICABILIDADE DO SANITECH O₃ CÂMARA FRIA"
            funcionamento={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH O₃ CÂMARA FRIA,</Strong> teve sua demanda
                  desenvolvida e planejada para atender os CDs (Centros de Distribuição),
                  Atacadistas, Supermercados, Restaurantes, Hotéis, Hospitais, Indústrias
                  de Processamentos de Alimentos e outros, para dar suporte às
                  atividades diárias.</p>
                <p>O desenvolvimento e a inovação de um equipamento <Strong>ecologicamente
                  correto,</Strong> para uma solução alternativa as perdas por apodrecimento ou
                  amadurecimento dos alimentos.</p>
                <p>O <Strong>EQUIPAMENTO SANITECH O₃ CÂMARA FRIA,</Strong> é instalado na ante-sala
                  da Câmara Fria e através de uma mangueira de silicone o ozônio (O₃)
                  chega até o forçador para melhor fluxo e espalhamento do ozônio (O₃)
                  nos alimentos.</p>
              </>
            }
          />
        </div>

        <div className="card" id="10">
          <div className="card-image">
            <img style={{ width: '140px' }} alt="imagem produto" src={SanitechO340} />
          </div>
          <h3>SANITECH O₃ 40</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ 40"
            descricao={
              <>
                <p className="modal-desc">
                  O <Strong>EQUIPAMENTO SANITECH O₃ 40</Strong> produz ozônio O₃ em alta concentração, um gás bactericida e germicida natural, capaz de desinfectar e sanitizar do ar ambiente e superfícies, <Strong>é prejudicial à saúde e a utilização do equipamento só será possível em <Strong2>ambientes desabitados</Strong2>.</Strong> O objetivo é desinfectar e sanitizar do ar ambiente e superfícies, evitando a contaminação cruzada por bactérias, vírus, germes, fungos e quaisquer microrganismos por aerossóis, odores de animais de estimação no transporte de pet e outros.
                </p>
              </>
            }
            func="APLICABILIDADE DO SANITECH O₃ 40"
            funcionamento={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ 40,</Strong> teve sua demanda desenvolvida e planejada para os seguintes segmentos: Hospitalares/clínicas trazendo mais proteção e segurança aos profissionais da saúde, funcionários, colaboradores e usuários que estejam em ambientes <Strong>de áreas críticas e semicríticas,</Strong> que requerem cuidados, Instituições de Longa Permanência para Idosos, consultórios dentários, veterinárias, residências, salão de beleza, escolas, hotéis, indústrias de processamentos de alimentos, supermercados, banheiros públicos, restaurantes/bares, automóveis, caminhões, plantas de saneamentos e outros para dar suporte às atividades diárias em <Strong2>ambientes desabitados.</Strong2></p>
                <p className="modal-desc">O desenvolvimento e a inovação de um equipamento ecologicamente correto, o equipamento desliga automaticamente depois da aplicação de 25 minutos e emite um bip de aviso ao seu término. A ventilação continua por mais 5 minutos para <Strong>dissipação do ozônio (O₃)</Strong> e após esse tempo é emitido um bip por longo tempo para avisar que está terminada a aplicação e <Strong>5 minutos depois o ozônio (O₃) se transforma em oxigênio</Strong> e para uma solução complementar <Strong>à limpeza terminal pelos saneantes químicos.</Strong></p>
                <p className="modal-desc">Importante sinalizar na porta. <Strong2>AMBIENTE INTERDITADO “PERIGO”</Strong2></p>
              </>
            }
          />
        </div>

        <div className="card" id="11">
          <div className="card-image">
            <img style={{ width: '140px' }} alt="imagem produto" src={SanitechO380Sanitizacao} />
          </div>
          <h3>SANITECH O₃ 80</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ 80"
            descricao={
              <>
                <p className="modal-desc">
                  O <Strong>EQUIPAMENTO SANITECH O₃ 80</Strong> produz ozônio O₃ em alta concentração, um gás bactericida e germicida natural, capaz de desinfectar e sanitizar do ar ambiente e superfícies, <Strong>é prejudicial à saúde e a utilização do equipamento só será possível em <Strong2>ambientes desabitados.</Strong2></Strong> O objetivo é desinfectar e sanitizar do ar ambiente e superfícies, evitando a contaminação cruzada por bactérias, vírus, germes, fungos e quaisquer microrganismos por aerossóis, odores de animais de estimação no transporte de pet e outros.
                </p>
              </>
            }
            func="APLICABILIDADE DO SANITECH O₃ 80"
            funcionamento={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ 80,</Strong> teve sua demanda desenvolvida e planejada para os seguintes segmentos: Hospitalares/clínicas trazendo mais proteção e segurança aos profissionais da saúde, funcionários, colaboradores e usuários que estejam em ambientes <Strong>de áreas críticas e semicríticas,</Strong> que requerem cuidados, Instituições de Longa Permanência para Idosos, consultórios dentários, veterinárias, residências, salão de beleza, escolas, hotéis, indústrias de processamentos de alimentos, supermercados, banheiros públicos, restaurantes/bares, automóveis, caminhões, plantas de saneamentos e outros para dar suporte às atividades diárias em <Strong2>ambientes desabitados.</Strong2></p>
                <p className="modal-desc">O desenvolvimento e a inovação de um equipamento ecologicamente correto para uma solução complementar <Strong>à limpeza terminal com saneantes químicos.</Strong></p>
                <p className="modal-desc">O equipamento funciona direto e <Strong>uso de EPIS contra gases</Strong> é necessário para esse caso e é recomendável aguardar por mais 10 minutos após o sinal de fim de aplicação emitido pelo equipamento para <Strong>dissipação do ozônio (O₃).</Strong></p>
                <p className="modal-desc">Fomos procurados pela Dra. Karyne Rangel, Doutora em Ciências pelo Programa de Pós-Graduação em Vigilância Sanitária do Instituto Nacional de Controle de Qualidade em Saúde (PPGVS/INCQS) e pesquisadora do Laboratório de Bioquímica de Proteínas e Peptídeos do Centro de Desenvolvimento Tecnológico em Saúde da <Strong>Fundação Oswaldo Cruz (LBPP/CDTS/FIOCRUZ),</Strong> realizando seu segundo Pós-Doutorado na área de microbiologia, nos propôs um estudo colaborativo sobre a eficácia do ozônio O₃, assunto de grande interesse atual, em virtude dos acontecimentos.</p>
                <p className="modal-desc">Diante da proposta de estudar o efeito do ozônio sobre bactérias patogênicas, a <Strong>ASTECH</Strong> não mediu esforços em colaborar com o projeto, disponibilizando inicialmente o <Strong>EQUIPAMENTO SANITECH O₃ 40</Strong> juntamente com o detector/medidor de gás portátil para monitoramento da concentração ambiental de <Strong>ozônio (O₃)</Strong> emitido.</p>
                <p className="modal-desc">Como era de se esperar, os testes obtiveram resultados preliminares surpreendentes, mostrando que em alta concentração de ozônio O₃ foi capaz de interferir significativamente na viabilidade celular da maioria das bactérias estudadas através da inibição do crescimento, conforme <Strong>relatório da Drª Karyne Rangel disponível para consulta e publicação de artigo na Revista Microorganisms Americana (MDPI).</Strong></p>
                <p className="modal-desc">Diante do fato, segue link da publicação e contribuição da Astech no arquivo para download: <a className="ref" href="https://www.mdpi.com/1420-3049/27/13/3998" target="blank">https://www.mdpi.com/1420-3049/27/13/3998</a></p>
                <p className="modal-desc">Importante sinalizar na porta. <Strong2>AMBIENTE INTERDITADO “PERIGO”</Strong2></p>
              </>
            }
          />
        </div>

        <div className="card" id="12">
          <div className="card-image">
            <img style={{ width: '140px' }} alt="imagem produto" src={DescontaminadorSapatos} />
          </div>
          <h3>SANITECH UVC DESCONTAMINADOR DE SOLA DE SAPATOS</h3>
          <ModalProduct
            desc="SANITECH UVC DESCONTAMINADOR DE SOLA DE SAPATOS"
            descricao={
              <>
                <p className="modal-desc">O <Strong>SANITECH EQUIPAMENTO UVC DESCONTAMINADOR DE SOLA DE SAPATOS</Strong> utiliza a tecnologia de lâmpadas UVC e tem o objetivo de eliminar quaisquer tipos de microrganismos por bactérias, vírus, germes e fungos, reduzindo os riscos sanitários, melhorando a qualidade do ar ambiente e reduzir cargas ambientais e atenuando a transmissão por via indireta de microrganismos e auxiliando na prevenção da contaminação cruzada por agentes infecciosos.</p>
              </>
            }
            func="APLICABILIDADE DO SANITECH UVC DESCONTAMINADOR DE SOLA DE SAPATOS"
            funcionamento={
              <>
                <p className="modal-desc">O <Strong>SANITECH EQUIPAMENTO UVC DESCONTAMINADOR DE SOLA DE SAPATOS,</Strong> teve sua demanda desenvolvida e planejada para os seguintes segmentos: Hospitais, salas de cirurgias e UTIs, clínicas, policlínicas, Instituições de Longa Permanência para Idosos, maternidades, prontos-socorros, consultórios dentários, veterinárias, pavilhões de exposições, rodoviárias, aeroportos, portos, empresas de ônibus interestaduais, aviões, transatlânticos, residências, salão de beleza, escolas, hotéis, cinemas, teatros, indústrias de processamentos de alimentos, supermercados, banheiros públicos, restaurantes/bares e outros para dar suporte às atividades diárias.</p>
                <p className="modal-desc">O desenvolvimento e a inovação de um equipamento <Strong>ecologicamente correto,</Strong> para uma solução alternativa <Strong>aos saneantes químicos,</Strong> trazendo mais proteção e segurança aos profissionais da saúde, funcionários, colaboradores e usuários que estejam em ambientes que requerem cuidados.</p>
                <p className="modal-desc">Apesar da limpeza diária de superfícies de alto contato, como pisos, já foi demonstrado que a contaminação bacteriana e viral retorna e se espalha rapidamente por contaminação cruzada <Strong>e por aerossolização.</Strong></p>
                <p className="modal-desc">As lavagens frequentes ou a utilização de desinfetantes para baixar o nível de contaminação bacteriana que está presente nas superfícies a serem limpas, não pode ser considerado útil na prevenção de disseminação de infecções, por não ser um processo prático e não conseguir eliminar a fonte de transporte dos patógenos.</p>
                <p className="modal-desc">O processo de sanitização das solas dos sapatos deverá ser de 05 (cinco) a 10 (dez) segundos, dando início a esterilização por um sensor que emitirá um sinal sonoro até completar o ciclo de sanitização da sola do sapato, cumprindo o processo e o protocolo de segurança corretamente e, além de rápido, é simples e intuitivo.</p>
                <p className="modal-desc">O equipamento é compreendido por uma estrutura em forma de gabinete plástico atóxico resistente, alumínio e/ou material similar, de alta resistência a produtos químicos, cuja estrutura em forma de gabinete compreende uma plataforma para subir e pisar, dimensionado para uma pessoa de até <Strong>135 kg,</Strong> promovendo facilidade para remoção da sujidade.</p>
                <p className="modal-desc"><Strong>Atualmente esse tipo de equipamento é importado, e agora, a Astech está fabricando no Brasil.</Strong></p>
                <p className="modal-desc">O <Strong>SANITECH EQUIPAMENTO UVC DESCONTAMINADOR DE SOLA DE SAPATOS</Strong> foi testado pela Dra. Karyne Rangel, Doutora em Ciências pelo Programa de Pós-Graduação em Vigilância Sanitária do Instituto Nacional de Controle de Qualidade em Saúde (PPGVS/INCQS) e pesquisadora do Laboratório de Bioquímica de Proteínas e Peptídeos do Centro de Desenvolvimento Tecnológico em Saúde da <Strong>Fundação Oswaldo Cruz (LBPP/CDTS/FIOCRUZ),</Strong> assunto de grande interesse da FIOCRUZ por causa das infecções hospitalares, após a realização de cirurgias.</p>
                <p className="modal-desc">Como era de se esperar, os testes obtiveram resultados surpreendentes, mostrando a descontaminação das solas dos calçados em que as lâmpadas de UVC foram capazes de interferir significativamente na viabilidade celular da maioria das bactérias estudadas através da inibição do crescimento, conforme <Strong>relatório da Drª Karyne Rangel disponível para consulta e publicação de artigo na Revista Microorganisms Americana (MDPI).</Strong></p>
                <p className="modal-desc">Diante do fato, segue link da publicação e contribuição da Astech no arquivo para download:</p>
                <p className="modal-desc"><a className="ref" href="https://www.mdpi.com/1660-4601/19/24/16559" target="blank">https://www.mdpi.com/1660-4601/19/24/16559</a></p>
                <p className="modal-desc"><a className="ref" href="https://www.arca.fiocruz.br/handle/icict/56226" target="blank">https://www.arca.fiocruz.br/handle/icict/56226</a></p>
                <p className="modal-desc"><a className="ref" href="https://pubmed.ncbi.nlm.nih.gov/36554438/" target="blank">https://pubmed.ncbi.nlm.nih.gov/36554438/</a></p>
              </>
            }
          />
        </div>

        <div className="card" id="13">
          <div className="card-image">
            <img style={{ width: '120px' }} alt="imagem produto" src={SistemaMVO3} />
          </div>
          <h3>SANITECH MVO₃ ACQUA ATÉ 5 MIL LITROS</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH MVO₃ ACQUA ATÉ 5 MIL LITROS"
            descricao={
              <>
                <p>O <Strong>SANITECH MVO₃ ACQUA ATÉ 5 MIL LITROS</Strong> para
                  sanitização e reestruturação de água potável de cisterna ou caixa d’água
                  com a tecnologia do ozônio (O₃), venturi, vórtex e magnético, tem o
                  objetivo mitigar a contaminação por bactérias, vírus, fungos e
                  microrganismos de água de poço, igarapés, rios, represas, açudes, água
                  das empresas concessionárias e outros.</p>
              </>
            }
            func="APLICABILIDADE DO EQUIPAMENTO SANITECH MVO₃ ACQUA ATÉ 5 MIL LITROS"
            funcionamento={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH MVO₃ ACQUA ATÉ 5 MIL LITROS,</Strong> teve sua
                  demanda desenvolvida e planejada para atender os cidadãos de todas as
                  regiões do Brasil, que não gozam de água tratada e utilizam águas de
                  chuvas, igarapés, rios, açudes, represas, e outros para dar suporte às
                  atividades diárias.</p>
                <p>O crescente desenvolvimento das atividades humanas tem causado
                  progressiva deterioração nos ecossistemas ecológicos e impactos
                  ambientais.</p>
                <p>O ambiente tem sofrido ao longo dos anos alterações provocadas pela
                  elevada quantidade de dejetos excedentes desses processos, os quais
                  ultrapassam a capacidade suporte dos ecossistemas.</p>
                <p><Strong>A água apresenta um significativo destaque, pois sua manutenção
                  em condições naturais de qualidade é absolutamente necessária a
                  todo tipo de vida do planeta.</Strong></p>
                <p><Strong>A água possui múltiplos usos e a ONU e a OMS reconhecem todos
                  eles como legítimos.</Strong></p>
                <p>A qualidade da água pode ser representada através de diversos
                  parâmetros, que traduzem as principais características físicas, químicas e
                  biológicas.</p>
                <p>A ingestão de alimentos e líquidos contaminados por resíduos de
                  coliformes fecais é uma das formas mais comuns de se contrair a
                  doenças.</p>
                <p>É importante a higienização dos alimentos, evitarem o consumo de itens
                  crus.</p>
                <p><Strong>Com a adição pesada de cloro perdemos a qualidade de uma água
                  muito boa na sua origem (mananciais).</Strong></p>
                <p>Não existe qualquer dúvida, hoje em dia, o cloro adicionado tem um
                  forte efeito oxidante e participa <Strong>da gênese do câncer de bexiga,</Strong>
                  favorece a disbiose (alteração da flora intestinal), levando a infecções por
                  fungos e vírus, além da oxidação do organismo.</p>
                <p>Muitas cidades europeias estão substituindo, ou já substituíram o cloro
                  pelo ozônio (O₃).</p>
                <p>Com o uso da tecnologia do <Strong>EQUIPAMENTO SANITECH MVO₃ ACQUA
                  ATÉ 5 MIL LITROS,</Strong> podemos contribuir para um planeta melhor no
                  consumo de água tratada com esse processo e abaixo os benefícios:</p>
                <ul>
                  <li>• Destruição de compostos por quebra das cadeias;</li>
                  <li>• Mineralização de compostos orgânicos dissolvidos;</li>
                  <li>• Elevação do potencial redox da água;</li>
                  <li>• Auxiliar de microfloculação;</li>
                  <li>• Alta reatividade contra poluentes e agrotóxicos;</li>
                  <li>• Desinfecção bacteriológica;</li>
                  <li>• Eliminação de AOX;</li>
                  <li>• Oxidação de compostos orgânicos;</li>
                  <li>• Oxidação de substâncias inorgânicas;</li>
                  <li>• Pequenas taxas de corrosão;</li>
                  <li>• Redução de DOC (Dissolved Organic Carbon);</li>
                  <li>• Redução de trialometanos (THM’s);</li>
                  <li>• Remoção de cor;</li>
                  <li>• Remoção de ferro solúvel e manganês por oxidação;</li>
                  <li>• Remoção de sabor e odor.</li>
                </ul>
              </>
            }
          />
        </div>

        <div className="card" id="14">
          <div className="card-image">
            <img style={{ width: '120px' }} alt="imagem produto" src={SistemaMVO350} />
          </div>
          <h3>SANITECH MVO₃ ACQUA ATÉ 50 MIL LITROS</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH MVO₃ ACQUA ATÉ 50 MIL LITROS"
            descricao={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH MVO₃ ACQUA ATÉ 50 MIL LITROS</Strong> para
                  sanitização e reestruturação de água potável de cisterna ou caixa d’água
                  com a tecnologia do ozônio (O₃), venturi, vórtex e magnético, tem o
                  objetivo mitigar a contaminação por bactérias, vírus, fungos e
                  microrganismos de água de poço, igarapés, rios, represas, açudes, água
                  das empresas concessionárias e outros.</p>
              </>
            }
            func="APLICABILIDADE DO EQUIPAMENTO SANITECH MVO₃ ACQUA ATÉ 50 MIL LITROS"
            funcionamento={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH MVO₃ ACQUA ATÉ 50 MIL LITROS,</Strong> teve
                  sua demanda desenvolvida e planejada para atender os cidadãos de
                  todas as regiões do Brasil, que não gozam de água tratada e utilizam águas de chuvas, igarapés, rios, açudes, represas, e outros para dar
                  suporte às atividades diárias.</p>
                <p>O crescente desenvolvimento das atividades humanas tem causado
                  progressiva deterioração nos ecossistemas ecológicos e impactos
                  ambientais.</p>
                <p>O ambiente tem sofrido ao longo dos anos alterações provocadas pela
                  elevada quantidade de dejetos excedentes desses processos, os quais
                  ultrapassam a capacidade suporte dos ecossistemas.</p>
                <p><Strong>A água apresenta um significativo destaque, pois sua manutenção
                  em condições naturais de qualidade é absolutamente necessária a
                  todo tipo de vida do planeta.</Strong></p>
                <p><Strong>A água possui múltiplos usos e a ONU e a OMS reconhecem todos
                  eles como legítimos.</Strong></p>
                <p>A qualidade da água pode ser representada através de diversos
                  parâmetros, que traduzem as principais características físicas, químicas e
                  biológicas.</p>
                <p>A ingestão de alimentos e líquidos contaminados por resíduos de
                  coliformes fecais é uma das formas mais comuns de se contrair a
                  doenças.</p>
                <p>É importante a higienização dos alimentos, evitarem o consumo de itens
                  crus.</p>
                <p><Strong>Com a adição pesada de cloro perdemos a qualidade de uma água
                  muito boa na sua origem (mananciais).</Strong></p>
                <p>Não existe qualquer dúvida, hoje em dia, o cloro adicionado tem um
                  forte efeito oxidante e participa <Strong>da gênese do câncer de bexiga,</Strong>
                  favorece a disbiose (alteração da flora intestinal), levando a infecções por
                  fungos e vírus, além da oxidação do organismo.</p>
                <p>Muitas cidades europeias estão substituindo, ou já substituíram o cloro
                  pelo ozônio (O₃).</p>
                <p>Com o uso da tecnologia do <Strong>EQUIPAMENTO SANITECH MVO₃ ACQUA
                  ATÉ 50 MIL LITROS,</Strong> podemos contribuir para um planeta melhor no
                  consumo de água tratada com esse processo e abaixo os benefícios:</p>
                <ul>
                  <li>• Destruição de compostos por quebra das cadeias;</li>
                  <li>• Mineralização de compostos orgânicos dissolvidos;</li>
                  <li>• Elevação do potencial redox da água;</li>
                  <li>• Auxiliar de microfloculação;</li>
                  <li>• Alta reatividade contra poluentes e agrotóxicos;</li>
                  <li>• Desinfecção bacteriológica;</li>
                  <li>• Eliminação de AOX;</li>
                  <li>• Oxidação de compostos orgânicos;</li>
                  <li>• Oxidação de substâncias inorgânicas;</li>
                  <li>• Pequenas taxas de corrosão;</li>
                  <li>• Redução de DOC (Dissolved Organic Carbon);</li>
                  <li>• Redução de trialometanos (THM’s);</li>
                  <li>• Remoção de cor;</li>
                  <li>• Remoção de ferro solúvel e manganês por oxidação;</li>
                  <li>• Remoção de sabor e odor.</li>
                </ul>
              </>
            }
          />
        </div>

        <div className="card" id="15">
          <div className="card-image">
            <img style={{ width: '90px' }} alt="imagem produto" src={AcquaSanitizacao} />
          </div>
          <h3>SANITECH O₃ ACQUA SANITIZAÇÂO</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ ACQUA SANITIZAÇÃO"
            descricao={
              <>
                <p className="modal-desc"><Strong>O EQUIPAMENTO SANITECH O₃ ACQUA SANITIZAÇÃO</Strong> utiliza a tecnologia do gás de ozônio (O₃) e acessórios na água, proporcionando a utilização da água ozonizada nas aplicações de lavar, enxaguar superfícies, reduz a quantidade de saneantes químicos na diluição, porque a água ozonizada potencializa trazendo mais proteção e segurança aos profissionais da limpeza que respiram os saneantes químicos mais fortes e que estejam em ambientes que requerem cuidados."</p>
              </>
            }
            func="APLICABILIDADE DO EQUIPAMENTO SANITECH O₃ ACQUA SANITIZAÇÃO"
            funcionamento={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ ACQUA SANITIZAÇÃO,</Strong> teve sua demanda desenvolvida e planejada para atender as escolas, hospitais, clínicas, consultórios dentários, hotéis, indústrias de processamentos de alimentos, supermercados (açougue, peixaria, laticínios e flvs) restaurantes/bares, banheiros públicos, veterinárias, haras de cavalos, canil, gatis, Centro de Zoonoses, currais, chiqueiros e outros, para dar suporte às atividades diárias. </p>
                <p className="modal-desc">O desenvolvimento e a inovação de um equipamento <Strong>ecologicamente correto,</Strong> para uma solução alternativa na limpeza diária de superfícies de alto contato, como pisos, já foi demonstrado que a contaminação bacteriana e viral retorna e se espalha rapidamente por contaminação cruzada <Strong>e por aerossolização.</Strong></p>
                <p className="modal-desc">As lavagens frequentes ou a utilização de desinfetantes para baixar o nível de contaminação bacteriana que está presente nas superfícies a serem limpas, não pode ser considerado útil na prevenção de disseminação de infecções, por não ser um processo prático e não conseguir eliminar a fonte de transporte dos patógenos. </p>
                <p className="modal-desc">Nos diversos segmentos acima, podemos considerar o uso da água ozonizada na higienização dos alimentos, na sanitização das mãos dos profissionais, no vazio sanitário das indústrias de alimentos, após limpeza e desinfecção, no enxague de superfícies, na potencialização e diluição de produtos químicos e outros.</p>
                <p className="modal-desc">Nos supermercados a utilização da água ozonizada na higienização das frutas, legumes e verduras (FLVS), proporciona uma sobrevida e redução de perdas após seu manuseio, eliminado as bactérias, germes e fungos, na lavagem dos pescados, açougue nas superfícies de cortes das carnes e instrumentos, laticínios idem e na higienização dos instrumentos (facas, tesouras, bandejas e outros).</p>
                <p className="modal-desc">Nos banheiros públicos é muito utilizado nos sanitários, mictórios, no enxague de superfícies, na potencialização e diluição de produtos químicos e outros.</p>
                <p className="modal-desc">Acreditamos que vários segmentos desconhecem o uso da água ozonizada ou não dispõem desse tipo de aparelho para sanitização de água com a tecnologia do ozônio (O₃) e acessório de ducha higiênica, o qual objetiva reduzir riscos sanitários, melhorar a qualidade do trabalho dos profissionais de saúde e de outros segmentos e reduzir cargas ambientais de microrganismos, atenuando a transmissão destes por via indireta, auxiliando na prevenção da contaminação cruzada por agentes infecciosos.</p>
              </>
            }
          />
        </div>

        <div className="card" id="16">
          <div className="card-image">
            <img style={{ width: '90px' }} alt="imagem produto" src={AcquaSanitizacao} />
          </div>
          <h3>SANITECH O₃ ACQUA OZONIOTERAPIA</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ ACQUA OZONIOTERAPIA"
            descricao={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ ACQUA OZONIOTERAPIA</Strong> para uso na <Strong>ozonioterapia médica e veterinária,</Strong> com a tecnologia do gás de ozônio (O₃) e acessórios, tem como objetivo a utilização da água ozonizada no uso terapêutico, poder desempenhar funções importantes auxiliando na descontaminação e cicatrização, como agente anti-inflamatório, lavagem de diversas feridas e principalmente por diabetes, queimaduras, fungos de unhas, inflamação dos membros, lavagem dos pets e outros."</p>
              </>
            }
            func="APLICABILIDADE DO EQUIPAMENTO SANITECH O₃ ACQUA OZONIOTERAPIA"
            funcionamento={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ ACQUA OZONIOTERAPIA,</Strong> teve sua demanda desenvolvida e planejada para atender os Hospitalares/clínicas, Instituições de Longa Permanência para Idosos, consultórios dentários, veterinárias, haras de cavalos, canil, gatis, Centro de Zoonoses, currais, chiqueiros e outros, para dar suporte às atividades diárias. </p>
                <p className="modal-desc">O desenvolvimento e a inovação de um equipamento <Strong>ecologicamente correto,</Strong> para uma solução alternativa de terapia médica e veterinária.</p>
                <p className="modal-desc">A ozonioterapia é um procedimento que consiste na aplicação dos gases oxigênio e o ozônio (O₃) por diversas vias, inclusive a de lavagem e imersão em água ozonizada com objetivo terapêutico.</p>
                <p className="modal-desc">As feridas são classificadas como qualquer lesão no tecido epitelial, mucosas ou órgãos com prejuízo de suas funções básicas. </p>
                <p className="modal-desc">Principalmente, as feridas crônicas surgem associadas às doenças como diabetes mellitus, hipertensão arterial sistêmica, neoplasias, problemas vasculares, entre outras causas.</p>
                <p className="modal-desc">Desta forma, apresentam diversos graus de comprometimento, dependendo do estado geral do paciente, sua doença e gravidade. </p>
                <p className="modal-desc">Assim, são consideradas de resolução complexa quando a cicatrização é difícil e o processo prolongado.</p>
                <p className="modal-desc">A necessidade de um equipamento <Strong>SANITECH O₃ ACQUA OZONIOTERAPIA,</Strong> trará muitos benefícios a saúde dos pacientes, sendo usado para tratar, feridas infectadas, do pé diabético (UPDs) costumam coexistir com insuficiência vascular e constituem uma das principais causas da gangrena e amputação nos diabéticos </p>
                <p className="modal-desc">Desta forma o ozônio (O₃) contribui para a lavagem, descontaminação e a cicatrização das feridas, por meio da capacidade de oxidação, eliminando fungos, bactérias e vírus. Auxiliando ao sistema imunológico a poder se concentrar na cicatrização e não no combate as inflamações. </p>
                <p className="modal-desc">Nos consultórios dentários o uso da água ozonizada no enxague bucal, instrumentais, limpeza de superfícies e outros, tem sido muito bem utilizada, proporcionando resultados contra infecções, redução de custos e outros benefícios.</p>
                <p className="modal-desc">Nos salões de belezas, o uso da água ozonizada no enxague dos cabelos, na podologia, na potencialização e diluição de produtos cosméticos, na lavagem facial e outros.</p>
                <p className="modal-desc">Na veterinária, o uso da água ozonizada no enxague após banho do animal, eliminando pulgas, carrapatos e outros, no tratamento de feridas, no enxague dos canis, na potencialização e diluição de produtos químicos e outros.</p>
                <p className="modal-desc"><Strong>A ozonioterapia é eficaz contra mais de duzentos tipos diferentes de doenças</Strong></p>
              </>
            }
          />
        </div>

        <div className="card" id="17">
          <div className="card-image">
            <img alt="imagem produto" src={MedPro} />
          </div>
          <h3>SANITECH O₃ MED PRO MOBILE</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ MED PRO MOBILE"
            descricao={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ MED PRO MOBILE</Strong> para uso na ozonioterapia, com a tecnologia do gás de ozônio (O₃) e acessórios, tem como objetivo o de facilitar o trabalho do profissional no uso terapêutico da ozonioterapia. Possibilitando desempenhar funções importantes da ozonioterapia dentro do consultório e no campo. Por ser leve, portátil e equipado com baterias de litium que permitem o uso do equipamento em locais sem energia elétrica.</p>
              </>
            }
            func="APLICABILIDADE DO EQUIPAMENTO SANITECH O₃ MED PRO MOBILE"
            funcionamento={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ MED PRO MOBILE,</Strong> teve sua demanda desenvolvida e planejada para atender as redes hospitalares, clínicas, consultórios médicos e outros, para dar suporte às atividades diárias.</p>
                <p className="modal-desc">O desenvolvimento e a inovação de um equipamento <Strong>ecologicamente correto,</Strong> para uma solução alternativa de terapia médica, que consiste em administrar o ozônio (O₃) no corpo, um gás composto por 3 átomos de oxigênio o (O₃), ajudando a melhorar a oxigenação dos tecidos, aumentando a resposta do sistema imunológico a doenças infecciosas e no alívio de dores crônicas.</p>
                <p className="modal-desc">O equipamento <Strong>SANITECH O₃ MED PRO MOBILE</Strong> é leve e de tamanho reduzido, <Strong>transportável para atendimento residencial e outros,</Strong> de fácil instalação, baixa manutenção, capaz de produzir gás ozônio (O₃) diversas concentrações como é necessário no uso terapêutico.</p>
                <p className="modal-desc">A ozonioterapia é um procedimento que consiste na aplicação dos gases oxigênio e o ozônio (O₃) por diversas vias, como intravenosa ou intramuscular, com objetivo terapêutico.</p>
                <p className="modal-desc"><Strong>A ozonioterapia é eficaz contra mais de duzentos tipos diferentes de doenças:</Strong></p>
                <p className="modal-desc">Câncer, problemas de circulação, doenças virais, inflamações crônicas intestinais, hepatite e herpes, feridas de origem vascular, arterial ou venosas, úlceras diabéticas e por insuficiência arterial, queimaduras de diversos tipos, hérnias de disco, protrusão discal e dores lombares e no sistema imunológico.</p>
              </>
            }
          />
        </div>

        <div className="card" id="18">
          <div className="card-image">
            <img style={{ width: '110px' }} alt="imagem produto" src={VetMobile} />
          </div>
          <h3>SANITECH O₃ VET PRO MOBILE OZONIOTERAPIA</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ VET PRO MOBILE OZONIOTERAPIA"
            descricao={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ VET PRO MOBILE OZONIOTERAPIA</Strong> para uso na <Strong>ozonioterapia veterinária,</Strong> com a tecnologia do gás de ozônio (O₃) e acessórios, tem como objetivo o de facilitar o trabalho do profissional no uso terapêutico da ozonioterapia. Possibilitando desempenhar funções importantes da ozonioterapia dentro do consultório e no campo. Por ser leve, portátil e equipado com baterias de litium que permitem o uso do equipamento em locais sem energia elétrica.</p>
              </>
            }
            func="APLICABILIDADE DO EQUIPAMENTO SANITECH O₃ VET PRO MOBILE OZONIOTERAPIA"
            funcionamento={
              <>
                <p className="modal-desc">O <Strong>EQUIPAMENTO SANITECH O₃ VET PRO MOBILE OZONIOTERAPIA,</Strong> teve sua demanda desenvolvida e planejada para atender as veterinárias, haras de cavalos, canil, gatis, Centro de Zoonoses, currais, chiqueiros e outros, para dar suporte às atividades diárias.</p>
                <p className="modal-desc">O desenvolvimento e a inovação de um equipamento <Strong>ecologicamente correto,</Strong> para uma solução alternativa de terapia veterinária, que consiste em administrar o ozônio (O₃) no corpo animal, um gás composto por 3 átomos de oxigênio o (O₃), ajudando a melhorar a oxigenação dos tecidos, aumentando a resposta do sistema imunológico a doenças infecciosas e no alívio de dores crônicas.</p>
                <p className="modal-desc">O equipamento <Strong>SANITECH O₃ VET PRO MOBILE OZONIOTERAPIA</Strong> é leve e de tamanho reduzido, <Strong>transportável para o uso no campo,</Strong> de fácil instalação, baixa manutenção, capaz de produzir gás ozônio (O₃) concentrações como é necessário no uso terapêutico.</p>
                <p className="modal-desc">A ozonioterapia é um procedimento que consiste na aplicação dos gases oxigênio e o ozônio (O₃) por diversas vias, como intravenosa ou intramuscular, com objetivo terapêutico.</p>
                <p className="modal-desc"><Strong>A ozonioterapia é eficaz contra mais de duzentos tipos diferentes de doenças:</Strong></p>
                <p className="modal-desc">Câncer, doenças virais, inflamações crônicas, feridas de origem vascular, arterial ou venosas, úlceras diabéticas e por insuficiência arterial, queimaduras de diversos tipos e no sistema imunológico.</p>
              </>
            }
          />
        </div>

        <div className="card" id="19">
          <div className="card-image">
            <img style={{ width: '50px' }} alt="imagem produto" src={KitBag} />
          </div>
          <h3>KIT SANITECH O₃ OZONIOTERAPIA</h3>
          <ModalProduct
            desc="KIT SANITECH O₃ OZONIOTERAPIA"
            descricao={
              <>
                <p>O <Strong>KIT SANITECH O₃ OZONIOTERAPIA</Strong> foi desenvolvido com
                  propósito de ser utilizado nos procedimentos externos do corpo,
                  utilizando a tecnologia do gás de ozônio (O₃) e acessórios, tem como
                  objetivo a aplicação do ozônio (O₃) na descontaminação ou cicatrização
                  de feridas, queimaduras, fungos e outros de processos infecciosos por
                  bactérias, vírus, germes, fungos, microrganismos e estimulando o
                  sistema imunológico.</p>
              </>
            }
            func="APLICABILIDADE DO KIT SANITECH O₃ OZONIOTERAPIA"
            funcionamento={
              <>
                <p>O <Strong>KIT SANITECH O₃ OZONIOTERAPIA,</Strong> teve sua demanda
                  criada para auxiliar aos profissionais da área médica e veterinária na
                  aplicação do ozônio O₃.</p>
                <p>O <Strong>KIT SANITECH O₃ OZONIOTERAPIA é composta dos seguintes acessórios:</Strong></p>
                <ul>
                  <li>• 01 Embalagem com fecho zip para guardar o kit;</li>
                  <li>• 01 Tubo extensor com luer lock GC de 1,20m;</li>
                  <li>• 06 Bags tamanho: 20cmx40cm;</li>
                  <li>• 06 Bags tamanho: 35cmx45cm;</li>
                  <li>• 06 Bags tamanho: 40cmx60cm;</li>
                  <li>• 06 Bags tamanho: 31cmx88cm;</li>
                  <li>• 01 Acessório Cupping para aplicação localizada, olhos, orelhas e pequenas feridas;</li>
                  <li>• 01 Kit para aplicação em soro fisiológico / água com capacidade 500 ml max.;</li>
                  <li>• 01 Kit de seringas siliconadas 10ml / 20ml / 60ml;</li>
                  <li>• 01 Kit de 06 agulhas 21G 1” (25mm x 0,9mm);</li>
                  <li>• 06 Scalp 21G 1” (25mm x 0,9mm);</li>
                  <li>• 06 Agulhas 26G ½” (13mm x 0,45mm);</li>
                  <li>• 06 Sondas uretral 08;</li>
                  <li>• 06 Sondas traqueais 50cm.</li>
                </ul>
                <p><Strong>INDICATIVO PARA O USO DOS ACESSÓRIOS</Strong></p>
                <p><Strong>1 - Acessório bag para aplicação em membros.</Strong></p>
                <p>Escolher o tamanho do bag a ser utilizado de acordo com o membro a ser tratado.</p>
                <p>Ver instruções de uso no Guia Rápido para Ozonioterapia no site da Astech.</p>
                <p><Strong>2-Acessório cupping/cone para aplicação localizada de ozônio (O₃).</Strong></p>
                <p>Os cuppings/cones são indicados para aplicações localizadas no corpo de humanos e ou animais.</p>
                <p>Os cuppings/cones podem ser utilizados em feridas localizadas, olhos, orelhas.</p>
                <p>Ver instruções de uso no Guia Rapido para Ozonioterapia no site da Astech.</p>
                <p><Red>OBSERVAÇÃO: </Red><Strong>NUNCA UTILIZE PARA APLICAÇÃO EM NARIZ</Strong><Red>"OZÔNIO O₃ NÃO DEVE SER INALADO"</Red></p>
                <p><Strong>3-Acessório para aplicação em soro fisiológico/água com ozônio (O₃).</Strong></p>
                <p>O kit para aplicação em soro fisiológico/água- 500 ml max. é composto de um extensor luer lock e uma pedra porosa.</p>
                <p>Ver instruções de uso no Guia Rápido para Ozonioterapia no site da Astech.</p>
                <p><Strong>4-Acessórios seringas siliconadas em conjunto com agulhas, scalp e sondas para aplicações diversas de ozônio (O₃).</Strong></p>
                <ul>
                  <li>• Hemoterapia menor;</li>
                  <li>• Subcutânea;</li>
                  <li>• Sublingual;</li>
                  <li>• Retal.</li>
                </ul>
                <p>Ver instruções de uso no Guia Rápido para Ozonioterapia no site da Astech.</p>
              </>
            }
          />
        </div>

        <div className="card" id="20">
          <div className="card-image">
            <img style={{ width: '80px' }} alt="produto" src={DescUniforme} />
          </div>
          <h3>SANITECH O₃ DESCONTAMINADOR DE UNIFORMES</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ DESCONTAMINADOR DE UNIFORMES"
            descricao={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH O₃ DESCONTAMINADOR DE UNIFORMES</Strong> utiliza a tecnologia do gás de ozônio (O₃) e tem o objetivo
                  de eliminar quaisquer tipos de microrganismos por bactérias, vírus,
                  germes e fungos, reduzindo os riscos sanitários, dos uniformes,
                  melhorando a qualidade do ar ambiente, reduzindo cargas ambientais,
                  atenuando a transmissão por via indireta de microrganismos e
                  auxiliando na prevenção da contaminação cruzada por agentes
                  infecciosos.</p>
              </>
            }
            func="APLICABILIDADE DO EQUIPAMENTO SANITECH O₃
            DESCONTAMINADOR DE UNIFORMES"
            funcionamento={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH O₃ DESCONTAMINADOR DE UNIFORMES,</Strong> teve sua demanda desenvolvida e planejada para os
                  seguintes segmentos: Hospitais, clínicas, policlínicas, Instituições de
                  Longa Permanência para Idosos, maternidades, prontos-socorros,
                  consultórios dentários, veterinárias, salão de beleza, escolas, hotéis, indústrias de processamentos de alimentos, supermercados,
                  restaurantes/bares e outros para dar suporte às atividades diárias.</p>
                <p>O desenvolvimento e a inovação de um equipamento <Strong>ecologicamente
                  correto,</Strong> para uma solução alternativa <Strong>aos saneantes químicos,</Strong>
                  trazendo mais proteção e segurança aos profissionais da saúde,
                  funcionários, colaboradores e usuários que estejam em ambientes que
                  requerem cuidados.</p>
                <p>Apesar da proteção diária em ambientes críticos e semi-críticos, já foi
                  demonstrado que a contaminação bacteriana e viral retorna e se espalha
                  rapidamente por contaminação cruzada <Strong>e por aerossolização.</Strong></p>
                <p>O processo de sanitização dos uniformes deverá ser de
                  aproximadamente 05 (cinco) minutos, após o acionamento do botão que
                  dá início ao ciclo de desinfecção.</p>
                <p>O sistema é seguro e vem equipado com luzes indicadoras de
                  funcionamento, <Red>(VERMELHO)</Red> durante o ciclo de desinfecção, <Green>(VERDE) </Green>
                  ao final da operação de dissipação de ozônio por um filtro de carvão
                  ativado, indicando que é seguro abrir a porta para retirada dos
                  uniformes.</p>
                <p>O equipamento é compreendido por uma estrutura em forma de cabine
                  telefônica, alumínio e/ou material similar, de alta resistência a produtos
                  químicos, cuja estrutura em forma de vidros nas extremidades,
                  promovendo facilidade para remoção da sujidade.</p>
                <p><Red>OBS.: SERÁ ENVIADO O PROJETO DA CABINE PARA FABRICAÇÃO
                  LOCAL. NÃO FORNECEMOS A CABINE POR MOTIVOS DE
                  TRANSPORTE DOS VIDROS.</Red></p>
              </>
            }
          />
        </div>

        <div className="card" id="21">
          <div className="card-image">
            <img style={{ width: '150px' }} alt="produto" src={AtomizadorCostalImage} />
          </div>
          <h3>EQUIPAMENTO SANITECH O₃ ATOMIZADOR COSTAL</h3>
          <ModalProduct
            desc="EQUIPAMENTO SANITECH O₃ ATOMIZADOR COSTAL"
            descricao={
              <>
                <p>O <Strong>EQUIPAMENTO EQUIPAMENTO SANITECH O₃ ATOMIZADOR COSTAL</Strong> utiliza a tecnologia do gás de ozônio (O₃) e acessórios para água, proporcionando a utilização da água ozonizada nas aplicações de lavar, enxaguar superfícies, reduz a quantidade de saneantes químicos na diluição, porque a água ozonizada potencializa trazendo mais proteção e segurança aos profissionais da limpeza que respiram os saneantes químicos mais fortes e que estejam em ambientes que requerem cuidados."</p>
                <p>Na Agricultura Familiar pode ser aplicado nas hortas, pomares e outros, utilizando água + 5% de agroleo ozonizado. O agroleo ozonizado é um adjuvante para os agrotóxicos e mais eficaz.</p>
                <p>Não usamos agrotóxicos, apenas agroleo ozonizado ecologicamente correto e não precisa usar EPIs, por não ser toxico.</p>
              </>
            }
            func="APLICABILIDADE DO EQUIPAMENTO SANITECH O₃ ATOMIZADOR COSTAL"
            funcionamento={
              <>
                <p>O <Strong>EQUIPAMENTO SANITECH O₃ ATOMIZADOR COSTAL,</Strong> teve sua demanda desenvolvida e planejada para atender as escolas, hospitais, clínicas, consultórios dentários, hotéis, indústrias de processamentos de alimentos, supermercados (açougue, peixaria, laticínios e flvs) restaurantes/bares, banheiros públicos, veterinárias, haras de cavalos, canil, gatis, Centro de Zoonoses, currais, chiqueiros, <strong>Agricultura Familiar</strong> e outros, para dar suporte às atividades diárias.</p>
                <p>O desenvolvimento e a inovação de um equipamento <strong>ecologicamente correto,</strong> para uma solução alternativa na limpeza diária de superfícies de alto contato, como pisos, já foi demonstrado que a contaminação bacteriana e viral retorna e se espalha rapidamente por contaminação cruzada <strong>e por aerossolização.</strong></p>
                <p>As lavagens frequentes ou a utilização de desinfetantes para baixar o nível de contaminação bacteriana que está presente nas superfícies a serem limpas, não pode ser considerado útil na prevenção de disseminação de infecções, por não ser um processo prático e não conseguir eliminar a fonte de transporte dos patógenos.</p>
                <p>Nos diversos segmentos acima, podemos considerar o uso da água ozonizada na higienização dos alimentos, na sanitização das mãos dos profissionais, no vazio sanitário das indústrias de alimentos, após limpeza e desinfecção, no enxague de superfícies, na potencialização e diluição de produtos químicos e outros.</p>
                <p>Nos supermercados a utilização da água ozonizada na higienização das frutas, legumes e verduras (FLVS), proporciona uma sobrevida e redução de perdas após seu manuseio, eliminando as bactérias, germes e fungos, na lavagem dos pescados, açougue nas superfícies de cortes das carnes e instrumentos, laticínios idem e na higienização dos instrumentos (facas, tesouras, bandejas e outros).</p>
                <p>Nos banheiros públicos é muito utilizado nos sanitários, mictórios, no enxague de superfícies, na potencialização e diluição de produtos químicos e outros.</p>
                <p>Acreditamos que vários segmentos desconhecem o uso da água ozonizada ou não dispõem desse tipo de aparelho para sanitização de água com a tecnologia do ozônio (O₃), o qual objetiva reduzir riscos sanitários, melhorar a qualidade do trabalho dos profissionais de saúde e de outros segmentos e reduzir cargas ambientais de microrganismos, atenuando a transmissão destes por via indireta, auxiliando na prevenção da contaminação cruzada por agentes infecciosos.</p>
              </>
            }
          />
        </div>
      </div>
    </ProductComponentStyle>
  );
}

const ProductComponentStyle = styled.div`
.card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // justify-content: space-between;
  justify-content: space-around;

  .card {
    margin: 50px 0;
    width: 400px; 
    height: 350px;
    text-align: center;
    padding: 30px;
    box-shadow: 0rem 0 1rem rgba(0, 0, 0, 0.109);
    border-radius: 16px;

    h3 {
      font-size: 1rem;
      height: 60px;
    }

    .card-image {
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100px;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: 1501px) and (max-width: 3500px) {
  
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .card-container {
    .card {
      width: 300px;
      margin: 40px auto;
    }
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {

}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  .card-container {
    .card {
      width: 300px;
      margin: 40px auto;
    }
  }
}

@media screen and (max-width: 800px) {
  .card-container {
    .card {
      margin: 40px auto;
    }
  }
}
`;

const Strong = styled.strong`
  color: #444;
`
const Strong2 = styled.strong`
  color: #F94A29;
`
const Red = styled.strong`
  color: #F94A29 !important;
`
const Green = styled.strong`
  color: #588157 !important;
`

export default ProductComponent;
