

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

function Plano() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Brasil lança plano contra superbactérias e hospitais racionalizam antibióticos</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        subtitulo="Brasil lança plano contra superbactérias e hospitais racionalizam antibióticos"
                        data="10/01/2019"
                    />
                    <p>SÃO PAULO, SP (FOLHAPRESS) – A guerra contra as bactérias e outros micro-organismos está reunindo hospitais, médicos, governo e indústria farmacêutica na mesma trincheira. Com alguns micróbios capazes de sobreviver aos antibióticos mais poderosos já criados, o medo é que as infecções causadas por eles matem milhões de pessoas nas próximas décadas.
                    O problema já assombra o presente. De acordo com estimativas do governo britânico, 700 mil pessoas morrem anualmente por causa de infecções e, entre 2015 e 2050, o número de mortos em decorrência apenas de infecções por supermicróbios pode chegar a 2,4 milhões, segundo uma estimativa da OCDE (Organização para a Cooperação e Desenvolvimento Econômico).
                    O custo para lidar com esse cenário um tanto catastrófico também seria enorme, de cerca de EUR 3,5 bilhões (R$ 14,9 bilhões) anuais em média para cada país membro da organização.</p>
                    <p>Uma estimativa americana aponta que de 20% a 50% dos tratamentos com antibióticos naquele país ou são incorretos ou são desnecessários -ou seja, o remédio é usado para casos mais simples, que não precisariam de antibiótico, ou o remédio errado é usado para tratar determinada infecção.
                    No Brasil ainda há poucos dados a respeito do tema. A Anvisa monitora desde 2010 as chamadas infecções primárias de corrente sanguínea ocorridas em UTIs de 1.900 hospitais de todo o país, mas o número está longe de representar o total de pessoas acometidas pelos micróbios multirresistentes.
                    Desde a descoberta da penicilina pelo escocês Alexander Fleming, 90 anos atrás, já se sabe que existem na natureza alguns micro-organismos que são simplesmente imunes a certos antibióticos. Isso significa que, em sua maquinaria celular, esses micróbios dispõem de meios de degradar ou simplesmente se livrar dessas moléculas agressoras.
                    O problema ganha dimensão se levarmos em conta que essas estratégias de resistência podem ser copiadas e compartilhadas entre as bactérias, gerando organismos multirresistentes.
                    No fim das contas, os antibióticos, quando mal empregados, destroem as bactérias “mais ou menos”. Sobram apenas aquelas extremamente hábeis e capazes de gerar infecções dificílimas ou até impossíveis de combater com as ferramentas farmacológicas atuais.</p>
                    <p>A conclusão a que diversos setores chegaram é que é necessário racionalizar o uso das drogas.
                    Um exemplo já em prática é o da Santa Casa de Santos, que por ser referência da região em casos complexos, tem alto consumo de antibióticos. É sabido, porém, que o ambiente hospitalar é um dos mais favoráveis para as infecções multirresistentes, graças à confluência de doentes.
                    Uma estratégia adotada no hospital tentou reduzir o tempo de internação dos pacientes. “Eles acabavam ficando muito tempo internados só para fazerem uso do antibiótico, mas o ambiente hospitalar é de risco. A gente sabe que uma pessoa corre mais risco de vida ao entrar num hospital do que ao fazer uma viagem de avião”, afirma a farmacêutica Priscilla Sartori, responsável pelo programa de gerenciamento de uso de antibióticos (ou “stewardship”, no jargão em inglês) do hospital.
                    A prefeitura da cidade é parceira da iniciativa, aplicando a medicação a domicílio quando necessário. Em alguns casos, o paciente pôde ficar 80 dias a menos no hospital, relata Sartori. Mas há cuidados a serem tomados: “Alta hospitalar não é alta médica. A desospitalização tem de ser feita com qualidade. Se isso acontece, a recuperação no ambiente domiciliar é muito mais rápida.”
                    Como saldo da iniciativa, que teve início em 2017, mais de mil dias de UTI foram economizados, abrindo vagas para quem mais precisava.</p>
                    <p>Um dos desafios na hora de fazer a gestão de antibióticos é a especificidade de cada país e hospital. Ana Gales, coordenadora do Comitê de Resistência Antimicrobiana da Sociedade Brasileira de Infectologia e professora da Unifesp, diz que iniciativas isoladas existem há algumas décadas, mas que só há poucos anos começaram a surgir esforços mais coordenados, especialmente na Europa, nos EUA e em outros países como Canadá e Austrália.
                    “Por aqui eu tenho que informar ao corpo clínico a realidade microbiológica deste hospital, qual é a melhor opção terapêutica é melhor para os pacientes daqui, que é diferente do Hospital Oswaldo Cruz, do Hospital das Clínicas da USP e mais diferente ainda de qualquer hospital dos EUA”, explica o infectologista Pedro Mathiasi, que há três anos coordena o programa de stewardship no HCor, em São Paulo.</p>
                    <p>Ele explica que a cada seis meses ou um ano é preciso fazer um inventário dos patógenos presentes no hospital e descobrir o perfil de resistência deles. Assim é possível descobrir dados valiosos como o fato de 25% das bactérias dali serem resistentes ao antibiótico quinolona -droga muito usada para tratar infecções urinárias. Dessa forma é possível escolher outra droga mais adequada para resolver o problema.</p>
                    <p>O uso de antibióticos no hospital caiu cerca de 20%; também houve queda no emprego de antifúngicos, de 25%. Na UTI pediátrica a redução chegou a 60%. Além do custo reduzido, há menor chance de efeitos colaterais para quem é tratado dessa forma mais racional.
                    Tanto a Santa Casa de Santos quanto o HCor contaram com o apoio da farmacêutica MSD, que tem auxiliado hospitais a implementarem seus programas de stewardship, inclusive apoiando a montagem de laboratórios de microbiologia –onde os patógenos podem ser identificados, assim como a quais antibióticos eles são resistentes.
                    Depois de algumas iniciativas setoriais, no último mês de dezembro o Brasil lançou um programa multiministerial para lidar com o tema (ministérios do Meio Ambiente, da Saúde, da Agricultura e Ciência e Tecnologia, entre outros órgãos), delineando uma estratégia que abrange desde a educação dos profissionais de saúde, a promoção de higiene humana e animal e a ação de vigilância sanitária para garantir que as diretrizes serão cumpridas.</p>
                    <p>Também em dezembro, a Anvisa publicou regras de como interpretar testes de sensibilidade a antibióticos -cruciais para a escolha do melhor tratamento . A orientação é usar a estratégia mais branda possível capaz de matar os micro-organismos.
                    Outra medida, já em vigor, é a retenção de receita na farmácia a fim de coibir o uso indiscriminado de antibióticos.
                    Um dos aspectos mais ignorados da questão, conta Ana Gales, é o uso de antibióticos em animais de corte. “Os animais engordam mais e têm menos infecções. Esse uso incorreto na ração, para promoção de crescimento, é pior porque ele se dá em subdose, favorecendo ainda mais a seleção dessas bactérias resistentes.”</p>
                    <p>A infectologista conta que algumas medidas simples podem ser empregadas pela população, a fim de evitar infecções por organismos multirresistentes. A primeira é evitar idas desnecessárias aos hospitais. Se precisar ir, o recomendável é lavar muito bem as mãos.
                    Outra recomendação que anda esquecida é se vacinar. Adultos também têm que manter a carteirinha de vacinação em dia a fim de evitar doenças como tétano, pneumonia e febre amarela.
                    A descoberta de alguns dos principais antibióticos:</p>
                    <p>– 1909: Salvarsan (Arsfenamina): Um composto sintético, foi capaz de tratar a sífilis com eficácia pela primeira vez</p>
                    <p>– 1928: Penicilina: Primeiro antibiótico de amplo espectro a tratar infecções sérias de estreptococos e estafilococos</p>
                    <p>– 1943: Streptomicina: Marcou o início da era de ouro dos antibióticos, foi o primeiro tratamento para tuberculos</p>
                    <p>– 1953: Vancomicina: Um dos antibióticos com eficácia mais duradoura; bactérias resistentes só surgiram depois de três décadas</p>
                    <p>– 1962: Quinolonas: Usado em alguns casos de infecção urinária, podem ter efeitos debilitantes graves</p>
                    <p>– 1976: Carbapenemas: Ainda relativamente bem eficazes, são os último recurso para tratar muitas infecções</p>
                    <p>– 1987: Daptomicina: Uma das últimas classes de antibióticos descoberta</p>
                    <a href="https://www.bemparana.com.br/noticia/brasil-lanca-plano-contra-superbacterias-e-hospitais-racionalizam-antibioticos#.YbdQ5L3MJPY" target="_blank" rel="noreferrer"><strong>Folhapress</strong></a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Plano