

function PageVideo(props) {
    return (
        <div className="PageVideo-news">
            <span>{ props.data }</span>
            <p>{ props.descricao }</p>
            <iframe width="560" height="315" src={ props.video } title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        
        </div>
    )
}

export default PageVideo