import { Link } from "react-router-dom";

import Header from "../../components/header";
import Footer from "../../components/footer";
import News from "../../components/news";

import ThumbOzonioterapia from "../../images/noticias/thumb-ozonioterapia.png";
import ImgEncathoExprotel from "../../images/noticias/encatho-exprotel.png";
import ImgNotaAboz from "../../images/noticias/NOTA-ABOZ.png";
import ImgExpotel2018 from "../../images/noticias/expotel2018.jpg";
import ImgClassicShow from "../../images/noticias/br-classic-show.jpg";
import ThumbBag from "../../images/noticias/thumb-bag.png";
import ImgInfecHosp from "../../images/noticias/infec-hosp.jpg";
import ImgSuperbacterias from "../../images/noticias/superbacterias.jpg";
import Imgbacteria from "../../images/noticias/bacteria.jpg";

import arrow3 from "../../images/svg/arrow3.svg";

function Pagina5() {
  return (
    <div>
      <Header title={"NOTICIAS"} />
      <h2 className="noticia-title">Notícias</h2>

      <div className="pagina-content">
        <ul>
          <li>
            <News
              img={Imgbacteria}
              titulo="“Superbactérias” resistentes poderão matar até 10 milhões em 2050"
              data="16/04/2019"
              descricao="A descoberta da penicilina, primeiro componente a fazer parte dos antibióticos, é até hoje..."
              pagina="superbacteiras-resistente-poderao-matar-milhoes"
            />
          </li>

          <li>
            <News
              img={ImgSuperbacterias}
              titulo="Brasil lança plano contra superbactérias e hospitais racionalizam antibióticos"
              data="10/01/2019"
              descricao="SÃO PAULO, SP (FOLHAPRESS) - A guerra contra as bactérias e outros micro-organismos está reunindo..."
              pagina="brasil-plano-contra-superbacterias-hospitais-racionalizam-antibioticos"
            />
          </li>
          <li>
            <News
              img={ImgInfecHosp}
              titulo="Infecção Hospitalar (negligência)"
              data="05/11/2018"
              descricao="Infecção hospitalar é qualquer infecção contraída por pacientes durante internação hospitalar..."
              pagina="infeccao-hospitalar-negligencia"
            />
          </li>
        </ul>
        <ul>
          <li>
            <News
              img={ThumbBag}
              titulo="Bag Multiuso para aplicação de ozônio"
              data="23/10/2020"
              descricao="A empresa ASTECH desenvolveu estes BAGs disponiveis em diversos tamanhos, são para proteção e..."
              pagina="bag-multiuso-aplicacao-ozonio"
            />
          </li>

          <li>
            {" "}
            <News
              img={ImgEncathoExprotel}
              titulo="Encatho & Exprotel 2018"
              data="18/07/2018"
              descricao="Promovido pela Associação Brasileira da Indústria de Hotéis de Santa Catarina- ABIH-SC, o Encontro..."
              pagina="encatho-e-exprotel"
            />
          </li>
          <li>
            {" "}
            <News
              img={ImgNotaAboz}
              titulo="ABOZ – Nota de Esclarecimento Jurídico"
              data="12/07/2028"
              descricao="Expedimos inicialmente essa nota com o fito de informar todos os associados da ABOZ quanto ao uso..."
              pagina="aboz-nota-esclarecimento-juridico"
            />
          </li>
        </ul>
        <ul>
          <li>
            <News
              img={ThumbOzonioterapia}
              titulo="#OzonioterapiaParaTodos – A Campanha"
              data="09/07/2018"
              descricao="Visando a Campanha #OzonioterapiaParaTodos, a Presidente da ABOZ, Dra..."
              pagina="ozonioterapia-campanha"
            />
          </li>

          <li>
            <News
              img={ImgExpotel2018}
              titulo="Expotel 2018"
              data="19/06/2018"
              descricao="A Astech participará da EXPOTEL – Feira Internacional para Hotelaria, que será realizada entre 25..."
              pagina="expotel-2018"
            />
          </li>
          <li>
            <News
              img={ImgClassicShow}
              titulo="Exposição de Carros antigos em Araxa"
              data="22/05/2018"
              descricao="Dois anos custaram a passar, mas o Brazil Classics Show chegou! Os mais charmosos veículos antigos..."
              pagina="exposicao-carros-antigos-araxa"
            />
          </li>
        </ul>

        <Link to="/noticias/pagina-4">
          <img className="icon" src={arrow3} alt="icone proximo" />
        </Link>
      </div>

      <Footer />
    </div>
  );
}

export default Pagina5;
