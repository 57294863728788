import styled from 'styled-components'

function Page(props) {
    return (
        <PageStyled>
            <span>{ props.data }</span>
            <img src={ props.img } alt="noticia" />
            <p>{ props.descricao }</p>
        </PageStyled>
    )
}

const PageStyled = styled.div`
    img {
        text-align: center;
        margin: 4vh auto;
        display: block;
    }
`

export default Page