import Nav from '../components/nav'
import styled from 'styled-components'

import bg from '../images/banner2.png'
import slogan from '../images/identidade/slogan.svg'

function Home() {
    return (
        <div>
            <Nav />
            
            <HomeStyled>
                <div className="home">
                    <div className="home-container">
                        <div className="home-left">
                            <h1>Preserve seu bem-estar utilizando nossos equipamentos SANITECH (O₃)</h1>
                            <p>Somos uma empresa nacional, de base tecnológica, desenvolvendo soluções inovadoras.</p>
                        </div>

                        <div className="home-right">
                            <img src={slogan} alt="slogan: Soluções para higienização e sanitização" />
                        </div>
                    </div>
                    <h2>Acreditamos que para fazer a diferença é preciso paixão</h2>
                </div>
            </HomeStyled>
        </div>
    )
}

const HomeStyled = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 100%;

    button {
        margin: 4vh 0;
        display: flex;
        align-items: center;
        
        .cart {
            margin-left: 1vw;
        }
    }

    @media screen and (min-width: 1501px) and (max-width: 3500px) {
        .home {
            padding: 16vh 6vw;

            .home-container {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .home-left {
                    h1, p, h2 {
                        color: var(--white-color) !important;
                    }

                    h1 {
                        font-size: 1.8rem;
                    }

                    p {
                        margin-top: 2vh;
                        font-weight: 300;
                    }

                    .home-button {
                        padding-top: 4vh;
                        button {
                            padding: 12px 20px;
                        }
                    }
                }

                .home-right {
                    img {
                        float: right;
                        width: 100%;
                        height: auto;
                    }
                }
            }

            h2 {
                text-align: center;
                font-size: 1.5rem;
                color: var(--white-color) !important;
                margin-top: 12vh;
            }
        }
    }

    @media screen and (min-width: 1201px) and (max-width: 1500px) {
        .home {
            padding: 25vh 6vw;

            .home-container {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .home-left {
                    h1, p, h2 {
                        color: var(--white-color) !important;
                    }

                    h1 {
                        font-size: 1.6rem;
                    }

                    p {
                        margin-top: 2vh;
                        font-weight: 300;
                    }

                    .home-button {
                        padding-top: 4vh;
                        button {
                            padding: 12px 20px;
                        }
                    }
                }

                .home-right {
                    img {
                        float: right;
                        width: 80%;
                        height: auto;
                    }
                }
            }

            h2 {
                text-align: center;
                font-size: 1.4rem;
                color: var(--white-color) !important;
                margin-top: 15vh;
            }
        }
    }

    @media screen and (min-width: 1001px) and (max-width: 1200px) {
        .home {
            padding: 25vh 6vw;

            .home-container {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .home-left {
                    h1, p, h2 {
                        color: var(--white-color) !important;
                    }

                    h1 {
                        font-size: 1.4rem;
                    }

                    p {
                        margin-top: 2vh;
                        font-weight: 300;
                    }

                    .home-button {
                        padding-top: 4vh;
                        button {
                            padding: 12px 20px;
                        }
                    }
                }

                .home-right {
                    img {
                        float: right;
                        width: 70%;
                        height: auto;
                    }
                }
            }

            h2 {
                text-align: center;
                font-size: 1.2rem;
                color: var(--white-color) !important;
                margin-top: 15vh;
            }
        }
    }

    @media screen and (min-width: 801px) and (max-width: 1000px) {
        .home {
            padding: 30vh 4vw;
            text-align: center;
            
            h1, h2, p {
                color: var(--white-color) !important;
                text-align: center;
            }

            h1 {
                max-width: 30ch;
                margin: 0 auto;
                font-size: 1.6rem;
            }

            p {
                font-size: 1rem;
                margin: 5vh 0;
            }

            h2 {
                font-size: 1.2rem;
                margin: 4vh auto 0 auto;
            }

            button {
                margin: 0 auto;
            }

            .home-right {
                img {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 800px) {
        .home {
            padding: 35vh 4vw;
            text-align: center;
            
            h1, h2, p {
                color: var(--white-color) !important;
                text-align: center;
            }

            h1 {
                font-size: 1.2rem;
            }

            p {
                font-size: 1rem;
                margin: 5vh 0;
            }

            h2 {
                font-size: 1.1rem;
                margin: 15vh auto 0 auto;
                max-width: 30ch;
            }

            button {
                margin: 4vh auto;
            }

            .home-right {
                img {
                    display: none;
                }
            }
        }
    }
`

export default Home