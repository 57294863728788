import styled from 'styled-components'

import Header from '../components/header'
import Footer from '../components/footer'

import bemEstar from '../images/svg/bem-estar.svg'
import conforto from '../images/svg/conforto.svg'
import saude from '../images/svg/saude.svg'
import seguranca from '../images/svg/seguranca.svg'
import precaucao from '../images/svg/precaucao.svg'
import prevencao from '../images/svg/prevencao.svg'

import certificado from '../images/certificado.png'

function Beneficios() {
    return (
        <div>
            <Header />
            <BeneficiosStyled className="body">
                <h1 className="title">Benefícios</h1>

                <h2 className="title-d">Benefícios da Nossa Linha de Produtos</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>
                <div className="container-card">
                    <div className="card">
                        <img className="icon-beneficio" alt="icon-beneficio" src={bemEstar} />
                        <h3>Bem-estar</h3>
                        <p>Previne a Síndrome do Edifício Doente, evitando a contaminação cruzada por bactérias, vírus, germes, fungos, ácaros e outros. Estes impulsionados por aerossóis da fala, espirro ou tosse.</p>
                    </div>

                    <div className="card">
                        <img className="icon-beneficio" alt="icon-beneficio" src={conforto} />
                        <h3>Conforto</h3>
                        <p>Elimina odores indesejáveis do ar-condicionado e do ambiente provocados por mofo, bolor, cigarro e animais de estimação, sem produzir ruídos.</p>
                    </div>

                    <div className="card">
                        <img className="icon-beneficio" alt="icon-beneficio" src={saude} />
                        <h3>Saúde</h3>
                        <p>Mitiga a contaminação cruzada pelos vírus da família do SARS (Síndrome Respiratória Aguda Grave) no ar ambiente e reduzir as taxas de absenteísmo.</p>
                    </div>

                    <div className="card">
                        <img className="icon-beneficio" alt="icon-beneficio" src={seguranca} />
                        <h3>Segurança</h3>
                        <p>Permite que o motorista feche os vidros e ligue o ar-condicionado sem desconforto para os passageiros.</p>
                    </div>

                    <div className="card">
                        <img className="icon-beneficio" alt="icon-beneficio" src={precaucao} />
                        <h3>Precaução</h3>
                        <p>Evita a propagação das principais doenças respiratórias alérgicas, como asma, bronquite, rinite, sinusite, entre outras. Purificando e esterilizando o ar ambiente.</p>
                    </div>

                    <div className="card">
                        <img className="icon-beneficio" alt="icon-beneficio" src={prevencao} />
                        <h3>Prevenção</h3>
                        <p>Espanta os insetos em ambientes fechados, como a picada dos mosquitos da dengue, chikungunya e zika, transmitindo o vírus.</p>
                    </div>
                </div>
                {/* </Fade> */}
                {/* <Fade left> */}
                <h2 className="title-d">Nota Técnica</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>
                {/* </Fade> */}
                {/* <Fade right> */}
                <div className="descricao">
                    <p className="desc">Nossos equipamentos purificadores e esterilizadores a base de ozonio (O₃) <strong>SANITECH (O₃) LINHA PURI</strong> para ambientes habitáveis, não requrem autorização da ANVISA para registro e comercialização</p>

                    <p className="desc"><strong>NOTA TÉCNICA n° 32/2020/SEI/COSAN/GHCOS/DIRE3/ANVISA, Processo n° 25000.038041/2020-51</strong></p>

                    <p className="desc">Os nossos equipamentos, não se enquadram na definição de produtos médicos, conforme lista de produtos não regulados que constam na Categoria 3 do subitem 1.2 e 1.3 abaixo.<br /><strong><a href="https://portal.anvisa.gov.br/registros-e-autorizacoes/produtos-para-a-saude/produtos-nao-regulados" target="_blank" rel="noreferrer">http://portal.anvisa.gov.br/registros-e-autorizacoes/produtos-para-a-saude/produtos-nao-regulados</a></strong></p>

                    <p className="desc">Os equipamentos de baixa concentração de ozonio (O₃) <strong>SANITECH (O₃) LINHA PURI</strong>, estão de acordo com a <strong>NR-15-anexo 11 do Ministério do Trabalho garantindo a quantidade de 0,02 a 0,08 PPM no ar ambiente.</strong></p>

                    <h3 className="patente">Nosso diferencial da concorrência - Patentes</h3>

                    <p>Pedido Nacional de Invenção, Modelo de Utilidade, Certificado de Adição de Invenção dos nossos equipamentos da <strong>LINHA PURI SANITECH O3</strong>. Patente depositado <strong>n° BR 20 2021009339 6.</strong></p>

                    <div className="certificado">
                        <div className="certificado-left">
                            <h3><strong>Conquistamos um feito inédito para nosso país.</strong></h3>
                            <p className="desc">O reconhecimento a uma Revista Americana (MDPI) do trabalho de pesquisas realizado pela Drª. Karyne Rangel e sua equipe da FIOCRUZ, que enviaram o trabalho (caderno de análise), de pesquisas em microrganismos para uma avaliação e publicação.<br />O resultado veio através do <strong>Certificado de Aceitação do Manuscrito dos Microrganismo no Efeito Prejudicial do Ozônio em Bactérias Patogênicas.</strong></p>
                        </div>
                        <div className="certificado-right">
                            <img alt="certificado" src={certificado} />
                        </div>
                    </div>

                    <p className="desc">Esse trabalho nos colocou em um patamar de empresa séria e comprometida com a Normas Brasilerias sober o ozonio (O₃) em baixa concentração em ambientes habitáveis.</p>

                    <p className="desc">Estamos buscando junto a ANVISA a regulamentação do ozonio (O₃) em ambientes habitáveis através de uma <strong>RDC - Regulamentação de Diretoria Colegiada.</strong></p>

                    <p className="desc"><strong>A conclusão é a falta de metodologia para chegarmos numa RDC.</strong></p>

                    <p className="desc">No trabalho de pesquisa da Drª Karyne, foi preciso escrever uma metodologia para chegar no efeito prejudicial do ozonio (O₃) em bactérias patogênicas e esse trabalho foi enviado ao <strong>Ministério da Saúde e a ANVISA</strong></p>

                    <p className="desc">Tivemos uma resposta de <strong>MANIFESTAÇÃO FAVORÁVEL</strong> quanto à prestação da empresa ASTECH em obter uma oportunidade para apresentar e testar seus equipamentos no âmbito da Assistência à Saúde com o objetivo da comprovação efetiva de sua eficácia através da:</p>

                    <p className="desc"><strong>NT.n°34/2022/SEI/COSAN/GHCOS/DIRE3/ANVISA, Processo n° 25000.038041/2020-51.</strong></p>
                </div>
            </BeneficiosStyled>
            <Footer />
        </div>
    )
}

const BeneficiosStyled = styled.div`
    .patente {
        text-align: left;
        padding: 40px 20px 0 32px;
    }
	@media screen and (min-width: 1501px) and (max-width: 3500px) {
        .container-card {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            .card {
                margin: 8vh 0;
                padding: 9px;
                box-shadow: 0rem 0 1rem rgba(0, 0, 0, 0.109);
                transition: 0.4s ease-out;
                position: relative;
                width: 40vw;
                height: 50vh;
                border-radius: 16px;

                img {
                    height: 50px;
                    width: 50px;
                    margin-top: 4vh;
                }

                h3 {
                    font-size: 1rem;
                    margin: 2vh 0;
                }

                p {
                    max-width: 30ch;
                    line-height: 26px;
                    font-size: 0.7rem;
                }


                :hover {
                    transform: translateY(-50px);
                    transition: 0.4s ease-out;
                }

                :hover ~ .card {
                    position: relative;
                    left: 1vw;
                    transition: 0.4s ease-out;
                }
            }
        }

        .descricao {
            text-align: center;
            p {
                font-size: 1rem;
                padding: 1vh 2vw !important;
                text-align: justify;
            }

            .certificado {
                display: flex;
                justify-content: center;
                align-items: center;

                .certificado-right {
                    img {
                        width: 600px;
                        height: auto;
                        border-radius: 16px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

	@media screen and (min-width: 1201px) and (max-width: 1500px) {
        .container-card {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            .card {
                margin: 8vh 0;
                padding: 9px;
                box-shadow: 0rem 0 1rem rgba(0, 0, 0, 0.109);
                transition: 0.4s ease-out;
                position: relative;
                width: 40vw;
                height: 50vh;
                border-radius: 16px;

                img {
                    height: 40px;
                    width: 40px;
                    margin-top: 2vh;
                }

                h3 {
                    font-size: 0.9rem;
                    margin: 2vh 0;
                }

                p {
                    max-width: 30ch;
                    line-height: 24px;
                    font-size: 0.7rem;
                }


                :hover {
                    transform: translateY(-50px);
                    transition: 0.4s ease-out;
                }

                :hover ~ .card {
                    position: relative;
                    left: 1vw;
                    transition: 0.4s ease-out;
                }
            }
        }

        .descricao {
            text-align: center;
            p {
                font-size: 1rem;
                padding: 1vh 2vw !important;
                text-align: justify;
            }

            .certificado {
                display: flex;
                justify-content: center;
                align-items: center;

                .certificado-right {
                    img {
                        width: 500px;
                        height: auto;
                        border-radius: 16px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

	@media screen and (min-width: 1001px) and (max-width: 1200px) {        
        .container-card {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            .card {
                margin: 6vh 0;
                padding: 9px;
                box-shadow: 0rem 0 1rem rgba(0, 0, 0, 0.109);
                transition: 0.4s ease-out;
                position: relative;
                width: 35vw;
                height: 50vh;
                border-radius: 16px;

                img {
                    height: 30px;
                    width: 30px;
                    margin-top: 2vh;
                }

                h3 {
                    font-size: 0.8rem;
                    margin: 2vh 0;
                }

                p {
                    max-width: 30ch;
                    line-height: 24px;
                    font-size: 0.6rem;
                }


                :hover {
                    transform: translateY(-50px);
                    transition: 0.4s ease-out;
                }

                :hover ~ .card {
                    position: relative;
                    left: 1vw;
                    transition: 0.4s ease-out;
                }
            }
        }

        .descricao {
            text-align: center;
            p {
                font-size: 1rem;
                padding: 1vh 2vw !important;
                text-align: justify;
            }

            img {
                margin: 4vh 0;
                width: 640px;
                height: auto;
            }
        }
    }

	@media screen and (min-width: 801px) and (max-width: 1000px) {
        .container-card {
            text-align: center;

            p {
                margin: 2vh 0 4vh 0;
                padding: 2vh 4vw;
                font-size: 1rem;
                text-align: justify;
            }

            h3 {
                font-size: 1.2rem;
            }

            .icon-beneficio {
                height: 60px;
                width: 60px;
            }
        }

        .descricao {
            text-align: center;
            p {
                padding: 2vh 4vw !important;
                text-align: justify;
            }

            img {
                margin: 4vh 0;
                width: 540px;
                height: auto;
            }
        }
    }

	@media screen and (max-width: 800px) {
        .container-card {
            text-align: center;

            p {
                margin: 2vh 0 4vh 0;
                padding: 2vh 4vw;
                font-size: 0.8rem;
                text-align: justify;
            }

            h3 {
                font-size: 1rem;
            }

            .icon-beneficio {
                height: 55px;
                width: 55px;
            }
        }

        .descricao {
            text-align: center;
            p {
                padding: 2vh 4vw !important;
                text-align: justify;
            }

            img {
                margin: 4vh 0;
                width: 340px;
                height: auto;
            }
        }
    }
`

export default Beneficios