import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import Img20200522_3 from '../../../images/noticias/2020-05-22_3.jpg'

function ComercioInveste() {
    return (
        <ComercioStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"}/>
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Comércio investe em máscara, álcool em gel e túnel de descontaminação de ozônio</h1>
                    <div className='paginas-noticias-content comercio-investe'>
                        <Page
                            subtitulo="Comércio investe em máscara, álcool em gel e túnel de descontaminação de ozônio"
                            data="22/05/2020"
                            descricao="RIO – Depois do álcool em gel, das máscaras, óculos de segurança e luvas, outro “acessório” tende a ganhar força Brasil afora. Em Petrópolis, na Região Serrana do Rio de Janeiro, um fabricante de purificador de ar e uma empresa de balões infláveis se uniram para produzir túneis de descontaminação.
                        O objetivo da criação é mitigar a proliferação do coronavírus e ajudar o comércio na adaptação do processo de abertura da economia, ainda sem data para ocorrer. Segundo especialistas, porém, a prática não é eficaz no combate ao coronavírus.
                        Embora tenha sido criada há apenas um mês, o empreendedor Guilherme Costa, diretor comercial da Astech, fabricante do purificador de ar, diz que já foram vendidos cem túneis para salões de beleza, academias e restaurantes para o Brasil inteiro.
                        — Temos capacidade para produzir oito produtos por dia. Nosso objetivo é elevar a nossa capacidade para doze equipamentos diários. Agora, estamos desenvolvendo uma cabine de descontaminação, que ocupa metade do espaço de dois metros do túnel.
                        O infectologista Renato Kfouri, membro do Comitê Técnico Assessor do Programa Nacional de Imunizações (PNI) do Ministério da Saúde e presidente do departamento de imunizações da sociedade brasileira de pediatria, diz que o ozônio tem um uso cosmético e não é a melhor forma de combater a pandemia.
                        — O coronavírus é um vírus sensível à agua e sabão, ao detergente e ao álcool. E alguns dados mostram que o ozônio é capaz de esterilizar ambientes não só contra o coronavírus, mas também contra outros vírus e bactérias. Por isso, alguns locais usam túnel com vapor de ozônio para eliminar vírus e bactérias nas roupas. Mas isso dura enquanto você está ali dentro. O efeito é fugaz e depois você está se expondo do mesmo jeito. Isso não esteriliza o indivíduo infectado. As pessoas doentes e infectadas com o vírus não ficam descontaminadas. A fonte de contaminação é o ser humano. Não há evidência que você melhora a imunidade da pessoa. É algo mais cosmético. Não é assim que se controla uma epidemia — afirma Kfrouri.
                        Sem evidência científica
                        A Sociedade Brasileira de Infectologia diz que até o presente momento não há qualquer evidência científica que a ozonioterapia proteja contra a Covid-19. Uma nota técnica da Anvisa destaca que as pessoas infectadas com Covid-19 carregam o vírus principalmente nas vias respiratórias e que este é transmitido principalmente de pessoa a pessoa por gotículas respiratórias produzidas quando uma pessoa infectada tosse ou espirra ou então entra em contato com superfícies ou objetos contaminados. Por isso, destaca a Anvisa, o uso desse tipo de equipamento pode gerar “ uma falsa sensação de segurança e, desse modo, levar ao relaxamento das práticas de distanciamento social, de lavagem das mãos frequente com água e sabonete, de desinfecção de superfícies e outras medidas de prevenção”.
                        A Anvisa disse ainda que a exposição leve a moderada ao gás ozônio pode trazer irritação ocular e produzir efeitos pulmonares, como irritação brônquica. “ O ozônio pode exacerbar o comprometimento das pequenas vias aéreas de adultos fumantes. O ozônio é um gás comburente que pode acelerar fortemente a ignição e aumentar os riscos de incêndio”, destaca a nota técnica da Anvisa.
                        Mas, segundo Costa, o túnel utiliza ozônio em pequenas proporções (entre 0,02 e 0,08 partícula por milhão) que elimina vírus e bactérias presentes na pele e na roupa após uma exposição de dois minutos. Iniciativas semelhantes já são adotadas na Europa e Ásia.
                        Por usar ozônio, Costa diz que não é necessário autorização da Anvisa.
                        — Eu tive a ideia de fazer essa solução após ver que as pessoas estavam confundindo túnel de descontaminação com túnel de desinfecção, que usa produtos químicos e precisa de autorização da Anvisa e é usado em hospitais, por exemplo. É importante que as pessoas saibam a diferença. Muito empresário me liga sem saber que existem esses dois produtos — afirma Costa.
                        De olho na proteção, o comércio vem apostando na iniciativa para proteger seus clientes assim que a abertura for possível. O salão Care Ipanema, na Zona Sul do Rio, já instalou em sua entrada o túnel de descontaminação do produtor da região Serrana do Rio.
                        Segundo Ivani Werneck, dona do salão, a solução vai proteger seus funcionários e clientes. Além disso, ela lembra que o produto é ecologicamente correto, uma vez que não há emissão de gases poluentes.
                        — Esse é mais um item que vamos usar para a proteção. É preciso apostar em tudo para aumentar a prevenção. Quero estar pronta quando tudo voltar ao seu ritmo normal — disse ela, listando itens já obrigatórios na nova rotina como álcool em gel e máscaras.
                        A empresária que comanda o salão de 450 metros quadrados reclama da falta de sintonia entre o governo federal e municipal:
                        — Confesso que ficamos perdidos. Abrir como quer o presidente Jair Bolsonaro ou seguir a determinação do governo local. É muito confuso. Estamos à deriva."
                        />
                        <img src={Img20200522_3} alt="noticia thumb" />
                        <span>Fonte: </span><a href="https://oglobo.globo.com/economia/comercio-investe-em-mascara-alcool-em-gel-tunel-de-descontaminacao-de-ozonio-24440109">https://oglobo.globo.com/economia/comercio-investe-em-mascara-alcool-em-gel-tunel-de-descontaminacao-de-ozonio-24440109</a>
                    </div>
                </div>
                <Footer />
            </div>
        </ComercioStyle>
    )
}

const ComercioStyle = styled.div`
    .comercio-investe {
        p {
            text-align: justify;
        }
        
        img {
            margin: 2vh auto;
            width: 40vw;
            height: auto;
            display: block;
        }
    }
`

export default ComercioInveste