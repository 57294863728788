import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'
import Page from '../../../components/page'

import ImgNotaAboz from '../../../images/noticias/NOTA-ABOZ.png'

function Aboz() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>ABOZ - Nota de Esclarecimento Jurídico</h1>
                <div className='paginas-noticias-content aboz'>
                    <img src={ ImgNotaAboz } alt="noticia thumb" />
                    <Page
                        subtitulo="ABOZ - Nota de Esclarecimento Jurídico"
                        data="12/07/2018"
                />
                <p>Expedimos inicialmente essa nota com o fito de informar todos os associados da ABOZ quanto ao uso da OZONIOTERAPIA.
                    Atualmente está em curso na Justiça Federal do Ceará uma ação judicial que questiona a competência do Conselho Federal de Medicina – CFM, para punir qualquer profissional que faça uso da ozonioterapia.
                    Vejamos, no referido processo, deixamos claro que é competência exclusiva da UNIÃO Federal, Estados e Municípios legislar sobre procedimentos aplicados a saúde.
                    Assim estabelece a Constituição Federal de 1988:
                    Art. 24. Compete à União, aos Estados e ao Distrito Federal legislar concorrentemente sobre:
                    XII – previdência social, proteção e defesa da saúde;
                    Válido mencionar que, a inexistência de legislação federal que estabeleça especialidades para exercício da ozonioterapia, bem como a outorga de poderes conferidos no art. 7º da Lei do Ato Médico não supera disposição constitucional que indica que <trong>cabe privativamente à União legislar sobre condições para o exercício de profissões</trong>.
                    A Portaria nº 702/2018 do Ministério da Saúde incluiu a Ozonioterapia como uma das novas práticas na Política Nacional de Práticas Integrativas e Complementares, inclusive atestando:</p>
                <p><strong>OZONIOTERAPIA</strong></p>
                <p>A ozonioterapia é prática integrativa e complementar de baixo custo, segurança comprovada e reconhecida, que utiliza a aplicação de uma mistura dos gases oxigênio e ozônio, por diversas vias de administração, com finalidade terapêutica, já utilizada em vários países como Itália, Alemanha, Espanha, Portugal, Rússia, Cuba, China, entre outros, há décadas.
                    Há algum tempo, o potencial terapêutico do ozônio ganhou muita atenção através da sua forte capacidade de induzir o estresse oxidativo controlado e moderado quando administrado em doses terapêuticas precisas. A molécula de ozônio é molécula biológica, presente na natureza e produzida pelo organismo sendo que o ozônio medicinal (sempre uma mistura de ozônio e oxigênio), nos seus diversos mecanismos de ação, representa um estimulo que contribui para a melhora de diversas doenças, uma vez que pode ajudar a recuperar de forma natural a capacidade funcional do organismo humano e animal.
                    Alguns setores de saúde adotam regularmente esta prática em seus protocolos de atendimento, como a odontologia, a neurologia e a oncologia, dentre outras.
                    Conforme se verifica, em nenhum momento na malsinada reportagem veiculada pelo programa FANTÁSTICO no dia 08/07/2018, foi mencionada referida portaria, pois contraria completamente a opinião manifesta daqueles que direcionaram a mensagem expressada no referido programa televisivo.
                    A reportagem nada mais fez do que advogar para aqueles que militam contra a consolidação da Ozonioterapia.
                    Nenhum médico que aplica a Ozonioterapia, nos moldes e conforme as orientações técnicas, comete crime ou mesmo infração funcional, muito pelo contrário, presta um serviço à saúde do Brasil.</p>
                <p>São Paulo, 9 de julho de 2018.</p>
                <p><strong>PAULO ROBERTO UCHOA DO AMARAL</strong></p>
                <p>OAB/CE 6.778</p>
                <p><strong>ROBERTO WAGNER VITORINO DO AMARAL</strong></p>
                <p>OAB/CE 16.949</p>
                    <a href="https://www.aboz.org.br/noticias/nota-de-esclarecimento-juridico/83/" target="_blank" rel="noreferrer"><strong>Fonte</strong></a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Aboz