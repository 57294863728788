import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Header from '../components/header'
import Footer from '../components/footer'

import hotel from '../images/svg/hotel.svg'
import hospital from '../images/svg/hospital.svg'
import ensino from '../images/svg/ensino.svg'
import automoveis from '../images/svg/automovel.svg'
import salao from '../images/svg/salao.svg'
import veterinaria from '../images/svg/veterinaria.svg'
import escritorio from '../images/svg/escritorio.svg'
import mercado from '../images/svg/carrinho.svg'
import shopping from '../images/svg/shopping.svg'
import restaurante from '../images/svg/restaurante.svg'
import estacao from '../images/svg/rodoviaria.svg'
import industria from '../images/svg/industria.svg'
import aeroporto from '../images/svg/aeroporto.svg'
import callcenter from '../images/svg/callcenter.svg'
import agro from '../images/svg/agricultura.svg'
import frigorifico from '../images/svg/frigorifico.svg'

function Solucoes() {
    return (
        <div>
            <Header />
            <SolucoesStyled className="body">
                <h1 className="title">Soluções</h1>

                <h2 className="title-d">Nossas Soluções para o Mercado</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>

                <p className="desc">Nosso propósito é atender o mercado nos segmentos de venda e locação de equipamentos, prestação de serviços, parcerias e desenvolvimento de novas soluções utilizando sempre a tecnologia de geração de ozônio (O₃).</p>

                <h2 className="title-d">Áreas de Atuação e Aplicabilidade</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>

                <div className="container">
                    <div className="itens">
                        <div className="item">
                            <img alt="icon" src={hotel} />
                            <h3>Hóteis e Residências</h3>
                            <Link to="/beneficios/1">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={hospital} />
                            <h3>Hospitais, Clínicas e Dentistas</h3>
                            <Link to="/beneficios/2">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={ensino} />
                            <h3>Instituições de Ensino</h3>
                            <Link to="/beneficios/3">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={automoveis} />
                            <h3>Automóveis, Ônibus, Caminhões, Táxis e Aplicativos</h3>
                            <Link to="/beneficios/4">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="itens">
                        <div className="item">
                            <img alt="icon" src={salao} />
                            <h3>Salão de Beleza, Academia, SPA, Estética e Barbeiro</h3>
                            <Link to="/beneficios/5">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={veterinaria} />
                            <h3>Veterinárias e Pet Shop</h3>
                            <Link to="/beneficios/6">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={escritorio} />
                            <h3>Escritórios e Partições Públicas</h3>
                            <Link to="/beneficios/7">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={mercado} />
                            <h3>Mercados e Lojas</h3>
                            <Link to="/beneficios/8">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="itens">
                        <div className="item">
                            <img alt="icon" src={shopping} />
                            <h3>Shopping, Teatro e Cinema</h3>
                            <Link to="/beneficios/9">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={restaurante} />
                            <h3>Restaurantes e Bares</h3>
                            <Link to="/beneficios/10">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={estacao} />
                            <h3>Estações e Rodoviárias</h3>
                            <Link to="/beneficios/11">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={industria} />
                            <h3>Indústrias em Geral e de Processamento de Alimentos</h3>
                            <Link to="/beneficios/12">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="itens">
                        <div className="item">
                            <img alt="icon" src={aeroporto} />
                            <h3>Aeroportos</h3>
                            <Link to="/beneficios/13">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={callcenter} />
                            <h3>Call Center e Pavilhão de Exposições</h3>
                            <Link to="/beneficios/14">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={agro} />
                            <h3>Agricultura, Pecuária, Avicultura e Psicultura</h3>
                            <Link to="/beneficios/15">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>

                        <div className="item">
                            <img alt="icon" src={frigorifico} />
                            <h3>Frigoríficos e Latícinios</h3>
                            <Link to="/beneficios/16">
                                <button className="button-global">aplicabilidade</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </SolucoesStyled>
            <Footer />
        </div>
    )
}

const SolucoesStyled = styled.div`
	@media screen and (min-width: 1201px) and (max-width: 3500px) {
        .container {
            .itens {
                display: flex;
                align-items: center;
                justify-content: center;

                .item {
                    text-align: center;
                    margin: 2vh 0;
                    padding: 1vh 4vw;
                    width: 20vw;
                    height: 35vh;

                    img {
                        width: 90px;
                        height: 90px;
                    }

                    h3 {
                        margin: 2vh 0 2vh 0;
                        height: 10vh;
                        font-size: 0.9rem;
                        max-width: 100ch;
                    }

                    button {
                        font-size: 0.8rem !important;
                    }
                }
            } 
        }
    }

	@media screen and (min-width: 1001px) and (max-width: 1200px) {
        .container {
            .itens {
                width: 100vw;
                display: grid;
                grid-template-columns: 40vw 40vw;

                .item {
                    text-align: center;
                    margin: 8vh 0;

                    img {
                        width: 90px;
                        height: 90px;
                    }

                    h3 {
                        font-size: 0.9rem;
                        margin: 2vh auto;
                        height: 6vh;
                        max-width: 30ch;
                    }

                    button {
                        font-size: 0.7rem !important;
                    }
                }
            }
        }
    }

	@media screen and (min-width: 801px) and (max-width: 1000px) {
        .container {
            .itens {
                width: 100vw;
                display: grid;
                grid-template-columns: 40vw 40vw;

                .item {
                    text-align: center;
                    margin: 8vh 0;

                    img {
                        width: 90px;
                        height: 90px;
                    }

                    h3 {
                        font-size: 0.9rem;
                        margin: 2vh auto;
                        height: 6vh;
                        max-width: 30ch;
                    }

                    button {
                        font-size: 0.7rem !important;
                    }
                }
            }
        }
    }

	@media screen and (max-width: 800px) {
        .container {
            text-align: center;

            .item {
                margin: 12vh 0;
                
                img{
                    width: 80px;
                    height: 80px;
                }

                button {
                    font-size: 0.7rem !important;
                }

                h3 {
                    font-size: 0.8rem;
                    margin: 3vh auto;
                    text-align: center;
                    max-width: 30ch;
                }
            }
        }
    }
`

export default Solucoes