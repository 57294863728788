import { Link } from "react-router-dom";

import Header from "../../components/header";
import Footer from "../../components/footer";
import News from "../../components/news";

import Imgmateriaozonio from "../../images/noticias/materia-ozonio.png";
import Img20200601 from "../../images/noticias/2020.06.01.png";
import Img20200526 from "../../images/noticias/2020-05-26.jpg";
import Img20200826 from "../../images/noticias/2020.08.26.jpg";
import Img20200814 from "../../images/noticias/2020.08.14.jpg";
import ThumbPrefeitura from "../../images/noticias/thumb-prefeitura.png";
import ThumbMateriaOzonio from "../../images/noticias/thumb-materia-ozonio.png";
import Img20201124 from "../../images/noticias/2020.11.24.jpg";
import ThumbVentilacao from "../../images/noticias/thumb-ventilacao.png";

import arrow2 from "../../images/svg/arrow2.svg";
import arrow3 from "../../images/svg/arrow3.svg";

function Pagina3() {
  return (
    <div>
      <Header title="NOTICIAS" />

      <h2 className="noticia-title">Notícias</h2>

      <div className="pagina-content">
        <ul>
          <li>
            <News
              img={ThumbVentilacao}
              titulo="Por que ventilação é mais importante que limpar superfícies"
              data="26/04/2021"
              descricao="A chance de o contato de uma pessoa com uma superfície contaminada pelo coronavírus resultar em..."
              pagina="importancia-ventilacao-limpar-superficie"
            />
          </li>
          <li>
            <News
              img={Img20201124}
              titulo="'A Covid-19 está longe de ser controlada no país', alerta pneumologista"
              data="24/11/2020"
              descricao="Pneumologista e pesquisadora da Fiocruz, Margareth Dalcolmo já enfrentou muitos desafios em quatro..."
              pagina="covid-19-longe-de-ser-controlada"
            />
          </li>
          <li>
            <News
              img={ThumbMateriaOzonio}
              titulo="Ozônio é eficaz para limpeza de ambiente fechados, aponta estudo"
              data="11/11/2020"
              descricao="A Universidade de São Paulo (USP) validou o uso de ozônio para desinfetar ambientes fechados..."
              pagina="ozonio-eficaz-limpeza-amientes-fechados"
            />
          </li>
          
        </ul>
        <ul>
          <li>
            <News
              img={Img20200826}
              titulo="Pesquisadores japoneneses afirmam que ozônio neutraliza o coronavírus"
              data="26/08/2020"
              descricao="Em pequenas concentrações, diz estudo, gás pode ajudar a desinfectar espaços sem prejudicar o ser..."
              pagina="pesquisadores-japoneses-afirmam-ozonio-neutraliza-coronavirus"
            />
          </li>
          <li>
            <News
              img={Img20200814}
              titulo="Estudo consegue detectar partículas de coronavírus no ar de hospital"
              data="14/08/2020"
              descricao="Um grupo de 239 cientistas de 32 paísesafirmou no mês passado que partículas do novo coronavírus..."
              pagina="estudo-consegue-detectar-particulas-coronavirus-ar-hospital"
            />
          </li>
          <li>
            <News
              img={ThumbPrefeitura}
              titulo="Prefeitura de Bacabeira disponibiliza Túnel de Descontaminação"
              data="05/06/2020"
              descricao="Prefeitura de Bacabeira disponibiliza Túnel de Descontaminação como medida de prevenção ao Coranavírus..."
              pagina="prefeitura-bacabeira-disponibiliza-tunel-descontaminacao-mdedida-prevencao-coronavirus"
            />
          </li>
          
        </ul>
        <ul>
          <li>
            <News
              img={Imgmateriaozonio}
              titulo="Tecnologia do ozonio estudada na UNESC ao combate do coronavírus"
              data="01/06/2020"
              descricao="Universidade estuda como usar ozônio para desinfecção de áreas no Sul de SC ASSISTA A REPORTAGEM..."
              pagina="tecnologia-ozonio-utilizada-combate-coronavirus"
            />
          </li>
          <li>
            <News
              img={Img20200601}
              titulo="Declaração de Berg Campos, Prefeito de Porto de Moz"
              data="01/06/2020"
              descricao="Nessa guerra contra um inimigo invisível, estamos apostando na técnica da ozônioterapia... "
              pagina="declaracao-berg-campos-prefeito-porto-de-moz"
            />
          </li>
          <li>
            <News
              img={Img20200526}
              titulo="Ozonioterapia: o que é, para que serve e como é feita"
              data="25/05/2020"
              descricao="a ozonioterapia consiste num processo em que é administrado gás de oz^nio no corpo para tratar..."
              pagina="ozonioterapia-o-que-e"
            />
          </li>
          
        </ul>

        <Link to="/noticias/pagina-4">
          <img className="icon" src={arrow2} alt="icone voltar" />
        </Link>
        <Link to="/noticias/pagina-2">
          <img className="icon" src={arrow3} alt="icone proximo" />
        </Link>
      </div>

      <Footer />
    </div>
  );
}

export default Pagina3;
