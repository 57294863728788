import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import AmazonasFaseAmarelaImg from '../../../images/noticias/amazonas-fase-amarela.png'

function AmazonasFaseAmarela() {
    return (
        <AmazonasFaseAmarelaStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Amazonas volta para fase amarela de transmissão de Covid-19</h1>
                    <div className='paginas-noticias-content AmazonasFaseAmarela'>
                        <Page
                            subtitulo="Amazonas volta para fase amarela de transmissão de Covid-19"
                            data="25/10/2022"
                            img={AmazonasFaseAmarelaImg}
                        />
                        {/* <img src={ AmazonasFaseAmarelaImg } alt="noticia" /> */}
                        <p>Após alta de casos de síndrome respiratórias, o Amazonas retornou para a fase amarela da pandemia da Covid-19. A informação é da Fundação de Vigilância em Saúde (FVS-AM).</p>
                        <span>Veja matéria completa em: </span><a href="https://cm7brasil.com/amazonas/amazonas-volta-para-fase-amarela-de-transmissao-da-covid-19/" target="_blank" rel="noreferrer">https://cm7brasil.com/amazonas/amazonas-volta-para-fase-amarela-de-transmissao-da-covid-19/</a>
                    </div>
                </div>
                <Footer />
            </div>
        </AmazonasFaseAmarelaStyle>
    )
}

const AmazonasFaseAmarelaStyle = styled.div`
    .AmazonasFaseAmarela-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default AmazonasFaseAmarela