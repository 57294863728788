import Header from '../../components/header'
import Footer from '../../components/footer'
import Ficha from '../../components/ficha'

function Veterinaria() {
    return (
        <div>
            <Header title={"VETERINÁRIA E PET SHOP"} />
            <div className="body">
                <h2 className="title-d">Aplicabilidade</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>

                <p className="desc">
                    Na purificação e esterilização do ar ambiente da recepção, sala
                    da diretoria, sala de triagem, sala de exames, sala de repouso,
                    sala de gesso, sala de feridas, sala de queimados, sala de banho
                    e tosa, consultórios, enfermarias, UTIs, área dos produtos à
                    venda, elevadores, banheiros, vestiários, cozinha e outros,

                    utilizando o nosso equipamento <strong>SANITECH (O₃) LINHA PURI</strong> de
                    baixa concentração para <strong>ambientes habitáveis</strong>, evitando assim
                    a contaminação cruzada por bactérias, vírus, germes, fungos,
                    odores indesejáveis, a proliferação de insetos em ambientes
                    fechados, como a picada dos mosquitos da dengue,
                    chikungunya e zika e minimizando as doenças respiratórias e
                    alérgicas como renite, sinusite, resfriado por aerossóis.
                </p>

                <p className="desc">
                    Nas ambulâncias e transportes dos animais utilizando o nosso
                    equipamento <strong>SANITECH (O₃) MULTIUSO</strong> de baixa concentração
                    para <strong>ambientes habitáveis</strong> eliminando micro-organismos,
                    fungos, odores indesejáveis.
                </p>

                <p className="desc">
                    Na descontaminação dos sapatos
                    utilizando o nosso equipamento <strong>SANITECH UVC</strong> eliminando os
                    micro-organismos das solas dos sapatos em 99%.
                </p>

                <p className="desc">
                    No tratamento da água potável e reuso utilizando o nosso
                    equipamento <strong>SANITECH MVO₃</strong>, eliminando as bactérias,
                    odores,
                    melhorando a turbidez, reestruturando molecularmente,
                    melhorando o seu PH oxigenando-o.
                </p>

                <p className="desc">
                    No tratamento de resíduos sólidos e lixo hospitalar utilizando o
                    nosso equipamento <strong>SANITECH MVO₃ DEFINFECÇÃO 80</strong>,
                    removendo e eliminando as bactérias, odores, melhorando a
                    turbidez e oxigenando-o.
                </p>

                <p className="desc">
                    Na sanitização das compras de supermercados e outros,
                    utilizando o nosso equipamento <strong>SANITECH (O₃) OXI-
                    SANITIZAÇÃO 40</strong>, com uma cápsula inflável ou minicontainer e
                    gás de ozônio em alta concentração, eliminando os micro-
                    organismos.
                </p>

                <p className="desc">
                    Na descontaminação dos alimentos com água ozonizada, no
                    tratamento com água ozonizada na limpeza das feridas,
                    queimaduras e enxague após o banho terminal, utilizando o
                    nosso equipamento <strong>SANITECH (O₃) ACQUA</strong>, eliminando as
                    bactérias, odores indesejáveis e auxiliando na cicatrização.
                </p>

                <p className="desc">
                    Na pulverização com água ozonizada
                    potencializando na mistura de saneantes químicos em
                    ambientes de grande circulação, canil e áreas contaminadas
                    nas veterinárias após a limpeza terminal, utilizando o nosso
                    equipamento <strong>SANITECH (O₃) NEBULIZADOR COSTAL</strong>,
                    eliminando micro-organismos, fungos e odores indesejáveis.
                </p>
            </div>
            <Ficha />
            <Footer />
        </div>
    )
}

export default Veterinaria