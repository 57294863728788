import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'
import Page from '../../../components/page'

import Candida from '../../../images/noticias/candida.jpg'

function CandidaAuris() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Maior surto de "superfungo" candida auris é identificado por cientistas no Brasil</h1>
                <div className='paginas-noticias-content aboz'>
                    <Page
                        subtitulo="ABOZ - Nota de Esclarecimento Jurídico"
                        data="22/01/2023"
                        img={Candida}
                />
                <p>Um estudo da Fundação Oswaldo Cruz (Fiocruz) identificou o que seria o maior surto de <i>candida auris</i> no Brasil. Os 48 casos da doença foram relatados entre novembro de 2021 e fevereiro de 2022 na capital de Pernambuco, Recife.</p>
                <p>A infecção por <i>candida auris</i> é resistente a medicamentos e pode ser fatal. Estima-se que entre 30% e 60% dos pacientes infectados com o fungo acabam indo a óbito. Porém, os números podem variar com base em alguns aspectos, como a gravidade da doença e a resistência do patógeno a tratamentos.</p>
                    <a href="https://olhardigital.com.br/2023/01/22/medicina-e-saude/maior-surto-de-superfungo-candida-auris-e-identificado-por-cientistas-no-brasil/" target="_blank" rel="noreferrer"><strong>Fonte: https://olhardigital.com.br/2023/01/22/medicina-e-saude/maior-surto-de-superfungo-candida-auris-e-identificado-por-cientistas-no-brasil/</strong></a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CandidaAuris