import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    :root {
        --primary-color: #033d4a;
        --secondary-color: #abcb33;
        --primary-hover: #085465;
        --secondary-hover: #c3e73f;
        --white-color: #ffffff;
        --white-hover: #d3d3d9;
        --gray-color: #555b6e;
        --text-color: #333533;
    }

    * {
        padding: 0;
        margin: 0;
        text-decoration: none;
        box-sizing: border-box;
        list-style: none;
        scroll-behavior: smooth;
        font-family: 'Poppins', sans-serif;
    }

    body {
        overflow-x: hidden;
        background-color: var(--white-color);
    }

    h1, h2, h3, h4 {
        color: var(--primary-color);
        font-weight: 700;
    }

    span {
        color: var(--primary-color);
    }

    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }

    li {
        list-style: none;
    }

    strong {
        color: var(--primary-color);
    }

    textarea {
        resize: none;
    }

    .title {
        text-align: center;
        margin-bottom: 8vh;
    }

    .title-d {
        font-size: 1.3rem;
        margin-top: 4vh;
    }

    .desc {
        padding: 0 2vw;
        margin-top: 2vh;
        line-height: 32px;
    }

    .button-nt {
        background-color: transparent;
        color: var(--primary-color);
        cursor: pointer;
        border: none;
        font-weight: 600;

        :hover {
            color: rgba(13,120,144,1);
        }
    }

    .button-global {
        border-radius: 8px;
        padding: 8px;
        cursor: pointer;
        width: auto;
        height: auto;
        border: none;
        color: white;
        background: rgb(3,61,74);

        :hover {
            transition-delay: .2s;
            background: rgba(13,120,144,1);
        }
    }

    .style {
        display: flex;
        margin-bottom: 5vh;
        .box {
            width: 30px;
            height: 10px;
        }

        .b1 {
            background-color: var(--primary-color);
        }
        .b2 {
            background-color: var(--secondary-color);;
        }
    }

    @media screen and (min-width: 1501px) and (max-width: 3500px) {
        .body {
            padding: 8vh 8vw;
        }

        .menu-container {
            padding: 5vh 8vw;
        }
    }

    @media screen and (min-width: 1201px) and (max-width: 1500px) {
        .body {
            padding: 8vh 8vw;
        }

        .menu-container {
            padding: 5vh 8vw;
        }
    }

    @media screen and (min-width: 1001px) and (max-width: 1200px) {
        .body {
            padding: 8vh 6vw;
        }

        .menu-container {
            padding: 5vh 4vw;
        }
    }

    @media screen and (min-width: 801px) and (max-width: 1000px) {
        .body {
            padding: 8vh 10vw;
        }

        .menu-container {
            padding: 6vh 0 0 0;
        }
    }

    @media screen and (max-width: 800px) {
        text-align: center;

        .title {
            font-size: 1.6rem;
        }

        .title-d {
            font-size: 1.2rem;
        }
        
        .body {
            padding: 6vh 8vw;
        }

        .menu-container {
            padding: 6vh 0 0 0;
        }

        p {
            text-align: justify;
        }
    }
`

export default GlobalStyle