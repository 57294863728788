import { Link } from 'react-router-dom'
import styled from 'styled-components'

function Ficha() {
    return (
        <FichaStyled>
            <Link to="/pdf/NT-QAI-QUALIDADEDOARINTERNO.pdf" target="_blank">
                <p>FISP - Ficha de Informação de Segurança para o Produto SANITECH (O₃) Linha PURI <strong>→</strong></p>
            </Link>
        </FichaStyled>
    )
}

const FichaStyled = styled.div`
    color: var(--primary-color);
    font-weight: bold;

    @media screen and (min-width: 1501px) and (max-width: 3500px) {
        padding: 20px 150px;
    }

    @media screen and (min-width: 1201px) and (max-width: 1500px) {
        padding: 20px 120px;
    }

    @media screen and (min-width: 1001px) and (max-width: 1200px) {
        padding: 10px 90px;
    }

    @media screen and (min-width: 801px) and (max-width: 1000px) {
        padding: 10px 110px;
    }

    @media screen and (max-width: 800px) {
        padding: 10px 70px;
    }
`

export default Ficha