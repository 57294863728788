import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import styled from 'styled-components'

import '../style.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '90vh',
    overflowY: 'scroll',
    background: 'white',
    border: '2px solid #000',
    boxShadow: '24',
    p: 2,
    color: 'black',
}

function ModalProduct(props) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <ModalProductStyle>
            <div className="modal-product">
                <div className="modal-product-button">
                    <button className="button-global" onClick={handleOpen}>saiba mais</button>
                </div>

                <Modal
                    className="modal"
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={style}>
                        <Typography className="modal-product-title" variant="h7" component="h3">
                            <h3>{props.desc}</h3>
                        </Typography>
                        <Typography id="modal-product-description" sx={{ mt: 2 }}>
                            <p>{props.descricao}</p>
                        </Typography>
                        <Typography className="modal-product-title title-2" variant="h7" component="h3">
                            <h3>{props.func}</h3>
                        </Typography>
                        <Typography id="modal-product-description" sx={{ mt: 2 }}>
                            <p>{props.funcionamento}</p>
                        </Typography>
                        <button className="button-fechar" style={{ width: '12vw' }} onClick={handleClose}>fechar</button>
                    </Box>
                </Modal>
            </div>
        </ModalProductStyle>
    )
}

const ModalProductStyle = styled.div`
    .modal-product-button {
        button {
            padding: 8px 15px;
            width: auto;
            height: auto;
            margin: 2vh;
            background-color: var(--primary-hover);
            border: none;
            color: #fff;

            :hover {
                background-color: var(--primary-color);
            }
        }
    }
`

export default ModalProduct