import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import seta from '../images/seta.png'

const CardNt = ({ title, text, backgroundImage, link }) => {
  const cardStyle = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <CardNtStyled style={cardStyle}>
      <h2>{title}</h2>
      <p>{text}</p>
      <Link to={`/notas-tecnicas/${link}`}>
        <img src={seta} />
      </Link>
    </CardNtStyled>
  );
};

const CardNtStyled = styled.div`
    background-color: var(--white-color);
    background-size: 120%;
    background-position: center;
    border-radius: 36px;
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        position: absolute;
        width: 30px;
        bottom: 40px;
        right: 40px;
    }
    @media screen and (min-width: 1501px) {
      width: 350px;
      height: 500px;

      h2 {
        top: 40px;
        position: absolute;
        display: block;
        text-align: center;
        color: white;
      }

      p {
          text-align: center;
          color: white;
          font-weight: 100;
          font-size: 30px;
      }
    }

    @media screen and (min-width: 1001px) and (max-width: 1500px) {
      margin: 40px auto;
      width: 350px;
      height: 500px;

      h2 {
        top: 40px;
        position: absolute;
        display: block;
        text-align: center;
        color: white;
      }

      p {
        text-align: center;
        color: white;
        font-weight: 100;
        font-size: 30px;
      }   
    }

    @media screen and (max-width: 1000px) {
        margin: 30px auto;
        width: 80%;
        height: 400px;

        h2 {
          top: 30px;
          position: absolute;
          display: block;
          text-align: center;
          color: white;
        }

        p {
          text-align: center;
          color: white;
          font-weight: 100;
          font-size: 20px;
      }
    }
`

export default CardNt;