import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import Img20200323 from '../../../images/noticias/2020.03.23.jpg'

function AntecipacaoVacina() {
    return (
        <AntecipacaoStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Antecipação da Campanha de vacinação cotra a gripe</h1>
                    <div className='paginas-noticias-content antecipacao-vacina'>
                        <Page
                            subtitulo="Antecipação da Campanha de vacinação cotra a gripe"
                            data="23/03/2020"
                        />
                        <img src={ Img20200323 } alt="noticia thum" />
                        <span>Visite o site!</span><a href="https://www.covidvisualizer.com/" target="_blank" rel="noreferrer">https://www.covidvisualizer.com/</a>
                    </div>
                </div>
                <Footer />
            </div>
        </AntecipacaoStyle>
    )
}

const AntecipacaoStyle = styled.div`
    .antecipacao-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default AntecipacaoVacina