

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import PageVideo from '../../../components/pageVideo'

import MateriaCNN from '../../../videos/CNN-Sao-Paulo11.11.2020-17h59-Ozonio-e-eficaz-para-limpeza-de-ambientes-fechados-aponta-estudo.mp4'

function EficaciaOzonioLimpeza() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Ozônio é eficaz para limpeza de ambientes fechados, aponta estudo</h1>
                <div className='paginas-noticias-content'>
                    <PageVideo
                        subtitulo="Ozônio é eficaz para limpeza de ambientes fechados, aponta estudo"
                        data="11/11/2020"
                        video={MateriaCNN}
                        descricao="CNN – São Paulo 11/11/2020 – 17h59
                        A Universidade de São Paulo (USP) validou o uso de ozônio para desinfetar ambientes fechados. Essa tecnologia promete ajudar no combate à pandemia da Covid-19.
                        A aplicação já está sendo feita em estabelecimentos de São Paulo. O estudo durou cerca de dois meses para ser concluído.
                        É importante lembrar que a desinfecção com o gás só pode ser feita quando o ambiente estiver sem a presença de pessoas.
                        
                        Fonte: CNN Brasil"
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EficaciaOzonioLimpeza