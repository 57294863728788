
import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

function Superbacteria() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Staphylococcus aureus: entenda o que é a bactéria que pode causar sepse, a infecção generalizada</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        subtitulo="“Superbactérias” resistentes poderão matar até 10 milhões em 2050"
                        data="16/04/2019"
                    />
                    <p>A descoberta da penicilina, primeiro componente a fazer parte dos antibióticos, é até hoje considerada um dos marcos da história da medicina. Descoberta em 1928 pelo escocês Alexander Fleming, a substância já salvou milhões de vidas e transformou infecções potencialmente mortais em doenças não tão graves assim.
                    Hoje, quase um século depois, novas substâncias além da penicilina passaram a ser usadas nos medicamentos, justamente pelo fato de que as bactérias, quando expostas muitas vezes a uma substância, vão tornando-se resistentes a ela. Ainda assim, vem surgindo novas bactérias que evoluíram a ponto de serem imunes aos antibióticos, chamadas de forma leiga de “superbactérias”.
                    Descobriu-se nesta semana que foi justamente uma dessas bactérias potencialmente resistentes a responsável por tirar a vida de Arthur Araújo Lula da Silva, neto do ex-presidente Luiz Inácio Lula da Silva, que morreu há um mês com apenas sete anos. O Instituto Lula divulgou nesta terça-feira que a causa da morte foi a bactéria Staphylococcus aureus, micro-organismo que, no geral, está presente na pele.</p>
                    <p>Até então, imaginava-se que a causa da morte de Arthur fora um tipo de meningite (chamada meningocócica), com base em informação vazada pelo hospital Bartira, da rede D’Or, em que o menino deu entrada antes de falecer. A descoberta da real causa do falecimento veio somente após exames realizados pela prefeitura de Santo André (SP), seguindo protocolos do Ministério da Saúde.
                    A divulgação inicial de que a causa havia sido meningite causou um frenesi em busca por vacinas contra a doença, além de críticas ao Sistema Único de Saúde (SUS) por não oferecer na rede pública vacinas contra todos os tipos de meningite.
                    O deputado federal e ex-ministro da Saúde, Alexandre Padilha (PT-SP), chegou a publicar em seu perfil no Twitter nesta terça-feira críticas ao hospital, dizendo esperar que a instituição “esclareça quais procedimentos de apuração já realizou para o vazamento de diagnóstico que se revelou antiético para com a família e irresponsável com a saúde pública da região”.
                    A bactéria específica que matou Arthur, a Staphylococcus aureus, costuma infectar a pele, e não só de pessoas doentes: o micro-organismo pode estar presente nos narizes e na pele de até um terço dos seres humanos, sobretudo os que trabalham em hospitais ou já estão doentes. A diferença é que, para virar um problema de fato, é preciso que a bactéria adentre o corpo, o que no geral, acontece por meio de um corte ou da inalação de gotículas contaminadas. A partir daí, a bactéria pode viajar pela corrente sanguínea e infectar partes do do corpo, se espalhando pelos órgãos.</p>
                    <p>As infecções por Staphylococcus aureus vão de leves a quase mortais, como infelizmente foi o caso de Arthur — que, por ser uma criança, também tinha o corpo mais frágil. Ainda assim, médicos afirmam que é raro que uma pessoa saudável perca a vida por causa dessa bactéria. Estão mais expostos a infecções graves pessoas que já estão doentes, cujo sistema imunológico não anda bem, ou pessoas que frequentam hospitais (sobretudo as que fazem furos no nariz, como para colocação de cateter).
                    Há vários tipos de Staphylococcus aureus, algumas mais resistentes que outras. A maior parte, se detectada rapidamente, pode ser tratada com antibióticos. Para o médico Nelson Douglas Ejzenbaum, membro da Sociedade Brasileira de Pediatria e da Academia Americana de Pediatria, este não é o tipo mais perigoso de bactéria que temos, e nem mesmo poderia ser classificado como uma “superbactéria” (termo que não existe oficialmente na medicina). “Em termos de bactérias, há problemas maiores”, diz.</p>
                    <p><strong>Como nasce uma “superbactéria”</strong></p>
                    <p>O fato é que bactérias de diversos tipos vêm se tornando cada vez mais resistentes a antibióticos — e parte da culpa é nossa. O alto uso de antibióticos para doenças não tão graves, além do uso irregular (como pessoas que param de tomar o medicamento antes do prazo indicado), vem fazendo as bactérias se modificarem, de modo que os medicamentos não as combatem mais da mesma forma.
                    Uma chamada “superbactéria” se desenvolve da seguinte forma: quando há uma infecção por bactérias e o paciente toma um antibiótico, um pequeno grupo delas pode sobreviver — por serem um pouco diferentes das demais. Quando isso acontece, essas bactérias “mais fortes” não morrem, e, de quebra, aprendem a resistir àquele antibiótico. Depois, elas podem se reproduzir e até mesmo transmitir esse conhecimento a outras bactérias.
                    A partir daí, surge um novo tipo de bactéria que não responde mais àquele antibiótico. É por isso que a própria penicilina, a “mãe” dos antibióticos, é pouco usada atualmente. As bactérias já aprenderam a resistir às substâncias dessa família, e tiveram de entrar em cena novos medicamentos.
                    As infecções bacterianas matam 700.000 pessoas em todo o mundo anualmente, segundo a Organização Mundial da Saúde (OMS). Um estudo também da OMS mostrou que o Brasil é o 17º país que mais utiliza antibióticos, em um ranking de 65 nações.
                    Se medidas não forem tomadas, estima-se que, em 2050, mais de 10 milhões de pessoas morreriam por problemas relacionadas às bactérias resistentes, segundo um estudo do governo britânico coordenado por especialistas da área e divulgado em 2014. As “superbactérias” também custariam até 3,8% do PIB global, segundo o Banco Mundial. Os principais afetados seriam regiões mais pobres e com menos acesso a hospitais de qualidade e saneamento básico, sobretudo na África e na Ásia.
                    Nem toda bactéria resistente a antibiótico é nociva. Algumas podem ser muito resistentes mas não fazer mal ao corpo; outras podem ser facilmente mortas com medicamentos, mas fazer um grande estrago se não tratadas. O maior problema é quando uma bactéria é resistente e também nociva.</p>
                    <p>O problema é que a velocidade com que essas bactérias surgem é maior do que a nossa capacidade de criar novos antibióticos, explica o Dr. Ralcyon Teixeira, infectologista do hospital Emílio Ribas, uma das maiores referências no tratamento e pesquisa de bactérias. “Assim como nós, as bactérias também têm mecanismos de defesa: se antes um antibiótico em formato de triângulo encaixava em um tipo de bactéria, agora as novas podem transformar-se em um quadrado, e esse antibiótico específico não funciona mais”, diz.
                    Ou seja: quanto mais exposição a antibióticos, mais fortes as bactérias ficam. Ambientes hospitalares, onde há muitos antibióticos em circulação, são um lugar propício para o desenvolvimento dessas bactérias super fortes. Pias, receptáculos de urinas e até ambientes secos de hospitais podem ser lugar para desenvolvimento desses organismos. Assim, para evitar que essas bactérias adentrem nosso corpo e causem estragos, os médicos recomendam a constante higienização das mãos e limpeza de machucados.</p>
                    <p><strong>Agronegócio é um dos principais vilões</strong></p>
                    <p>Além do uso indiscriminado de antibióticos em humanos, o agronegócio é também um dos principais usuários desses medicamentos. Segundo a OMS, cerca de 80% do consumo de antibióticos no mundo acontece no setor animal, sobretudo na pecuária. E nem sempre é para tratar animais doentes: a professora de infectologia Juliana Lapa, da Faculdade de Medicina da Universidade de Brasília, lembra que muitos criadores de animais usam antibióticos simplesmente para que o animal cresça mais e possa ser vendido a preços maiores. “Se fosse para tratar os animais doentes, tudo bem. O problema é que é um uso irresponsável, e sem necessidade médica”, explica a especialista.
                    O alto uso de antibióticos no agronegócio acaba indo parar no solo e nos lençóis freáticos. Assim, em última instância, pode também chegar aos humanos que sequer tomaram antibióticos, fazendo com que as bactérias de nosso corpo “reconheçam” mais substâncias do que o que ocorreria naturalmente.
                    Por isso, os infectologistas afirmam que são necessárias políticas públicas para reduzir o uso indiscriminado de antibióticos, tanto em humanos quanto no agronegócio. O Brasil lançou no fim do ano passado o seu Plano de Resistência aos Antibióticos, que inclui o financiamento de pesquisas inovadoras sobre o tema. Outra mudança recente foi a exigência de receitas médicas mais rigorosas antes de comprar antibióticos em farmácias.
                    A OMS também recomenda que os países trabalhem para evitar o tráfico de antibióticos falsificados, que acentuam o problema do fortalecimento das bactérias. Segundo a organização, em países de renda média e baixa, como o Brasil, um em cada dez produtos é falsificado.</p>
                    <p>“O mundo pertence a bactérias e vírus, somos apenas visitantes”, diz o Dr. Ejzenbaum. É um problema mundial, e é preciso que legisladores, comunidade médica, agronegócio e pacientes façam sua parte.</p>
                    <span>Fonte: </span><a href="https://exame.abril.com.br/ciencia/superbacterias-resistentes-a-antibioticos-poderao-matar-ate-10-milhoes-em-2050/" target="_blank" rel="noreferrer">https://exame.abril.com.br/ciencia/superbacterias-resistentes-a-antibioticos-poderao-matar-ate-10-milhoes-em-2050/</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Superbacteria