

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

function Paroquia() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Estudo consegue detectar partículas de coronavírus no ar de hospital</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        subtitulo="Estudo consegue detectar partículas de coronavírus no ar de hospital"
                        data="14/08/2020"
                        descricao="Um grupo de 239 cientistas de 32 países afirmou no mês passado que partículas do novo coronavírus permanecem no ar em ambientes fechados, sendo capazes de infectar as pessoas. Agora, um estudo preliminar publicado na plataforma MedRxiv (sem revisão por pares) e desenvolvido por pesquisadores da Universidade da Flórida (EUA), parece ter confirmado a tese: o novo coronavírus pode sobreviver no ar.
                        O estudo mostrou que as partículas do vírus podem permanecer no ar como aerossóis fazendo com que se espalhem mais rapidamente. A transmissão por aerossol ocorre quando várias gotículas respiratórias produzem aerossóis microscópicos por evaporação, e quando uma pessoa respira, tosse, espirra ou fala. Os aerossóis contêm vírus em quantidade suficiente para causar infecção."
                    />
                    <span>Fonte: Bruna Alves (Colaboração para VivaBem) </span><a href="https://www.uol.com.br/vivabem/noticias/redacao/2020/08/12/estudo-mostra-que-coronavirus-pode-sim-ser-transmitido-pelo-ar.htm" target="blank">https://www.uol.com.br/vivabem/noticias/redacao/2020/08/12/estudo-mostra-que-coronavirus-pode-sim-ser-transmitido-pelo-ar.htm</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Paroquia