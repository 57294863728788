import Header from '../../components/header'
import Footer from '../../components/footer'
import Ficha from '../../components/ficha'

function Hospitais() {
    return (
        <div>
            <Header title={"HOSPITAIS, CLÍNICAS E DENTISTAS"} />
            <div className="body">
                <h2 className="title-d">Aplicabilidade</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>

                <p className="desc">Na purificação e esterilização do ar ambiente da recepção, sala da diretoria, sala de triagem, sala de exames, sala de repouso, sala de gesso, sala de feridas, sala de queimados, consultórios, leitos, enfermarias, UTIs, elevadores, banheiros, vestiários, refeitórios, cozinha e outros, utilizando o nosso equipamento <strong>SANITECH (O₃) LINHA PURI</strong> de baixa concentração para <strong>ambientes habitáveis</strong>, evitando assim a contaminação cruzada por bactérias, vírus, germes, fungos, odores, indesejáveis, a proliferação de insetos em ambientes fechados, comom a picada de mosquitos da dengue, chikungunya e zika e minimizando as doenças respiratórias e alérgicas como rinite, sinusite, resfriados por aerossóis.</p>

                <p className="desc">Nas ambulâncias e transportes de pacientes utilizando o nosso equipamento <strong>SANITECH (O₃) MULTIUSO</strong> de baixa concentração para <strong>ambientes habitáveis</strong>eliminando micro-organismos, fungos, odores indesejáveis</p>

                <p className="desc">Na descontaminação dos sapatos utilizando o nosso equipamento <strong>SANITECH UVC</strong> eliminando os micro-organismos das solas dos sapatos em 99%.</p>

                <p className="desc">No tratamento da água potável e reuso utilizando o nosso equipamento <strong>SANITECH MVO₃</strong>, eliminando as bactérias, odores, melhorando a turbidez, reestruturando molecularmente, melhorando o seu PH oxigenando-o.</p>

                <p className="desc">No tratamento de ETE hospitalar, resíduos sólidos e lixo hospitalar utilizando o nosso equipamento <strong>SANITECH MVO₃ DESINFECÇÃO 80</strong>, removendo e eliminando as bactérias, odores, melhorando a turbidez e oxigenando-o.</p>

                <p className="desc">Na sanitizaç~çao das compras de supermercados e outros, utilizando o nosso equipamento <strong>SANITECH (O₃) OXI-SANITIZAÇÃO 40</strong>, com uma cápsula inflável ou minicontainer e gás de ozônio em alta concentração, eliminando os micro-organismos.</p>

                <p className="desc">Na descontaminação dos alimentos com água ozonizada, no tratamento com água ozonizada na limpeza das feridas, queimaduras, no enxague bucal, utilizando o nosso equipamento <strong>SANITECH (O₃) ACQUA</strong>, eliminando as bacteria, odores indesejáveis e auxiliando na cicatrização.</p>

                <p className="desc">Na pulverização com água ozonizada potencializando na mistura de saneantes químicos em ambientes de grande circulação e áreas contaminadas nos hospitais após a limpeza terminal, utilizando o nosso equipamento <strong>SANITECH (O₃) NEBULIZADOR COSTAL</strong>, eliminando micro-organismos, fungos e odores indesejáveis.</p>
            </div>        
            <Ficha />  
            <Footer />
        </div>
    )
}

export default Hospitais