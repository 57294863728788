import styled from 'styled-components'
import { Link } from 'react-router-dom'
// import { Fade } from 'react-reveal'

import Header from '../components/header'
import Footer from '../components/footer'
import BannerOzonio from '../components/bannerOzonio'
import CardNt from '../components/cardNt'
// import paper from '../images/svg/paper.svg'
import saude from '../images/svg/saude-n.svg'
import cardBack2 from '../images/card-back2.png'
import cardBack1 from '../images/card-back1.png'
import cardBack3 from '../images/card-back3.png'

function Solucoes() {
    return (
        <div>
            <Header title="NOTAS TÉCNICAS" />
            <BannerOzonio />
            <SolucoesStyled className="body">
                <h2 className="title-d">Artigos e Certificados</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>

                <div class="desc-nt">
                    <p className="desc">
                        Neste espaço, você encontrará um vasto acervo de informações técnicas e científicas sobre os benefícios do ozônio como poderosa ferramenta de purificação e esterilização do ar. Abordamos em detalhes os processos de ozonização, suas aplicações em diferentes setores e os mecanismos pelos quais o ozônio elimina microrganismos, alérgenos e odores indesejados, promovendo ambientes livres de contaminantes nocivos.
                    </p>
                    <p className="desc">
                        Nossas notas técnicas são uma fonte confiável para profissionais da indústria, engenheiros ambientais, gestores de saúde, arquitetos e qualquer pessoa interessada em proporcionar um ambiente limpo e seguro para suas atividades diárias.
                    </p>
                </div>

                <div className="cards-nt">
                    <CardNt 
                        title="SAÚDE"
                        text="Explorar notas técnicas na área da saúde"
                        backgroundImage={cardBack1}
                        link="saude"
                    />
                    <CardNt 
                        title="INDÚSTRIA"
                        text="Explorar notas técnicas na área industrial"
                        backgroundImage={cardBack2}
                    />
                    <CardNt 
                        title="VETERINÁRIA"
                        text="Explorar notas técnicas na área veterinária"
                        backgroundImage={cardBack3}
                    />
                </div>

            </SolucoesStyled>
            <Footer />
        </div>
    )
}

const SolucoesStyled = styled.div`
    @media screen and (min-width: 1001px) {
        .cards-nt {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 6vh 2vw;
        }
    }

    @media screen and (max-width: 1000px) {
        .cards-nt {
            
        }
    }  
`

export default Solucoes