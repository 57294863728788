import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import Casos from '../../../images/noticias/14mm-casos-arte.webp'

function MediaMortesBrasil() {
    return (
        <MediaMortesBrasilStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Brasil registra 78 mortes por Covid e total chega a 688.617 média segue com tendência de queda</h1>
                    <div className='paginas-noticias-content MediaMortesBrasil'>
                        <Page
                            subtitulo="Brasil registra 78 mortes por Covid e total chega a 688.617 média segue com tendência de queda"
                            data="25/10/2022"
                            img={Casos}
                        />
                        {/* <img src={ MediaMortesBrasilImg } alt="noticia" /> */}
                        <p>O Brasil registrou nesta terça-feira (8) 78 novas mortes pela Covid-19 nas últimas 24 horas, totalizando 688.617 desde o início da pandemia. Com isso, a média móvel de mortes nos últimos 7 dias é de 45%, com variação de -32% em relação aos últimos 7 dias, tendência de quede - hoje completa uma semana com esta tendência.</p>
                        <span>Veja matéria completa em: </span><a href="https://g1.globo.com/saude/coronavirus/noticia/2022/11/09/brasil-registra-78-mortes-por-covid-e-total-chega-a-688617-media-segue-com-tendencia-de-queda.ghtml" target="_blank" rel="noreferrer">https://g1.globo.com/saude/coronavirus/noticia/2022/11/09/brasil-registra-78-mortes-por-covid-e-total-chega-a-688617-media-segue-com-tendencia-de-queda.ghtml</a>
                    </div>
                </div>
                <Footer />
            </div>
        </MediaMortesBrasilStyle>
    )
}

const MediaMortesBrasilStyle = styled.div`
    .MediaMortesBrasil-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default MediaMortesBrasil