import { Link } from "react-router-dom";

import Header from "../../components/header";
import Footer from "../../components/footer";
import News from "../../components/news";
import "./style.css";

import arrow2 from "../../images/svg/arrow2.svg";

import CriancaRespirador from "../../images/noticias/crianca-respirador.webp";
import AltaCovid from "../../images/noticias/alta-covid.png";
import TesteCovid from "../../images/noticias/teste_covid_19_rapido.webp";
import NovaCepa from "../../images/noticias/1_picture2__1_-26636562.jpg";
import Rio from "../../images/noticias/rio-de-janeiro.jpg";
import SaoPaulo from "../../images/noticias/Bandeira_do_estado_de_São_Paulo.svg.png";
import NovaVariante from "../../images/noticias/nova-variante.webp";
import Omicron from "../../images/noticias/omicron.jpg";
import Candida from "../../images/noticias/candida.jpg"

function Pagina2() {
  return (
    <div>
      <Header title="NOTICIAS" />
      <h2 className="noticia-title">Notícias</h2>

      <div className="pagina-content">
        <ul>
          <li>
            <News
              img={Candida}
              titulo='22/01/2023 - Maior surto de "superfungo" candida auris é identificado por cientistas no Brasil'
              data="22/01/2023"
              descricao="A infecção por Candida auris é resistente a medicamentos e pode ser fatal..."
              pagina="candida-auris"
            />
          </li>
          <li>
            <News
              img={NovaVariante}
              titulo="11/11/2022 - Nova variante mais transmissível já corresponde a mais de 90% dos casos de Covid no AM"
              data="16/11/2022"
              descricao="Apesar da quantidade de casos, a subvariante BA.5.3.1, uma mutação mais contagiosa da Ômicron, não tem provocado aumento de mortes..."
              pagina="nova-variante"
            />
          </li>
          <li>
            <News
              img={Omicron}
              titulo="11/11/2022 - Sociedade Brasileira de Infectologia pede medidas urgentes contra subvariante"
              data="16/11/2022"
              descricao="Sociedade Brasileira de Infectologia sugere a aquisição de doses suficientes de vacina para imunizar as crianças..."
              pagina="medidas-urgente-subvariante"
            />
          </li>
        </ul>

        <ul>
          <li>
            <News
              img={CriancaRespirador}
              titulo="04/11/2022 - Fiocruz aponta aumento de casos de vírus sincicial respiratório em crianças"
              data="11/11/2022"
              descricao="Vírus é um dos principais causadores das infecções que afetam o trato respiratório inferior de crianças..."
              pagina="virus-respiratorio"
            />
          </li>
          <li>
            <News
              img={AltaCovid}
              titulo="05/11/2022 - Alta de casos positivos pode indicar nova onda de Covid-19, diz infectologista"
              data="11/11/2022"
              descricao="Em entrevista à CNN, a infectologista do Instituto Emílio Ribas, Rosana RIchtmann, disse qie é possível falar em nova onda de Covid-19 diante do aumento..."
              pagina="nova-onda"
            />
          </li>
          <li>
            <News
              img={TesteCovid}
              titulo="07/11/2022 - Nova cepa tem poder de transmissão maior do que a Ômicron, diz infectologista"
              data="11/11/2022"
              descricao="À CNN Rádio, Márcio Nehab disse que o aumento de casos na Europa e Estados Unidos serve de alerta para o Brasil..."
              pagina="nova-cepa"
            />
          </li>
        </ul>

        <ul>
          <li>
            <News
              img={NovaCepa}
              titulo="05/11/2022 - Especialistas alertam para avanço da covid-19 e chance de nova onda"
              data="11/11/2022"
              descricao="Duas novas cepas do vírus, a BG.1 e a XBB, que surgiram a partir da variante Ômicron, são potencialmente mais resistentes à vacina ..."
              pagina="chance-nova-onda"
            />
          </li>
          <li>
            <News
              img={Rio}
              titulo="07/11/2022 - Prefeitura do Rio confirma transmissão local de subvariante da ômicron da Covid-19"
              data="11/11/2022"
              descricao="BQ.1 está causando o aumento do número de casos de Covid-19 na cidade...."
              pagina="rio-confirma-subvariante"
            />
          </li>
          <li>
            <News
              img={SaoPaulo}
              titulo="08/11/2022 - Covid-19: São Paulo tem primeira morte pela nova subvariante da Ômicron"
              data="11/11/2022"
              descricao="O secretário de Estado da Saúde de São Paulo, Jean Gorinchteyn, confirmou nesta terça-feira, 8, a primeira morte por covid-19 no Estado..."
              pagina="sao-paulo-morte-variante"
            />
          </li>
        </ul>

        <Link to="/noticias/pagina-2">
          <img alt="icon" className="icon" src={arrow2} />
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default Pagina2;
