import { Link } from "react-router-dom";

import Header from "../../components/header";
import Footer from "../../components/footer";
import News from "../../components/news";

import ImgTratamentoOzonio from "../../images/noticias/tratamento-ozonio.jpg";
import ImgMateriaSujeira from "../../images/noticias/materia-sujeira-hospitais.png";
import ImgMateriaPoluicao from "../../images/noticias/materia-poluicao-nas-lagoas.png";
import ImgbacteriaSuperresistente from "../../images/noticias/bacteriasuperresistente.jpg";
import Img20200522 from "../../images/noticias/2020-05-22.jpg";
import Img20200522_2 from "../../images/noticias/2020-05-22_2.jpg";
import Img20200323 from "../../images/noticias/2020.03.23.jpg";
import Img20200523_2 from "../../images/noticias/2020-05-23_2.jpg";
import Img20200523 from "../../images/noticias/2020-05-23.jpg";
import Img20200526_2 from "../../images/noticias/2020-05-26_2.jpg";

import arrow2 from "../../images/svg/arrow2.svg";
import arrow3 from "../../images/svg/arrow3.svg";

function Pagina4() {
  return (
    <div>
      <Header title={"NOTICIAS"} />
      <h2 className="noticia-title">Notícias</h2>

      <div className="pagina-content">
        <ul>
        <li>
            <News
              img={Img20200526_2}
              titulo="Grupos sulfidrila são vulneráveis à oxidação"
              data="25/05/2020"
              descricao="Materia traduzida do CLINMEDJOURNALS.org Muitos vírus requerem grupos sulfidrila reduzidos para..."
              pagina="grupos-sulfidrila-vulneraveis-oxidacao"
            />
          </li>
          <li>
            <News
              img={Img20200523}
              titulo="Serratec instala túnel inflável de ozônio"
              data="23/05/2020"
              descricao="Leia a matéria completa no site do Acontece Petrópolis..."
              pagina="serratec-instala-tunel-inflavel-ozonio"
            />
          </li>
          <li>
            <News
              img={Img20200523_2}
              titulo="Jambo Supermercados instala túnel de descontaminação"
              data="23/05/2020"
              descricao="O Jambo Supermercados loja do bairro Jequiezinho em Jequié, saiu na frente e instalou um túnel de..."
              pagina="jambo-supermercados-instala-tunel-descontaminacao"
            />
          </li>
        </ul>
        <ul>
        <li>
            {" "}
            <News
              img={Img20200522}
              titulo="Comércio investe em túnel de descontaminação de ozônio"
              data="22/05/2020"
              descricao="RIO - Depois do álcool em gel, das máscaras, óculos de segurança e luvas, outro “acessório” tende..."
              pagina="comercio-investe-mascara-alcool-gel-tunel-descontaminacao"
            />
          </li>
          <li>
            {" "}
            <News
              img={Img20200522_2}
              titulo="Comércio investe em túnel de ozônio, que promete eliminar vírus da pele e da roupa"
              data="22/05/2020"
              descricao="Depois do álcool em gel, das máscaras, óculos de segurança e luvas, outro “acessório” tende ..."
              pagina="comercio-investe-tunel-ozonio-elimina-virus"
            />
          </li>
          <li>
            <News
              img={Img20200323}
              titulo="Antecipação da campanha de vacinação contra a gripe"
              data="23/03/2020"
              descricao="Visite o site! https://www.covidvisualizer.com/..."
              pagina="antecipacao-campanha-vacinacao-contra-gripe"
            />
          </li>
          
        </ul>
        <ul>
          <li>
            <News
              img={ImgTratamentoOzonio}
              titulo="Ozonioterapia na prevenção de doenças e para antienvelhecimento"
              data="16/04/2019"
              descricao="Ozonioterapia na prevenção de doenças e para antienvelhecimento... Realizamos tratamentos com..."
              pagina="ozonioterapia-prevencao-doencas-e-antienvelhecimento"
            />
          </li>
          <li>
            <News
              img={ImgMateriaSujeira}
              titulo="Mais de 900 mil recém-nascidos morrem todo ano por causa de sujeira nos hospitais, diz OMS"
              data="16/04/2019"
              descricao="Uma em cada quatro instalações médicas tem acesso limitado ou então nem tem água corrente. Além..."
              pagina="mais-900mil-recem-nascidos-morrem-todo-ano-por-sujeira-hospitais"
            />
          </li>

          <li>
            <News
              img={ImgbacteriaSuperresistente}
              titulo="Staphylococcus aureus: entenda o que é a bactéria que pode causar sepse"
              data="16/04/2019"
              descricao="Os estafilococos são comuns, mas quando afetam pessoas com baixa imunidade podem ser letais...."
              pagina="staphylococcus-aureus-entenda"
            />
          </li>
        </ul>
        <Link to="/noticias/pagina-5">
          <img className="icon" src={arrow2} alt="icone voltar" />
        </Link>
        <Link to="/noticias/pagina-3">
          <img className="icon" src={arrow3} alt="prosseguir" />
        </Link>
      </div>

      <Footer />
    </div>
  );
}

export default Pagina4;
