import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import NovaVarianteImg from '../../../images/noticias/nova-variante.webp'

function NovaVariante() {
    return (
        <NovaVarianteStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Nova variante mais transmissivel já corresponde a mais de 90% dos casos de Covid no AM, diz pesquisador da Fiocruz</h1>
                    <div className='paginas-noticias-content NovaVariante'>
                        <Page
                            subtitulo="Nova variante mais transmissivel já corresponde a mais de 90% dos casos de Covid no AM, diz pesquisador da Fiocruz"
                            data="16/11/2022"
                            img={NovaVarianteImg}
                        />
                        {/* <img src={ NovaVarianteImg } alt="noticia" /> */}
                        <p>Cerca de 94% dos casos de Covid-19 registrados no Amazonas nos últimos meses são da subvariante BA.5.3.1, uma mutação mais contagiosa da Ômicron. A informação foi confirmada ao g1 pelo virologista Felipe Naveca, da Fundação Oswaldo Cruz (Fiocruz), nesta quinta-feira (10).</p>
                        <p>De acordo com o pesquisador, a BA.5.3.1 começou a circular no estado desde junho, e é a responsável pelo aumento de casos da doença no estado, observado com maior intensidade desde a primeira metade de outubro.</p>
                        <p>No período de 12 a 18 de outubro, o Amazonas registrou 348 casos de Covid-19, de acordo com os boletins diários da Fundação de Vigilância em Saúde (FVS-AM). Já na última semana, entre 3 e 9 de novembro, o número de novos casos saltou para 942.</p>
                        <span>Veja matéria completa em: </span><a href="https://g1.globo.com/am/amazonas/noticia/2022/11/10/nova-variante-mais-transmissivel-ja-corresponde-a-mais-de-90percent-dos-casos-de-covid-no-am-diz-pesquisador-da-fiocruz.ghtml" target="_blank" rel="noreferrer">https://g1.globo.com/am/amazonas/noticia/2022/11/10/nova-variante-mais-transmissivel-ja-corresponde-a-mais-de-90percent-dos-casos-de-covid-no-am-diz-pesquisador-da-fiocruz.ghtml</a>
                    </div>
                </div>
                <Footer />
            </div>
        </NovaVarianteStyle>
    )
}

const NovaVarianteStyle = styled.div`
    .NovaVariante-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default NovaVariante