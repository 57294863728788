
import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

function TecnologiaOzonio() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Tecnologia do ozonio estudada na UNESC será utilizada no combate ao coronavirus</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        subtitulo="Tecnologia do ozonio estudada na UNESC será utilizada no combate ao coronavirus"
                        data="01/06/2020"
                        descricao="Universidade estuda como usar ozônio para desinfecção de áreas no Sul de SC
                        
                        Pesquisadores da Universidade do Extremo Sul Catarinense (Unesc) estudam a possibilidade de utilizar a tecnologia do ozônio no trabalho de higienização de espaços como Centro de Triagem de Criciúma, no Sul catarinense, que recebe os pacientes com sintoma de Covid-19.
                        A utilização do ozônio promete ser eficiente na desinfecção de espaços e objetos por ser um agente de oxidação. Conforme o professor Elídio Angioletto, é possível utilizar a água ozonizada para descontaminar ambientes e aparelhos, incluindo equipamentos de proteção individual, como por exemplo, os óculos de proteção da equipe de saúde.
                        Ele explica que o ozônio em alta concentração é o mais potente germicida do que se tem conhecimento, é 100 vezes mais potente do que o cloro e 3.120 vezes mais rápido, além de sua alta capacidade oxidante.
                        Ainda de acordo com o professor, a tecnologia do ozônio já foi utilizada de forma efetiva contra outros tipos de vírus, incluindo os que atacam as vias respiratórias, sendo, portanto, uma grande aposta mundial neste momento de combate à pandemia de Covid-19 causada pelo novo vírus SARS-CoV-2.
                        O trabalho será realizado em três etapas, sendo a aspersão de gotículas de água ozonizada nas pessoas que entram e saem do Centro de Triagem. Também será realizada a higienização total do local com a utilização do equipamento que liberará o ozônio, que irá permear em todo o espaço e na lavagem dos equipamentos de proteção e outros aparelhos utilizados.
                        CORONAVÍRUS"
                    />
                    <a href="https://g1.globo.com/sc/santa-catarina/noticia/2020/04/06/tecnologia-do-ozonio-estudada-na-unesc-sera-utilizada-no-combate-ao-coronavirus.ghtml" target="_blank" rel="noreferrer"><strong>ASSISTA A REPORTAGEM<br/></strong></a>
                    <span>Fonte: </span><a href="https://g1.globo.com/sc/santa-catarina/noticia/2020/04/06/tecnologia-do-ozonio-estudada-na-unesc-sera-utilizada-no-combate-ao-coronavirus.ghtml" target="_blank" rel="noreferrer">https://g1.globo.com/sc/santa-catarina/noticia/2020/04/06/tecnologia-do-ozonio-estudada-na-unesc-sera-utilizada-no-combate-ao-coronavirus.ghtml</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TecnologiaOzonio 