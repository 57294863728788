import styled from 'styled-components'
import Header from '../components/header'

import ImageNotFound from '../images/svg/notfound.svg'

function NotFound() {
    return (
        <div>
            <Header />
            <NotFoundStyled>
                <h2>Página não encontrada</h2>
                <img src={ImageNotFound} alt="pagina nao encontrada" />
               </NotFoundStyled>
        </div>
    )
}

const NotFoundStyled = styled.div`
    text-align: center;
    margin-top: 20vh;

    h2 {
        color: var(--text-color);
        margin-bottom: 4vh;
    }

    img { 
        width: 400px; 
        height: auto;
    }
`

export default NotFound