import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import ImgExpotel from "../../../images/noticias/expotel2018.jpg"
import ImgExpotel01 from '../../../images/noticias/expotel-001.jpg'
import ImgExpotel02 from '../../../images/noticias/expotel-002.jpg'
import ImgExpotel03 from '../../../images/noticias/expotel-003.jpg'
import ImgExpotel04 from '../../../images/noticias/expotel-004.jpg'
import ImgExpotel05 from '../../../images/noticias/expotel-005.jpg'
import ImgExpotel06 from '../../../images/noticias/expotel-006.jpg'
import ImgExpotel07 from '../../../images/noticias/expotel-007.jpg'
import ImgExpotel08 from '../../../images/noticias/expotel-008.jpg'
import ImgExpotel09 from '../../../images/noticias/expotel-009.jpg'

function Expotel() {
    return (
        <ExpotelStyle>
            <div className="paginas-noticias">
            <div className="pn-content">
                <Header title={"NOTICIAS"} />
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Expotel 2018</h1>
                    <div className='paginas-noticias-content expotel'>
                        <img src={ ImgExpotel } alt="expotel"/>
                        <Page
                            subtitulo="Expotel 2018"
                            data="19/06/2018"
                            descricao="A Astech participará da EXPOTEL – Feira Internacional para Hotelaria, que será realizada entre 25 a 27 de Junho de 2018 no Centro de Eventos Frei Caneca, em conjunto com o SEHGA – Sinergia Estratégica da Hotelaria, Governo e Academia para Desenvolver o Turismo do Estado de SP. A feira contará com a visita de proprietários e gestores de resorts, hotéis, pousadas, hostels, flats e motéis. Serão 3 dias dedicados ao desenvolvimento do mercado hoteleiro."
                        />
                        <div className="expotel">
                            <span>Saiba Mais</span><a href="http://expotel.com.br" target="_blank" rel="noreferrer">http://expotel.com.br</a>
                            
                            <img alt="expotel imagem" src={ ImgExpotel01 } />
                            <img alt="expotel imagem" src={ ImgExpotel02 } />
                            <img alt="expotel imagem" src={ ImgExpotel03 } />
                            <img alt="expotel imagem" src={ ImgExpotel04 } />
                            <img alt="expotel imagem" src={ ImgExpotel05 } />
                            <img alt="expotel imagem" src={ ImgExpotel06 } />
                            <img alt="expotel imagem" src={ ImgExpotel07 } />
                            <img alt="expotel imagem" src={ ImgExpotel08 } />
                            <img alt="expotel imagem" src={ ImgExpotel09 } />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </ExpotelStyle>
    )
}

const ExpotelStyle = styled.div`
    .expotel {
        img {
            margin: 5vh auto;
            display: block;
            width: 30vw;
            height: auto;
        }
    }
`

export default Expotel