
import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import PageVideo from '../../../components/pageVideo'

import VideoOzonioterapia from '../../../videos/ozonioterapia-2.mp4'

function Campanha() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>#OzonioterapiaParaTodos – A Campanha</h1>
                <div className='paginas-noticias-content'>
                    <PageVideo
                        subtitulo="#OzonioterapiaParaTodos – A Campanha"
                        data="09/07/2018"
                        video={VideoOzonioterapia}
                        descricao="Visando a Campanha #OzonioterapiaParaTodos, a Presidente da ABOZ, Dra. Maria Emilia Gadelha Serra, esclarece neste filme o porquê o tratamento com o Ozônio Medicinal é eficaz para a recuperação de pacientes acometidos por várias doenças, reforças que há evidências científicas publicadas e destaca os benefícios para a saúde dos pacientes e para a gestão em saúde das redes pública e privada. Ela convoca a população a aderir a Campanha. Assista o filme, informe-se pelo www.ozonioterapiaparatodos.com.br e faça parte desta luta – que visa a Aprovação do Projeto de Lei que Autoriza a Prescrição da Ozonioterapia no Brasil. Os brasileiros merecem este tratamento assim como os povos de outros continentes!"
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Campanha