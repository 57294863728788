import styled from 'styled-components'
// import { Fade } from 'react-reveal'

import Header from '../components/header'
import Footer from '../components/footer'

import shoppecas from '../images/clientes/shoppecas.png'
import aerobica from '../images/clientes/cliente-aerobica.png'
import ambev from '../images/clientes/cliente-ambev.png'
import crystal from '../images/clientes/cliente-crystal.png'
import gpa from '../images/clientes/cliente-gpa.png'
import leadec from '../images/clientes/cliente-leadec.png'
import prefeituraManaus from '../images/clientes/prefeitura-manaus.png'
import saphyr from '../images/clientes/cliente-saphyr.png'
import sebrae from '../images/clientes/cliente-sebrae-ms.png'
import socicam from '../images/clientes/cliente-socicam.png'
import ssa from '../images/clientes/cliente-ssa.png'
import vilaDaSanta from '../images/clientes/vila-da-santa.png'

function Clientes() {
    return (
        <div>
            <Header />
            <ClientesStyled className="body">
                <h1 className="title">Clientes</h1>
                {/* <Fade left> */}
                    <h2 className="title-d">Nossos Clientes</h2>
                    <div className="style">
                        <div className="box b1"></div>
                        <div className="box b2"></div>
                    </div>
                {/* </Fade> */}
                {/* <Fade right> */}
                    <div className="clientes">
                        <img alt="clientes" src={shoppecas} />
                        <img alt="clientes" src={aerobica} />
                        <img alt="clientes" src={ambev} />
                    
                        <img alt="clientes" src={crystal} />
                        <img alt="clientes" src={gpa} />
                        <img alt="clientes" src={leadec} />
                    
                        <img alt="clientes" src={prefeituraManaus} />
                        <img alt="clientes" src={saphyr} />
                        <img alt="clientes" src={sebrae} />
                    
                        <img alt="clientes" src={socicam} />
                        <img alt="clientes" src={ssa} />
                        <img alt="clientes" src={vilaDaSanta} />
                    </div>
                {/* </Fade> */}
            </ClientesStyled>
            <Footer />
        </div>
    )
}

const ClientesStyled = styled.div`
    .clientes {
        display: grid;
        justify-content: center;
        align-items: center
    }
  
	@media screen and (min-width: 1501px) and (max-width: 3500px) {
        .clientes {
            grid-template-columns: 30vw 30vw 30vw;

            img {
                margin: 0 auto;
                width: 170px;
                height: auto;
            }
        }
    }

    @media screen and (min-width: 1201px) and (max-width: 1500px) {
        .clientes {
            grid-template-columns: 30vw 30vw 30vw;

            img {
                margin: 0 auto;
                width: 150px;
                height: auto;
            }
        }
    }

    @media screen and (min-width: 1001px) and (max-width: 1200px) {
        .clientes {
            grid-template-columns: 30vw 30vw 30vw;

            img {
                margin: 0 auto;
                width: 150px;
                height: auto;
            }
        }
    }

    @media screen and (min-width: 801px) and (max-width: 1000px) {
        .clientes {
            grid-template-columns: 30vw 30vw;
            justify-content: space-between;

            img {
                margin: 0 auto;
                width: 220px;
                height: auto;
            }
        }
    }

    @media screen and (max-width: 800px) {
        .clientes {
            img {
                width: 220px;
                height: auto;
            }
        }
    }
`

export default Clientes