
import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import PageVideo from '../../../components/pageVideo'

function BagMultiuso() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Bag Multiuso para aplicação de ozônio</h1>
                <div className='paginas-noticias-content'>
                    <PageVideo
                        subtitulo="Bag Multiuso para aplicação de ozônio"
                        data="23/10/2018"
                        descricao="A empresa ASTECH desenvolveu estes BAGs disponiveis em diversos tamanhos, são para proteção e aplicação de ozônio em colchões travesseiros, edredons, etc… Eliminando odores, fungos, ácaros, vírus e bactérias. Além de servirem como proteção contra poeiras e umidade quando guardados."
                        video="https://www.youtube.com/embed/4ljaTdnPg9A"
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BagMultiuso