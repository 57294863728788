import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import PageVideo from '../../../components/pageVideo'

function InfeccaoHospitalar() {
    return (
        <div className="paginas-noticias">
            <Header title={"NOTICIAS"} />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Infecção Hospitalar (negligência)</h1>
                <div className='paginas-noticias-content'>
                    <PageVideo
                        subtitulo="Infecção Hospitalar (negligência)"
                        data="05/11/2018"
                        descricao="Infecção hospitalar é qualquer infecção contraída por pacientes durante internação hospitalar, gerando sintomas que podem se manifestar durante a internação ou após a alta. Geralmente os organismos causadores de infecções hospitalares são mais resistentes a medicamentos, por isso o tratamento pode ser um pouco mais complicado. O Ministério da Saúde e a Anvisa determinam regulamentos para o controle e prevenção deste tipo de infecção, e cada hospital deve ter uma Comissão de Controle de Infecção Hospitalar (CCIH) para garantir a segurança de pacientes. Assista nesse vídeo a negligência com pacientes em um hospital."
                        video="https://www.youtube.com/embed/01sUkqVifdY"
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default InfeccaoHospitalar