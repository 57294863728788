import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import styled from 'styled-components'

import RingLoader from 'react-spinners/RingLoader'

import Home from './pages/Home'
import NossaHistoria from './pages/NossaHistoria'
import Solucoes from './pages/Solucoes'
import Beneficios from './pages/Beneficios'
import Produtos from './pages/Produtos'
import Clientes from './pages/Clientes'
import Notas from './pages/Notas'
import Contato from './pages/Contato'

import NotFound from './pages/NotFound'

import Noticias from './pages/Noticias/Pagina1'
import Noticias2 from './pages/Noticias/Pagina2'
import Noticias3 from './pages/Noticias/Pagina3'
import Noticias4 from './pages/Noticias/Pagina4'
import Noticias5 from './pages/Noticias/Pagina5'

import Paroquia from './pages/Noticias/PaginaNoticia/Paroquia'
import Quantum from './pages/Noticias/PaginaNoticia/Quantum'
import OxiSanitizacao from './pages/Noticias/PaginaNoticia/OxiSanitizacao'
import ImportanciaVentilacao from './pages/Noticias/PaginaNoticia/ImportanciaVentilacao'
import Covid19LongeControlada from './pages/Noticias/PaginaNoticia/Covid19LongeControlada'
import EficaciaOzonioLimpeza from './pages/Noticias/PaginaNoticia/EficaciaOzonioLimpeza'
import Aboz from './pages/Noticias/PaginaNoticia/Aboz'
import AntecipacaoVacina from './pages/Noticias/PaginaNoticia/AntecipacaoVacina'
import BacteriaSepse from './pages/Noticias/PaginaNoticia/BacteriaSepse'
import BagMultiuso from './pages/Noticias/PaginaNoticia/BagMultiuso'
import Campanha from './pages/Noticias/PaginaNoticia/Campanha'
import ComercioInveste from './pages/Noticias/PaginaNoticia/ComercioInveste'
import ComercioInvesteTunel from './pages/Noticias/PaginaNoticia/ComercioInvesteTunel'
import DeclaracaoBergCampos from './pages/Noticias/PaginaNoticia/DeclaracaoBergCampos'
import Encantho from './pages/Noticias/PaginaNoticia/Encantho'
import EstudoDetectaParticulas from './pages/Noticias/PaginaNoticia/EstudoDetectaParticulas'
import Exposicao from './pages/Noticias/PaginaNoticia/Exposicao'
import Expotel from './pages/Noticias/PaginaNoticia/Expotel'
import GruposSulfidrila from './pages/Noticias/PaginaNoticia/GrupoSulfidrila'
import InfeccaoHospitalar from './pages/Noticias/PaginaNoticia/InfeccaoHospitalar'
import JamboSupermercados from './pages/Noticias/PaginaNoticia/JamboSupermercados'
import Ozonioterapia from './pages/Noticias/PaginaNoticia/Ozonioterapia'
import OzonioterapiaPrevencao from './pages/Noticias/PaginaNoticia/OzonioterapiaPrevencao'
import Palestra from './pages/Noticias/PaginaNoticia/Palestra'
import PesquisadoresJaponeses from './pages/Noticias/PaginaNoticia/PesquisadoresJaponeses'
import Plano from './pages/Noticias/PaginaNoticia/Plano'
import PrefeituraBacabeira from './pages/Noticias/PaginaNoticia/PrefeituraBacabeira'
import PrefeituraPoluicao from './pages/Noticias/PaginaNoticia/PrefeituraPoluicao'
import RecemNascidos from './pages/Noticias/PaginaNoticia/RecemNascidos'
import Serratec from './pages/Noticias/PaginaNoticia/Serratec'
import Superbacteria from './pages/Noticias/PaginaNoticia/Superbacteria'
import TecnologiaOzonio from './pages/Noticias/PaginaNoticia/TecnologiaOzonio'
import Inclusao from './pages/Noticias/PaginaNoticia/Inclusao'
import NovaOndaEuropa from './pages/Noticias/PaginaNoticia/NovaOndaEuropa'
import QualidadeAr from './pages/Noticias/PaginaNoticia/QualidadeAr'
import AmazonasFaseAmarela from './pages/Noticias/PaginaNoticia/AmazonasFaseAmarela'
import CriancaRespirador from './pages/Noticias/PaginaNoticia/CriancaRespirador'
import NovaOnda from './pages/Noticias/PaginaNoticia/NovaOnda'
import NovaCepa from './pages/Noticias/PaginaNoticia/NovaCepa'
import ChanceNovaOnda from './pages/Noticias/PaginaNoticia/ChanceNovaOnda'
import RioConfirmaSubvariante from './pages/Noticias/PaginaNoticia/RioConfirmaSubvariante'
import SaoPauloMorte from './pages/Noticias/PaginaNoticia/SaoPauloMorteVariante'
import CoronavirusAmazonas from './pages/Noticias/PaginaNoticia/CoronavirusAmazonas'
import MediaMortesBrasil from './pages/Noticias/PaginaNoticia/MediaMortesBrasil'
import Subvariante from './pages/Noticias/PaginaNoticia/Subvariante'
import NovaVariante from './pages/Noticias/PaginaNoticia/NovaVariante'
import CandidaAuris from './pages/Noticias/PaginaNoticia/CandidaAuris'

import Hoteis from './pages/Beneficios/Hoteis'
import Hospitais from './pages/Beneficios/Hospitais'
import Ensino from './pages/Beneficios/Ensino'
import Automoveis from './pages/Beneficios/Automoveis'
import Salao from './pages/Beneficios/Salao'
import Veterinaria from './pages/Beneficios/Veterinaria'
import Escritorios from './pages/Beneficios/Escritorios'
import Mercados from './pages/Beneficios/Mercado'
import Shopping from './pages/Beneficios/Shopping'
import Restaurantes from './pages/Beneficios/Restaurantes'
import Estacoes from './pages/Beneficios/Estacoes'
import Industrias from './pages/Beneficios/Industria'
import Aeroportos from './pages/Beneficios/Aeroportos'
import CallCenter from './pages/Beneficios/CallCenter'
import Agricultura from './pages/Beneficios/Agricultura'
import Frigorificos from './pages/Beneficios/Frigorifico'

import Saude from './pages/NotasTecnicas/Saude'

import bg from './images/banner2.png'

function App() {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  return (
    <AppStyled>
      {loading ? (
        <div className="loading">
          <div className="loading-container">
            <RingLoader size={150} color={'var(--white-color)'} className="loading-item" loading={loading} />
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="*" element={<NotFound />} />

          <Route path="/" element={<Home />} />
          <Route path="/nossa-historia" element={<NossaHistoria />} />
          <Route path="/solucoes" element={<Solucoes />} />
          <Route path="/beneficios" element={<Beneficios />} />
          <Route path="/produtos" element={<Produtos />} />
          <Route path="/clientes" element={<Clientes />} />
          <Route path="/notas-tecnicas" element={<Saude />} />
          <Route path="/contato" element={<Contato />} />

          {/* <Route path="/notas-tecnicas/saude" element={<Saude /> } /> */}

          <Route path="/noticias" element={<Noticias />} />
          <Route path="/noticias/pagina-2" element={<Noticias2 />} />
          <Route path="/noticias/pagina-3" element={<Noticias3 />} />
          <Route path="/noticias/pagina-4" element={<Noticias4 />} />
          <Route path="/noticias/pagina-5" element={<Noticias5 />} />

          <Route path="/beneficios/1" element={<Hoteis />} />
          <Route path="/beneficios/2" element={<Hospitais />} />
          <Route path="/beneficios/3" element={<Ensino />} />
          <Route path="/beneficios/4" element={<Automoveis />} />
          <Route path="/beneficios/5" element={<Salao />} />
          <Route path="/beneficios/6" element={<Veterinaria />} />
          <Route path="/beneficios/7" element={<Escritorios />} />
          <Route path="/beneficios/8" element={<Mercados />} />
          <Route path="/beneficios/9" element={<Shopping />} />
          <Route path="/beneficios/10" element={<Restaurantes />} />
          <Route path="/beneficios/11" element={<Estacoes />} />
          <Route path="/beneficios/12" element={<Industrias />} />
          <Route path="/beneficios/13" element={<Aeroportos />} />
          <Route path="/beneficios/14" element={<CallCenter />} />
          <Route path="/beneficios/15" element={<Agricultura />} />
          <Route path="/beneficios/16" element={<Frigorificos />} />

          <Route path="/noticias/paroquia-de-sao-jose-itaipava-petropolis-rj" element={<Paroquia />} />
          <Route path="/noticias/quantum-medicina" element={<Quantum />} />
          <Route path="/noticias/produtos-oxi-sanitizacao" element={<OxiSanitizacao />} />
          <Route path="/noticias/importancia-ventilacao-limpar-superficie" element={<ImportanciaVentilacao />} />
          <Route path="/noticias/covid-19-longe-de-ser-controlada" element={<Covid19LongeControlada />} />
          <Route path="/noticias/ozonio-eficaz-limpeza-amientes-fechados" element={<EficaciaOzonioLimpeza />} />
          <Route path="/noticias/antecipacao-campanha-vacinacao-contra-gripe" element={<AntecipacaoVacina />} />
          <Route path="/noticias/aboz-nota-esclarecimento-juridico" element={<Aboz />} />
          <Route path="/noticias/pesquisadores-japoneses-afirmam-ozonio-neutraliza-coronavirus" element={<PesquisadoresJaponeses />} />
          <Route path="/noticias/estudo-consegue-detectar-particulas-coronavirus-ar-hospital" element={<EstudoDetectaParticulas />} />
          <Route path="/noticias/prefeitura-bacabeira-disponibiliza-tunel-descontaminacao-mdedida-prevencao-coronavirus" element={<PrefeituraBacabeira />} />
          <Route path="/noticias/tecnologia-ozonio-utilizada-combate-coronavirus" element={<TecnologiaOzonio />} />
          <Route path="/noticias/declaracao-berg-campos-prefeito-porto-de-moz" element={<DeclaracaoBergCampos />} />
          <Route path="/noticias/ozonioterapia-o-que-e" element={<Ozonioterapia />} />
          <Route path="/noticias/grupos-sulfidrila-vulneraveis-oxidacao" element={<GruposSulfidrila />} />
          <Route path="/noticias/serratec-instala-tunel-inflavel-ozonio" element={<Serratec />} />
          <Route path="/noticias/jambo-supermercados-instala-tunel-descontaminacao" element={<JamboSupermercados />} />
          <Route path="/noticias/comercio-investe-mascara-alcool-gel-tunel-descontaminacao" element={<ComercioInveste />} />
          <Route path="/noticias/comercio-investe-tunel-ozonio-elimina-virus" element={<ComercioInvesteTunel />} />
          <Route path="/noticias/ozonioterapia-prevencao-doencas-e-antienvelhecimento" element={<OzonioterapiaPrevencao />} />
          <Route path="/noticias/mais-900mil-recem-nascidos-morrem-todo-ano-por-sujeira-hospitais" element={<RecemNascidos />} />
          <Route path="/noticias/prefeitura-notificada-poluicao-lagoas" element={<PrefeituraPoluicao />} />
          <Route path="/noticias/staphylococcus-aureus-entenda" element={<BacteriaSepse />} />
          <Route path="/noticias/superbacteiras-resistente-poderao-matar-milhoes" element={<Superbacteria />} />
          <Route path="/noticias/palestra-ozonio" element={<Palestra />} />
          <Route path="/noticias/brasil-plano-contra-superbacterias-hospitais-racionalizam-antibioticos" element={<Plano />} />
          <Route path="/noticias/infeccao-hospitalar-negligencia" element={<InfeccaoHospitalar />} />
          <Route path="/noticias/bag-multiuso-aplicacao-ozonio" element={<BagMultiuso />} />
          <Route path="/noticias/encatho-e-exprotel" element={<Encantho />} />
          <Route path="/noticias/ozonioterapia-campanha" element={<Campanha />} />
          <Route path="/noticias/expotel-2018" element={<Expotel />} />
          <Route path="/noticias/exposicao-carros-antigos-araxa" element={<Exposicao />} />
          <Route path="/noticias/astech-inclusao-social" element={<Inclusao />} />
          <Route path="/noticias/covid-nova-onda-de-casos-na-europa" element={<NovaOndaEuropa /> } />
          <Route path="/noticias/segmento-corporativo-investa-qualidade-ar" element={<QualidadeAr /> } />
          <Route path="/noticias/amazonas-fase-amarela" element={<AmazonasFaseAmarela /> } />
          <Route path="/noticias/virus-respiratorio" element={<CriancaRespirador /> } />
          <Route path="/noticias/nova-onda" element={<NovaOnda /> } />
          <Route path="/noticias/nova-cepa" element={<NovaCepa /> } />
          <Route path="/noticias/chance-nova-onda" element={<ChanceNovaOnda /> } />
          <Route path="/noticias/rio-confirma-subvariante" element={<RioConfirmaSubvariante /> } />
          <Route path="/noticias/sao-paulo-morte-variante" element={<SaoPauloMorte /> } />
          <Route path="/noticias/coronavirus-amazonas" element={<CoronavirusAmazonas /> } />
          <Route path="/noticias/media-mortes-brasil" element={<MediaMortesBrasil /> } />
          <Route path="/noticias/medidas-urgente-subvariante" element={<Subvariante /> } />
          <Route path="/noticias/nova-variante" element={<NovaVariante /> } />
          <Route path="/noticias/candida-auris" element={<CandidaAuris /> } />
          
          {/* <Route path="/notas-tecnicas/saude" element={<Saude />} /> */}
        </Routes>
      )}
    </AppStyled>
  );
}

const AppStyled = styled.div`
    width: 100vw;
    height: 100vh;
    .loading {
      width: 100vw;
      height: 100vh;
      background-image: url(${bg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: 100%;
      filter: brightness(.8);
      
      .loading-container {
        padding-top: 30vh;
        .loading-item {
        margin: 0 auto;
      }
    }
  }
`

export default App;
