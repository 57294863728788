import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import NovaCepa from '../../../images/noticias/1_picture2__1_-26636562.jpg'

function ChanceNovaOnda() {
    return (
        <ChanceNovaOndaStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Especialistas alertam para avanço da covid-19 e chance de nova onda</h1>
                    <div className='paginas-noticias-content ChanceNovaOnda'>
                        <Page
                            subtitulo="Especialistas alertam para avanço da covid-19 e chance de nova onda"
                            data="11/10/2022"
                            img={NovaCepa}
                        />
                        {/* <img src={ ChanceNovaOndaImg } alt="noticia" /> */}
                        <p>A quantidade de testes positivos de coronavírus em laboratórios privados do país aumentou em outubro. É o que mostram análises do Instituto Todos pela Saúde (ITpS), que alerta para uma nova onda de casos da doença em países da Europa. Por causa desses dois fatores, especialistas apontam que é importante que a população fique atenta aos sintomas, faça testes para confirmar a infecção por coronavírus e siga o tratamento correto.</p>
                        <p>Duas novas cepas do vírus, a BG.1 e a XBB, que surgiram a partir da variante Ômicron, são potencialmente mais resistentes à vacina e têm crescido em circulação. Segundo o médico infectologista do Hospital das Clínicas da Faculdade de Medicina da USP Evaldo Stanislau, apesar das novas cepas do vírus, os sintomas da covid-19 continuam sendo os mesmos de antes.</p>
                        <p>"Geralmente são respiratórios, similares aos de um resfriado comum ou, eventualmente, aos de uma gripe, quando há mais sintomas de febre e mal-estar", explica.</p>
                        <span>Veja matéria completa em: </span><a href="https://www.correiobraziliense.com.br/brasil/2022/11/5049623-especialistas-alertam-para-avanco-da-covid-19-e-chance-de-nova-onda.html" target="_blank" rel="noreferrer">https://www.correiobraziliense.com.br/brasil/2022/11/5049623-especialistas-alertam-para-avanco-da-covid-19-e-chance-de-nova-onda.html</a>
                    </div>
                </div>
                <Footer />
            </div>
        </ChanceNovaOndaStyle>
    )
}

const ChanceNovaOndaStyle = styled.div`
    .ChanceNovaOnda-vacina {
        img {
            margin: 4vh auto;
            display: block;
        }
    }
`

export default ChanceNovaOnda