import Header from '../../components/header'
import Footer from '../../components/footer'
import Ficha from '../../components/ficha'

function Automoveis() {
    return (
        <div>
            <Header title={"AUTOMÒVEIS, ÔNIBUS, CAMINHÕES, TÁXIS E APLICATIVOS"} />
            <div className="body">
                <h2 className="title-d">Aplicabilidade</h2>
                <div className="style">
                    <div className="box b1"></div>
                    <div className="box b2"></div>
                </div>

                <p className="desc">No transporte de passageiros, permitindo o uso do ar-condicionado e proporcionando segurança com os vidros fechados, utilizando o nosso equipamento <strong>SANITECH (O₃) MULTIUSO</strong> de baixa concentração para <strong>ambientes habitáveis</strong> eliminando micro-organismos, fungos, odores indesejáveis</p>

                <p className="desc">Na descontaminação dos sapatos em rodoviárias, utilizando o nosso equipamento <strong>SANITECH UVC</strong> eliminando os micro-organismos das solas dos sapatos em 99%.</p>

                <p className="desc">Na sanitização da cabine e baú dos caminhões, no interior dos ônibus, taxis e aplicativos após a limpeza terminal, utilizando o nosso equipamento <strong>SANITECH (O₃) OXI-SANITIZAÇÃO 40</strong>, com gás de ozônio em alta concentração, eliminando os micro-organismos e fungos do ar-condicionado.</p>

                <p className="desc">Na pulverização com água ozonizada potencializando na mistura de saneantes químicos no baú dos caminhões frigoríficos e de transportes de FLV (frutas, legumes e verduras) contaminados, após a limpeza terminal, utilizando o nosso equipamento <strong>SANITECH (O₃) NEBULIZADOR COSTAL</strong>, eliminando micro-organismos, fungos e odores indesejáveis.</p>

            </div>         
            <Ficha />
            <Footer /> 
        </div>
    )
}

export default Automoveis