import { Link } from "react-router-dom";

import Header from "../../components/header";
import Footer from "../../components/footer";
import News from "../../components/news";
import "./style.css";

import Paroquia from "../../images/noticias/paroquia.jpeg";
import Quantum from "../../images/noticias/quantum.png";

import NovaOnda from "../../images/noticias/nova-onda.webp";
import QualidadeAr from "../../images/noticias/qualidade-ar.webp";
import ThumbOxisanitizacao from "../../images/noticias/thumb-oxisanitizacao.png";
import Inclusao from "../../images/noticias/inclusao.png";
import Casos from "../../images/noticias/14mm-casos-arte.webp";
import AmazonasFaseAmarela from "../../images/noticias/amazonas-fase-amarela.png";
import Covid from '../../images/noticias/covid.jpg'

import arrow2 from "../../images/svg/arrow2.svg";
import arrow3 from "../../images/svg/arrow3.svg";

function Pagina2() {
  return (
    <div>
      <Header title="NOTICIAS" />
      <h2 className="noticia-title">Notícias</h2>

      <div className="pagina-content">
        <ul>

          <li>
            <News
              img={Covid}
              titulo="09/11/2022 - Fiocruz identifica subvariante BA.5.3.1 do coronavírus no Amazonas"
              data="11/11/2022"
              descricao="Enquanto autoridades de saúde do Rio de Janeiro veem uma possível associação entre o aumento de casos de covid-19 entre cariocas e a subvariante BQ.1 do coronavírus, no Amazonas..."
              pagina="coronavirus-amazonas"
            />
          </li>
          <li>
            <News
              img={Casos}
              titulo="07/11/2022 - Brasil registra 78 mortes por Covid e total chega a 688.617 média segue com tendência de queda"
              data="09/11/2022"
              descricao="São 34.919.019 casos conhecidos de Covid-19 registrados desde o início da pandemia, segundo dados reunidos pelo consórcio de veículos de imprensa..."
              pagina="media-mortes-brasil"
            />
          </li>
          <li>
            <News
              img={AmazonasFaseAmarela}
              titulo="Amazonas volta para fase amarela de transmissão de Covid-19"
              data="25/10/2022"
              descricao="Amazonas volta para fase amarela de transmissão da Covid-19 da pandemia..."
              pagina="amazonas-fase-amarela"
            />
          </li>
          
        </ul>
        <ul>
          <li>
            <News
              img={NovaOnda}
              titulo="Covid: o que nova onda de casos na Europa significa para o Brasil"
              data="25/10/2022"
              descricao="A mudança nos cenários epidemiológicos motiva, inclusive, discussões sobre a volta de certas medidas preventivas."
              pagina="covid-nova-onda-de-casos-na-europa"
            />
          </li>
          <li>
            <News
              img={QualidadeAr}
              titulo="Segmento corporativo investe na qualidade do ar"
              data="25/10/2022"
              descricao="Ameaça do ‘sick building’ leva o mercado a apostar em tecnologia avançada para eliminar vírus e bactérias..."
              pagina="segmento-corporativo-investa-qualidade-ar"
            />
          </li>
          <li>
            <News
              img={Inclusao}
              titulo="Astech - Inclusão Social"
              data="08/07/2022"
              descricao="A Astech preocupada com a inclusão social de jovens carentes da Região Serrana do Rio de Janeiro..."
              pagina="astech-inclusao-social"
            />
          </li>
          
        </ul>
        <ul>
          <li>
            <News
              img={Paroquia}
              titulo="Paróquia de São José - Itaipava - Petrópolis - RJ"
              data="10/08/2021"
              descricao="Instalação do Sanitech O³ Puri MU TURBO DUPLO na Paróquia de São José, Itaipava, Petrópolis - RJ..."
              pagina="paroquia-de-sao-jose-itaipava-petropolis-rj"
            />
          </li>
          <li>
            <News
              img={Quantum}
              titulo="Quantum Medicina"
              data="22/06/2021"
              descricao="O ambiente da Clínica Quantum Medicina agora conta com os equipamentos da Linha Puri da Sanitech... "
              pagina="quantum-medicina"
            />
          </li>
          <li>
            <News
              img={ThumbOxisanitizacao}
              titulo="Produtos para Oxi-sanitização"
              data="31/05/2021"
              descricao="Cohen & Cohen, representante Astech exclusivo do Norte do país..."
              pagina="produtos-oxi-sanitizacao"
            />
          </li>
          
        </ul>

        <Link to="/noticias/pagina-3">
          <img className="icon" src={arrow2} alt="icone voltar" />
        </Link>
        <Link to="/noticias/pagina-1">
          <img className="icon" src={arrow3} alt="icone proximo" />
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default Pagina2;
