import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

function ComercioInvesteTunel() {
    return (
        <div className="paginas-noticias">
            <Header />
            <div className="pn-content">
                <h2 className='noticia-title'>Notícias</h2>
                <h1>Comércio investe em túnel de ozônio, que promete eliminar vírus da pele e da roupa</h1>
                <div className='paginas-noticias-content'>
                    <Page
                        subtitulo="Comércio investe em túnel de ozônio, que promete eliminar vírus da pele e da roupa"
                        data="22/05/2020"
                        descricao="Depois do álcool em gel, das máscaras, óculos de segurança e luvas, outro “acessório” tende a ganhar força Brasil afora. Em Petrópolis, na Região Serrana do Rio de Janeiro, um fabricante de purificador de ar e uma empresa de balões infláveis se uniram para produzir túneis de descontaminação.
                        O objetivo da criação é mitigar a proliferação do novo coronavírus e ajudar o comércio na adaptação do processo de abertura da economia, ainda sem data para ocorrer.
                        Embora tenha sido criada há apenas um mês, o empreendedor Guilherme Costa, diretor comercial da Astech, fabricante do purificador de ar, diz que já foram vendidos cem túneis para salões de beleza, academias e restaurantes para o Brasil inteiro.
                        — Temos capacidade para produzir oito produtos por dia. Nosso objetivo é elevar a nossa capacidade para doze equipamentos diários. Agora, estamos desenvolvendo uma cabine de descontaminação, que ocupa metade do espaço de dois metros do túnel.
                        Envie denúncias, informações, vídeos e imagens para o WhatsApp do Extra (21 99644 1263)
                        Segundo ele, o túnel utiliza ozônio em pequenas proporções (entre 0,02 e 0,08 partícula por milhão) que elimina vírus e bactérias presentes na pele e na roupa após uma exposição de dois minutos. Iniciativas semelhantes já são adotadas na Europa e Ásia.
                        Por usar ozônio, Costa diz que não é necessário autorização da Anvisa.
                        — Eu tive a ideia de fazer essa solução após ver que as pessoas estavam confundindo túnel de descontaminação com túnel de desinfecção, que usa produtos químicos e precisa de autorização da Anvisa e é usado em hospitais, por exemplo. É importante que as pessoas saibam a diferença. Muito empresário me liga sem saber que existem esses dois produtos — afirma Costa.
                        De olho na proteção, o comércio vem apostando na iniciativa para proteger seus clientes assim que a abertura for possível. O salão Care Ipanema, na Zona Sul do Rio, já instalou em sua entrada o túnel de descontaminação do produtor da região Serrana do Rio.
                        Segundo Ivani Werneck, dona do salão, a solução vai proteger seus funcionários e clientes. Além disso, ela lembra que o produto é ecologicamente correto, uma vez que não há emissão de gases poluentes.
                        — Esse é mais um item que vamos usar para a proteção. É preciso apostar em tudo para aumentar a prevenção. Quero estar pronta quando tudo voltar ao seu ritmo normal — disse ela, listando itens já obrigatórios na nova rotina como álcool em gel e máscaras.
                        A empresária que comanda o salão de 450 metros quadrados reclama da falta de sintonia entre o governo federal e municipal:
                        — Confesso que ficamos perdidos. Abrir como quer o presidente Jair Bolsonaro ou seguir a determinação do governo local. É muito confuso. Estamos à deriva."
                    />
                    <span>Fonte: </span><a href="https://extra.globo.com/noticias/economia/comercio-investe-em-tunel-de-ozonio-que-promete-eliminar-virus-da-pele-da-roupa-24440226.html">https://extra.globo.com/noticias/economia/comercio-investe-em-tunel-de-ozonio-que-promete-eliminar-virus-da-pele-da-roupa-24440226.html</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ComercioInvesteTunel