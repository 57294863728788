import styled from 'styled-components'

import './style.css'

import Header from '../../../components/header'
import Footer from '../../../components/footer'

import Page from '../../../components/page'

import ImgQuantum03 from '../../../images/noticias/quantum03.jpg'
import ImgQuantum01 from '../../../images/noticias/quantum01.jpg'
import ImgQuantum00 from '../../../images/noticias/quantum00.jpg'
import InclusaoImg from "../../../images/noticias/inclusao.png";

function Quantum() {
    return (
        <QuantumStyle>
            <div className="paginas-noticias">
                <Header title={"NOTICIAS"} />
                <div className="pn-content">
                    <h2 className='noticia-title'>Notícias</h2>
                    <h1>Quantum Medicina</h1>
                    <div className='paginas-noticias-content'>
                        <Page
                            subtitulo="Quantum Medicina"
                            data="22/06/2021"
                            img={InclusaoImg}
                        />
                        <p className='quantum'>O ambiente da <strong>Clínica Quantum Medicina</strong> agora conta com os equipamentos da <a href="https://sanitech.eco.br/" target="_blank" rel="noreferrer"><strong>Linha Puri Sanitech O3</strong></a> para a preservação.</p>
                        <p className='quantum'>Agradecemos a parceria. A satisfação do cliente é nosso maior objetivo.</p>
                        <div className="pn-fotos">
                            <div className="pn-thumbnail">
                                <ul>
                                    <li><img alt="imagem quantum"src={ImgQuantum03} /></li>
                                    <li><img alt="imagem quantum"src={ImgQuantum01} /></li>
                                    <li><img alt="imagem quantum"src={ImgQuantum00} /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </QuantumStyle>
    )
}

const QuantumStyle = styled.div`
    .paginas-noticias-content {
        .quantum {
            text-align: center;
        }
    }
`

export default Quantum